import { CONSTANTS } from './constants';
import { Actions } from '../actiontypes';

export function setClassificationId(payload: number): Actions.NumberPayloadAction {
    return {
        type: CONSTANTS.SET_CLASSIFICATION_ID,
        payload,
    };
}

export function setLoadingClassifications(payload: boolean): Actions.BooleanPayloadAction {
    return {
        type: CONSTANTS.SET_LOADING_CLASSIFICATIONS,
        payload,
    };
}

export function fetchClassificationsAndRawData(
    classificationsPayload,
    rawDataPayload,
): Actions.Classifications.FetchClassifications {
    return {
        type: CONSTANTS.FETCH_CLASSIFICATIONS,
        classificationsPayload,
        rawDataPayload,
    };
}

export function fetchRawData(className, payload): Actions.Classifications.FetchRawData {
    return {
        type: CONSTANTS.FETCH_RAW_DATA,
        className,
        payload,
    };
}

export function exportRawDataAsCsv(className, payload): Actions.Classifications.FetchRawData {
    return {
        type: CONSTANTS.EXPORT_CSV,
        className,
        payload,
    };
}

export function setSelectedRowClassification(
    payload,
): Actions.Classifications.SetRowClassification {
    return {
        type: CONSTANTS.SET_ROW_CLASSIFICATION,
        payload,
    };
}

export function updateClassifications(
    className,
    payload,
    params,
    refetchPayload,
    isEditing,
): Actions.Classifications.UpdateClassifications {
    return {
        type: CONSTANTS.UPDATE_CLASSIFICATIONS,
        className,
        payload,
        params,
        refetchPayload,
        isEditing,
    };
}

export function resetRawData(): Actions.ResetAction {
    return {
        type: CONSTANTS.RESET_RAW_DATA,
    };
}

export function resetDropdowns(): Actions.ResetAction {
    return {
        type: CONSTANTS.RESET_DROPDOWNS,
    };
}
