import './loader.scss';

/**
 * @Description Custom Loader for Recur 
 */

interface LoaderProps {
    noFullScreen?: boolean
}

function Loader(props: LoaderProps) {
    return (
        <div
            className="loader-screen"
            style={{
                ...(props?.noFullScreen ? {
                    position: "relative",
                    background: "transparent",
                    width: "100%",
                    height: "400px"
                } : {})
            }}
        >
            <div className="loader-div">
                <div className="loader letter-first">
                    <div className="text">R</div>
                </div>
                <div className="loader letter-mid">
                    <div className="text">E</div>
                </div>
                <div className="loader letter-mid">
                    <div className="text">C</div>
                </div>
                <div className="loader letter-mid">
                    <div className="text">U</div>
                </div>
                <div className="loader letter-last">
                    <div className="text">R</div>
                </div>
            </div>
        </div>
    );
}

export default Loader;
