import {
    CONSTANTS,
    PeriodOptions,
    GRAPH_NAMES,
    GRAPH_TYPES,
    STAT_TYPES,
    CURRENCY_DROPDOWN_OPTIONS,
} from './constants';
import { getUnitAndConversionFunction } from '../../utils/utils';
import { ROUTES } from '../../RoutesConst';
import { DashboardState } from './types';

const inititalState: DashboardState = {
    isLoading: false,
    selectedPeriod: PeriodOptions[1],
    selectedDropdownPeriod: PeriodOptions[1],
    fromDate: '',
    toDate: '',
    selectedCurrency: CURRENCY_DROPDOWN_OPTIONS[0],
    currencyOptions: CURRENCY_DROPDOWN_OPTIONS,
    metaData: {
        arrGrowthRate: {
            value: 0,
            status: 'Above',
            label: 'Revenue Growth Rate',
            background: '#DAE7FF',
            valueType: '%',
            info: 'Growth in Revenue over a period of one year.\nRevenue growth = (Closing ARR / Opening ARR) - 1',
        },
        grossMargin: {
            value: 0,
            status: 'Above',
            label: 'Gross Margin',
            background: '#E2FDFF',
            valueType: '%',
            info: 'Gross margin compares the gross profit of a company with its revenue. Calculated as the difference between Revenue and Cost of goods/services sold, gross profit shows the earnings of a company after paying off its direct costs.\nGross Margin = (Revenue - Direct costs) / Revenue',
        },
        ltvCac: {
            value: 0,
            status: 'Above',
            label: 'LTV/CAC',
            background: 'rgba(176, 149, 255, 0.3)',
            valueType: '',
            info: 'Lifetime Value (LTV) refers to the monetary value (calculated as gross margin in absolute terms) which is expected to be derived from a new customer over its lifetime. Customer Acquisition Cost (CAC) refers to the cost of acquiring a new customer, including sales and marketing spends. The ratio of LTV to CAC gives a comparison between the value of a new customer over its lifetime relative to the cost of acquisition.\nLTV = Average Revenue Per Account (ARPA) * Gross Margin / Churn\nCAC = Sum of all Sales and Marketing spends / Number of customers added',
        },
        runway: {
            value: 0,
            status: 'Above',
            label: 'Runway',
            background: '#D8F3FF',
            valueType: ' month(s)',
            info: 'Runway refers to the number of months the company is expected to remain solvent at the current cash burn rate, in absence of any additional external financing.\nRunway = Closing cash balance / Average monthly burn (including operating, investing and financing cash outflows)',
        },
    },
    recurScore: {
        value: 0,
        info: "Recur Score is a proprietary scoring engine developed based on the company's recurring revenue metrics, financial metrics, management & governance risk and historical performance on Recur platform.",
    },
    graphsToShow: [
        // GRAPH_NAMES.REVENUE,
        GRAPH_NAMES.TOTAL_COST,
        GRAPH_NAMES.GROSS_MARGIN,
        GRAPH_NAMES.EBITDA,
        GRAPH_NAMES.PNL_SNPASHOT,
        GRAPH_NAMES.WORKING_CAPITAL_CYCLE,
        GRAPH_NAMES.CASH_BALANCE,
        GRAPH_NAMES.CASH_MOVEMENT,
        GRAPH_NAMES.CASH_FLOW_MOVEMENT,
        GRAPH_NAMES.CASH_FLOW_SNAPSHOT,
        GRAPH_NAMES.ARR,
        GRAPH_NAMES.ARR_BRIDGE,
        GRAPH_NAMES.CLOSING_LOGOS,
        GRAPH_NAMES.CUSTOMER_BRIDGE,
        GRAPH_NAMES.ARPA,
    ],
    pageSections: [
        {
            id: 1,
            label: '',
            datepickerData: null,
            graphs: [
                // GRAPH_NAMES.REVENUE,
                GRAPH_NAMES.TOTAL_COST,
                GRAPH_NAMES.GROSS_MARGIN,
                GRAPH_NAMES.EBITDA,
                GRAPH_NAMES.PNL_SNPASHOT,
                GRAPH_NAMES.WORKING_CAPITAL_CYCLE,
                GRAPH_NAMES.CASH_BALANCE,
                GRAPH_NAMES.CASH_MOVEMENT,
                GRAPH_NAMES.CASH_FLOW_MOVEMENT,
                GRAPH_NAMES.CASH_FLOW_SNAPSHOT,
                GRAPH_NAMES.ARPA,
            ],
        },
        {
            id: 2,
            label: 'Recurring Revenue Metrics',
            datepickerData: {
                fromDate: '',
                toDate: '',
            },
            graphs: [
                GRAPH_NAMES.ARR,
                GRAPH_NAMES.ARR_BRIDGE,
                GRAPH_NAMES.CLOSING_LOGOS,
                GRAPH_NAMES.CUSTOMER_BRIDGE,
            ],
        },
    ],
    graphData: {
        [GRAPH_NAMES.ARR]: {
            isLoading: false,
            isFetched: false,
            type: GRAPH_TYPES.LINE,
            label: 'Annual Recurring Revenue (ARR)',
            colors: {
                primary: '#000000',
                secondary: '',
                tertiary: '',
                error: { primary: '#000000', secondary: '#505050' },
            },
            linkToFinancials: ROUTES.PRIVATE.FINANCIALS.INVOICING,
            amount: [],
            aggregateValue: '',
            hasNegative: false,
            info: 'Annual recurring revenue refers to the predictable revenue a company derived from all its customers during a year. It can be computed as:\nMonthly recurring revenue * 12 OR\nQuarterly recurring revenue * 4 OR \nRevenue for the last 12 months, depending on the billing cycles of the business',
        },
        [GRAPH_NAMES.ARR_BRIDGE]: {
            isLoading: false,
            isFetched: false,
            type: GRAPH_TYPES.WATERFALL,
            label: 'ARR Bridge',
            colors: {
                primary: '#000000',
                secondary: '#6BDE85',
                tertiary: '#ED6E72',
                error: { primary: '#000000', secondary: '#505050' },
            },
            linkToFinancials: ROUTES.PRIVATE.FINANCIALS.INVOICING,
            amount: [],
            aggregateValue: '',
            hasNegative: false,
            info: 'ARR bridge gives a synopsis of the change in ARR in a period of one year and consists of New ARR i.e. ARR contributed by new customers, Net expansion ARR i.e. expansion or contraction in ARR of the existing customers and Churn ARR i.e. ARR lost to churned customers ',
        },
        // [GRAPH_NAMES.REVENUE]: {
        //     isLoading: false,
        //     isFetched: false,
        //     type: GRAPH_TYPES.LINE,
        //     label: 'Revenue',
        //     colors: {
        //         primary: '#000000',
        //         secondary: '',
        //         tertiary: '',
        //         error: { primary: '#000000', secondary: '#505050' },
        //     },
        //     linkToFinancials: ROUTES.PRIVATE.FINANCIALS.PNL,
        //     amount: [],
        //     aggregateValue: '',
        //     hasNegative: false,
        //     info: 'Value of good or services rendered by the company net of returns for the selected period (month / quarter / year), calculated using the accrual principle',
        // },
        [GRAPH_NAMES.TOTAL_COST]: {
            isLoading: false,
            isFetched: false,
            type: GRAPH_TYPES.STACKBAR,
            label: 'Total Costs',
            colors: {
                primary: '#ED6E72',
                secondary: '#D580B8',
                tertiary: '#97E0FF',
                error: { primary: '#000000', secondary: '#505050' },
            },
            linkToFinancials: ROUTES.PRIVATE.FINANCIALS.PNL,
            amount: [],
            aggregateValue: '',
            hasNegative: false,
            info: 'Amounts incurred by the company across direct costs, Customer Acquisition (CAC) and General and Administrative expenditures (G&A) for the selected period (month/ quarter/ year), calculated using the accrual principle',
        },
        [GRAPH_NAMES.CASH_BALANCE]: {
            isLoading: false,
            isFetched: false,
            type: GRAPH_TYPES.LINE,
            label: 'Cash Balance',
            colors: {
                primary: '#000000',
                secondary: '',
                tertiary: '',
                error: { primary: '#000000', secondary: '#505050' },
            },
            linkToFinancials: ROUTES.PRIVATE.FINANCIALS.BANK_STATEMENT,
            amount: [],
            aggregateValue: '',
            hasNegative: false,
            info: 'Captures the movement in cash balances of the company for the selected period (month/ quarter/ year). Cash balance comprises of:\nCash in hand; Bank balances; FD and other liquid investments (if any)',
        },
        [GRAPH_NAMES.PNL_SNPASHOT]: {
            isLoading: false,
            isFetched: false,
            type: GRAPH_TYPES.WATERFALL,
            label: 'P&L Snapshot',
            colors: {
                primary: '#000000',
                secondary: '#6BDE85',
                tertiary: '#ED6E72',
                error: { primary: '#000000', secondary: '#505050' },
            },
            linkToFinancials: ROUTES.PRIVATE.FINANCIALS.PNL,
            amount: [],
            aggregateValue: '',
            hasNegative: false,
            info: 'P&L snapshot gives a synopsis of the unit economics of the business. It shows how each rupee of revenue generated is apportioned to different expenses like Direct Costs, CAC and G&A.',
        },
        [GRAPH_NAMES.CASH_MOVEMENT]: {
            isLoading: false,
            isFetched: false,
            type: GRAPH_TYPES.DOUBLEBAR,
            label: 'Cash Movement',
            colors: {
                primary: '#ED6E72',
                secondary: '#6BDE85',
                tertiary: '',
                error: { primary: '#000000', secondary: '#505050' },
            },
            linkToFinancials: ROUTES.PRIVATE.FINANCIALS.BANK_STATEMENT,
            amount: [],
            aggregateValue: '',
            hasNegative: false,
            info: 'A summary of the cash inflows and outflows for the selected period (month/ quarter/ year)',
        },
        [GRAPH_NAMES.GROSS_MARGIN]: {
            isLoading: false,
            isFetched: false,
            type: GRAPH_TYPES.LINE,
            label: 'Gross Margin',
            colors: {
                primary: '#000000',
                secondary: '',
                tertiary: '',
                error: { primary: '#000000', secondary: '#505050' },
            },
            linkToFinancials: ROUTES.PRIVATE.FINANCIALS.PNL,
            amount: [],
            aggregateValue: '',
            hasNegative: false,
            info: 'Gross margin compares the gross profit of a company with its revenue. Calculated as the difference between Revenue and Cost of goods/services sold, gross profit shows the earnings of a company after paying off its direct costs.\nGross Margin = (Revenue - Direct costs) / Revenue',
        },
        [GRAPH_NAMES.CASH_FLOW_MOVEMENT]: {
            isLoading: false,
            isFetched: false,
            type: GRAPH_TYPES.BRUSH,
            label: 'Cash Flow Split',
            colors: {
                primary: '#000000',
                secondary: '#A381EB',
                tertiary: '#6FD4FF',
                error: { primary: '#000000', secondary: '#505050' },
            },
            linkToFinancials: ROUTES.PRIVATE.FINANCIALS.CASHFLOW,
            amount: [],
            aggregateValue: '',
            hasNegative: false,
            info: 'Segments the net cash flow into operating, investing and financing heads for the selected period (month/ quarter/ year)',
        },
        [GRAPH_NAMES.CASH_FLOW_SNAPSHOT]: {
            isLoading: false,
            isFetched: false,
            type: GRAPH_TYPES.WATERFALL,
            label: 'Cash Flow Bridge',
            colors: {
                primary: '#000000',
                secondary: '#6BDE85',
                tertiary: '#ED6E72',
                error: { primary: '#000000', secondary: '#505050' },
            },
            linkToFinancials: ROUTES.PRIVATE.FINANCIALS.CASHFLOW,
            amount: [],
            aggregateValue: '',
            hasNegative: false,
            info: 'Cash flow bridge gives a synopsis of the change in cash balance in a period of one year and consists of Operating i.e. cash movements due to regular operations of business, Investing i.e. cash movements due to certain investment in fixed assets etc. and Financing i.e. cash movements due to financing activities',
        },
        [GRAPH_NAMES.EBITDA]: {
            isLoading: false,
            isFetched: false,
            type: GRAPH_TYPES.LINEBAR,
            label: 'EBITDA',
            colors: {
                primary: '#000000',
                secondary: '#6BDE85',
                tertiary: '',
                error: { primary: '#000000', secondary: '#505050' },
            },
            linkToFinancials: ROUTES.PRIVATE.FINANCIALS.PNL,
            amount: [],
            aggregateValue: '',
            hasNegative: false,
            info: 'Refers to Earnings Before Interest, Tax, Depreciation and Amortization. It is calculated as:\nEBITDA = Revenue - Total costs (CAC + G&A + Direct costs)',
        },
        [GRAPH_NAMES.WORKING_CAPITAL_CYCLE]: {
            isLoading: false,
            isFetched: false,
            type: GRAPH_TYPES.DOUBLEBAR,
            label: 'Working Capital Cycle',
            colors: {
                primary: '#000000',
                secondary: '#6FD4FF',
                tertiary: '',
                error: { primary: '#000000', secondary: '#505050' },
            },
            linkToFinancials: ROUTES.PRIVATE.FINANCIALS.INVOICING,
            amount: [],
            aggregateValue: '',
            hasNegative: false,
            info: 'The time a company takes to convert net current assets and current liabilities into cash\n\nReceivable Cycle or Receivable Days refers to the number of days an invoice is outstanding before it is collected.\nReceivable cycle = Trade receivables / Revenue * 365\n\nPayable Cycle or Payable Days refers to the number of days a payment / supplier obligation is outstanding before it is paid\nPayable cycle = Trade payables / Direct costs * 365',
        },
        [GRAPH_NAMES.CLOSING_LOGOS]: {
            isLoading: false,
            isFetched: false,
            type: GRAPH_TYPES.LINEBAR,
            label: 'Closing Logos',
            colors: {
                primary: '#6FD4FF',
                secondary: '#000000',
                tertiary: '',
                error: { primary: '#000000', secondary: '#505050' },
            },
            linkToFinancials: ROUTES.PRIVATE.FINANCIALS.INVOICING,
            amount: [],
            aggregateValue: '',
            hasNegative: false,
            info: 'Closing logos are the number of active customers at the end of the selected period (month/ quarter/ year). This is calculated based on the number of customers that were invoiced in the latest period',
        },
        [GRAPH_NAMES.CUSTOMER_BRIDGE]: {
            isLoading: false,
            isFetched: false,
            type: GRAPH_TYPES.WATERFALL,
            label: 'Customer Count Bridge',
            colors: {
                primary: '#000000',
                secondary: '#6BDE85',
                tertiary: '#ED6E72',
                error: { primary: '#000000', secondary: '#505050' },
            },
            linkToFinancials: ROUTES.PRIVATE.FINANCIALS.INVOICING,
            amount: [],
            aggregateValue: '',
            hasNegative: false,
            info: 'Customer count bridge gives a synopsis of the change in number of customers for the selected period (month/ quarter/ year)',
        },
        [GRAPH_NAMES.ARPA]: {
            isLoading: false,
            isFetched: false,
            type: GRAPH_TYPES.LINE,
            label: 'Average Revenue Per Account',
            colors: {
                primary: '#000000',
                secondary: '',
                tertiary: '',
                error: { primary: '#000000', secondary: '#505050' },
            },
            linkToFinancials: ROUTES.PRIVATE.FINANCIALS.INVOICING,
            amount: [],
            aggregateValue: '',
            hasNegative: false,
            info: 'Average Revenue Per Account is the average revenue from each customer in one year. It is calculated as\nARPA = Closing ARR / Closing logos',
        },
    },
};

const setIsLoading = (state, action) => {
    return {
        ...state,
        isLoading: action.payload,
    };
};

const setSelectedPeriod = (state, action) => {
    return {
        ...state,
        selectedPeriod: action.payload,
    };
};

const setIsLoadingGraph = (state, action) => {
    return {
        ...state,
        graphData: {
            ...state.graphData,
            [action.graphName]: {
                ...state.graphData[action.graphName],
                isLoading: action.payload,
            },
        },
    };
};

const setRecurringMetricsDates = (state, action) => {
    let pageSections = JSON.parse(JSON.stringify(state.pageSections));
    pageSections[1].datepickerData.fromDate = action.payload.fromDate;
    pageSections[1].datepickerData.toDate = action.payload.toDate;
    return {
        ...state,
        pageSections: [...pageSections],
    };
};

const getDashboardMetadataSuccess = (state, action) => {
    return {
        ...state,
        metaData: {
            ...state.metaData,
            arrGrowthRate: {
                ...state.metaData.arrGrowthRate,
                ...action.payload[STAT_TYPES.ARR_GROWTH_RATE],
            },
            grossMargin: {
                ...state.metaData.grossMargin,
                ...action.payload[STAT_TYPES.GROSS_MARGIN],
            },
            ltvCac: {
                ...state.metaData.ltvCac,
                ...action.payload[STAT_TYPES.LTV_CAC],
            },
            runway: {
                ...state.metaData.runway,
                ...action.payload[STAT_TYPES.RUNWAY],
                value: parseInt(action.payload[STAT_TYPES.RUNWAY]?.value || 0),
            },
        },
        recurScore: {
            ...state.recurScore,
            ...action.payload[STAT_TYPES.RECUR_SCORE],
        },
    };
};

//THIS FUNCTION CONTAINS ALL THE LOGIC TO MANIPULATE API DATA TO REQUIRED FORMAT FOR GRAPHS.
//DATA MANIPULATION IS DONE BY MATCHING THE GRAPH TYPE AND GRAPH NAME

const getGraphDataSuccess = (state, action) => {
    switch (action.graph.graphType) {
        case GRAPH_TYPES.LINE: {
            return fetchLineGraphsSuccessHandler(state, action);
        }
        case GRAPH_TYPES.WATERFALL: {
            return fetchWaterfallGraphSuccessHandler(state, action);
        }
        case GRAPH_TYPES.DOUBLEBAR: {
            return fetchDoubleBarSuccessHandler(state, action);
        }
        case GRAPH_TYPES.STACKBAR: {
            return fetchStackBarSuccessHandler(state, action);
        }
        case GRAPH_TYPES.BRUSH: {
            return fetchBrushGraphSuccessHandler(state, action);
        }
        case GRAPH_TYPES.LINEBAR: {
            return fetchLineBarSuccessHandler(state, action);
        }

        default:
            return {
                ...state,
                graphData: {
                    ...state.graphData,
                    [action.graph.graphName]: {
                        ...state.graphData[action.graph.graphName],
                        isLoading: false,
                        isFetched: true,
                        ...action.payload,
                    },
                },
            };
    }
};

const fetchLineGraphsSuccessHandler = (state, action) => {
    let aggregateValue = '';
    let hasNegative = false;
    let incompleteData = false;
    const [unit, conversionFunction] = getUnitAndConversionFunction(state.selectedCurrency);
    return {
        ...state,
        graphData: {
            ...state.graphData,
            [action.graph.graphName]: {
                ...state.graphData[action.graph.graphName],
                isLoading: false,
                isFetched: true,

                ...action.payload,
                amount: action.payload.amount.length
                    ? action.payload.amount.map((amt) => {
                          switch (action.graph.graphName) {
                              // case GRAPH_NAMES.REVENUE: {
                              //     const val = amt.revenue ? conversionFunction(amt.revenue) : null;
                              //     aggregateValue = `₹ ${conversionFunction(
                              //         action.payload.metaData.value,
                              //     )} ${unit}`;
                              //     hasNegative = hasNegative ? hasNegative : val ? val < 0 : false;
                              //     incompleteData = incompleteData
                              //         ? incompleteData
                              //         : amt.revenue
                              //         ? incompleteData
                              //         : true;
                              //     return {
                              //         ...amt,
                              //         revenue: val,
                              //     };
                              // }
                              case GRAPH_NAMES.ARR: {
                                  const val = amt.arr ? conversionFunction(amt.arr) : null;
                                  aggregateValue = `₹ ${conversionFunction(
                                      action.payload.metaData.value,
                                  )} ${unit}`;
                                  hasNegative = hasNegative ? hasNegative : val ? val < 0 : false;
                                  incompleteData = incompleteData
                                      ? incompleteData
                                      : amt.arr
                                      ? incompleteData
                                      : true;
                                  return {
                                      ...amt,
                                      arr: val,
                                  };
                              }
                              case GRAPH_NAMES.CASH_BALANCE: {
                                  const val = amt.cashBalance
                                      ? conversionFunction(amt.cashBalance)
                                      : null;
                                  aggregateValue = `₹ ${conversionFunction(
                                      action.payload.metaData.value,
                                  )} ${unit}`;
                                  hasNegative = hasNegative ? hasNegative : val ? val < 0 : false;
                                  incompleteData = incompleteData
                                      ? incompleteData
                                      : amt.cashBalance
                                      ? incompleteData
                                      : true;
                                  return {
                                      ...amt,
                                      cashBalance: val,
                                  };
                              }
                              case GRAPH_NAMES.GROSS_MARGIN: {
                                  const val = amt.grossMargin
                                      ? parseFloat(parseFloat(amt.grossMargin).toFixed(2))
                                      : null;
                                  aggregateValue = `${parseFloat(
                                      action.payload.metaData.value,
                                  ).toFixed(2)}%`;
                                  hasNegative = hasNegative ? hasNegative : val ? val < 0 : false;
                                  incompleteData = incompleteData
                                      ? incompleteData
                                      : amt.grossMargin
                                      ? incompleteData
                                      : true;
                                  return {
                                      ...amt,
                                      grossMargin: val,
                                  };
                              }
                              case GRAPH_NAMES.ARPA: {
                                  const val = amt.arpa ? conversionFunction(amt.arpa) : null;
                                  aggregateValue = `₹ ${conversionFunction(
                                      action.payload.metaData.value,
                                  )} ${unit}`;
                                  hasNegative = hasNegative ? hasNegative : val ? val < 0 : false;
                                  incompleteData = incompleteData
                                      ? incompleteData
                                      : amt.arpa
                                      ? incompleteData
                                      : true;
                                  return {
                                      ...amt,
                                      arpa: val,
                                  };
                              }
                              default:
                                  return {
                                      ...amt,
                                  };
                          }
                      })
                    : [],
                aggregateValue: aggregateValue,
                hasNegative: hasNegative,
                incompleteData: incompleteData,
            },
        },
    };
};

const fetchWaterfallGraphSuccessHandler = (state, action) => {
    let hasNegative = false;
    const [unit, conversionFunction] = getUnitAndConversionFunction(state.selectedCurrency);
    if (action.payload.amount.length) {
        let data: any = [];
        let aggregateValue, value, initialValue;
        if (action.graph.graphName === GRAPH_NAMES.ARR_BRIDGE) {
            aggregateValue = `₹ ${conversionFunction(action.payload.metaData.value)} ${unit}`;
            Object.keys(action.payload.amount[0]).map((period) => {
                switch (period) {
                    case 'openingArr':
                        value = conversionFunction(action.payload.amount[0][period]);
                        initialValue = 0.0;
                        hasNegative = hasNegative ? hasNegative : value + initialValue < 0;
                        data[0] = {
                            period: 'Opening ARR',
                            value,
                            initialValue,
                        };
                        break;
                    case 'newARR':
                        value = conversionFunction(action.payload.amount[0][period]);
                        initialValue = conversionFunction(
                            action.payload.amount[0]['openingArr'] || 0,
                        );
                        hasNegative = hasNegative ? hasNegative : value + initialValue < 0;
                        data[1] = {
                            period: 'New ARR',
                            value,
                            initialValue,
                        };
                        break;
                    case 'netExpansion':
                        value = conversionFunction(action.payload.amount[0][period]);
                        initialValue = conversionFunction(
                            (action.payload.amount[0]['openingArr'] || 0) +
                                (action.payload.amount[0]['newARR'] || 0),
                        );
                        hasNegative = hasNegative ? hasNegative : value + initialValue < 0;
                        data[2] = {
                            period: 'Net Expansion',
                            value,
                            initialValue,
                        };
                        break;
                    case 'churn':
                        value = conversionFunction(action.payload.amount[0][period]);
                        initialValue = conversionFunction(
                            (action.payload.amount[0]['openingArr'] || 0) +
                                (action.payload.amount[0]['newARR'] || 0) +
                                (action.payload.amount[0]['netExpansion'] || 0),
                        );
                        hasNegative = hasNegative ? hasNegative : value + initialValue < 0;
                        data[3] = {
                            period: 'Churn',
                            value,
                            initialValue,
                        };
                        break;
                    default:
                        return {};
                }
                return period;
            });
            value = conversionFunction(
                (action.payload.amount[0]['openingArr'] || 0) +
                    (action.payload.amount[0]['newARR'] || 0) +
                    (action.payload.amount[0]['netExpansion'] || 0) +
                    (action.payload.amount[0]['churn'] || 0),
            );
            initialValue = 0.0;
            hasNegative = hasNegative ? hasNegative : value + initialValue < 0;
            data.push({
                period: 'Closing ARR',
                value,
                initialValue,
            });
        } else if (action.graph.graphName === GRAPH_NAMES.PNL_SNPASHOT) {
            aggregateValue = `₹ ${conversionFunction(action.payload.metaData.value)} ${unit}`;
            Object.keys(action.payload.amount[0]).map((period) => {
                switch (period) {
                    case 'Revenue':
                        // value = conversionFunction(action.payload.amount[0][period]);
                        // initialValue = 0;
                        value = 100;
                        initialValue = 0;
                        hasNegative = hasNegative ? hasNegative : value + initialValue < 0;
                        data[0] = {
                            period: 'Revenue',
                            value,
                            initialValue,
                        };
                        hasNegative = hasNegative
                            ? hasNegative
                            : action.payload?.amount[0][period] < 0;
                        break;
                    case 'Direct Costs':
                        // value = conversionFunction(action.payload.amount[0][period] || 0);
                        // initialValue = conversionFunction(action.payload.amount[0]['Revenue'] || 0);
                        value =
                            ((action.payload?.amount[0][period] ?? 0) /
                                action.payload?.amount[0]['Revenue']) *
                            100;
                        initialValue = 100;
                        hasNegative = hasNegative ? hasNegative : value + initialValue < 0;
                        data[1] = {
                            period: 'Direct Costs',
                            value,
                            initialValue,
                        };
                        break;
                    case 'CAC':
                        // value = conversionFunction(action.payload.amount[0][period]);
                        // initialValue = conversionFunction(
                        //     (action.payload.amount[0]['Revenue'] || 0) +
                        //     (action.payload.amount[0]['Direct Costs'] || 0),
                        // );
                        value =
                            ((action.payload?.amount[0][period] ?? 0) /
                                action.payload?.amount[0]['Revenue']) *
                            100;
                        initialValue =
                            (((action.payload?.amount[0]['Revenue'] ?? 0) +
                                (action.payload?.amount[0]['Direct Costs'] ?? 0)) /
                                action.payload?.amount[0]['Revenue']) *
                            100;
                        hasNegative = hasNegative ? hasNegative : value + initialValue < 0;
                        data[2] = {
                            period: 'CAC',
                            value,
                            initialValue,
                        };
                        break;
                    case 'G&A':
                        // value = conversionFunction(action.payload.amount[0][period]);
                        // initialValue = conversionFunction(
                        //     (action.payload.amount[0]['Revenue'] || 0) +
                        //     (action.payload.amount[0]['Direct Costs'] || 0) +
                        //     (action.payload.amount[0]['CAC'] || 0),
                        // );
                        value =
                            ((action.payload?.amount[0][period] ?? 0) /
                                action.payload?.amount[0]['Revenue']) *
                            100;
                        initialValue =
                            (((action.payload?.amount[0]['Revenue'] ?? 0) +
                                (action.payload?.amount[0]['Direct Costs'] ?? 0) +
                                (action.payload?.amount[0]['CAC'] ?? 0)) /
                                action.payload?.amount[0]['Revenue']) *
                            100;
                        hasNegative = hasNegative ? hasNegative : value + initialValue < 0;
                        data[3] = {
                            period: 'G&A',
                            value,
                            initialValue,
                        };
                        break;
                    default:
                        return {};
                }
                return period;
            });
            // value = conversionFunction(
            //     (action.payload.amount[0]['Revenue'] || 0) +
            //     (action.payload.amount[0]['Direct Costs'] || 0) +
            //     (action.payload.amount[0]['CAC'] || 0) +
            //     (action.payload.amount[0]['G&A'] || 0),
            // );
            value =
                (((action.payload?.amount[0]['Revenue'] ?? 0) +
                    (action.payload?.amount[0]['Direct Costs'] ?? 0) +
                    (action.payload?.amount[0]['CAC'] ?? 0) +
                    (action.payload?.amount[0]['G&A'] ?? 0)) /
                    action.payload?.amount[0]['Revenue']) *
                100;
            initialValue = 0;
            hasNegative = hasNegative ? hasNegative : value + initialValue < 0;
            data.push({
                period: 'EBITDA',
                value,
                initialValue,
            });
        } else if (action.graph.graphName === GRAPH_NAMES.CASH_FLOW_SNAPSHOT) {
            aggregateValue = `₹ ${conversionFunction(action.payload.metaData.value)} ${unit}`;
            Object.keys(action.payload.amount[0]).map((period) => {
                switch (period) {
                    case 'OpeningBalance':
                        value = conversionFunction(action.payload.amount[0][period] || 0);
                        initialValue = 0;
                        hasNegative = hasNegative ? hasNegative : value + initialValue < 0;
                        data[0] = {
                            period: 'Opening Balance',
                            value,
                            initialValue,
                        };
                        hasNegative = hasNegative
                            ? hasNegative
                            : action.payload.amount[0][period] < 0;
                        break;
                    case 'Operating':
                        value = conversionFunction(action.payload.amount[0][period] || 0);
                        initialValue = conversionFunction(
                            action.payload.amount[0]['OpeningBalance'] || 0,
                        );
                        hasNegative = hasNegative ? hasNegative : value + initialValue < 0;
                        data[1] = {
                            period: 'Operating',
                            value,
                            initialValue,
                        };
                        break;
                    case 'Investing':
                        value = conversionFunction(action.payload.amount[0][period] || 0);
                        initialValue = conversionFunction(
                            (action.payload.amount[0]['OpeningBalance'] || 0) +
                                (action.payload.amount[0]['Operating'] || 0),
                        );
                        hasNegative = hasNegative ? hasNegative : value + initialValue < 0;
                        data[2] = {
                            period: 'Investing',
                            value,
                            initialValue,
                        };
                        break;
                    case 'Financing':
                        value = conversionFunction(action.payload.amount[0][period] || 0);
                        initialValue = conversionFunction(
                            (action.payload.amount[0]['OpeningBalance'] || 0) +
                                (action.payload.amount[0]['Operating'] || 0) +
                                (action.payload.amount[0]['Investing'] || 0),
                        );
                        hasNegative = hasNegative ? hasNegative : value + initialValue < 0;
                        data[3] = {
                            period: 'Financing',
                            value,
                            initialValue,
                        };
                        break;
                    default:
                        return {};
                }
                return period;
            });
            value = conversionFunction(
                (action.payload.amount[0]['OpeningBalance'] || 0) +
                    (action.payload.amount[0]['Operating'] || 0) +
                    (action.payload.amount[0]['Investing'] || 0) +
                    (action.payload.amount[0]['Financing'] || 0),
            );
            initialValue = 0;
            hasNegative = hasNegative ? hasNegative : value + initialValue < 0;
            data.push({
                period: 'Closing Balance',
                value,
                initialValue,
            });
        } else if (action.graph.graphName === GRAPH_NAMES.CUSTOMER_BRIDGE) {
            aggregateValue = action.payload.metaData.value;
            Object.keys(action.payload.amount[0]).map((period) => {
                switch (period) {
                    case 'openingLogos':
                        value = action.payload.amount[0][period] || 0;
                        initialValue = 0;
                        hasNegative = hasNegative ? hasNegative : value + initialValue < 0;
                        data[0] = {
                            period: 'Opening Logos',
                            value,
                            initialValue,
                        };
                        hasNegative = hasNegative
                            ? hasNegative
                            : action.payload.amount[0][period] < 0;
                        break;
                    case 'newLogos':
                        value = action.payload.amount[0][period] || 0;
                        initialValue = action.payload.amount[0]['openingLogos'] || 0;
                        hasNegative = hasNegative ? hasNegative : value + initialValue < 0;
                        data[1] = {
                            period: 'New Logos',
                            value,
                            initialValue,
                        };

                        break;
                    case 'churnLogos':
                        value = action.payload.amount[0][period] || 0;
                        initialValue =
                            (action.payload.amount[0]['openingLogos'] || 0) +
                            (action.payload.amount[0]['newLogos'] || 0);
                        hasNegative = hasNegative ? hasNegative : value + initialValue < 0;
                        data[2] = {
                            period: 'Churn Logos',
                            value,
                            initialValue,
                        };
                        break;
                    default:
                        return {};
                }
                return period;
            });
            value =
                (action.payload.amount[0]['openingLogos'] || 0) +
                (action.payload.amount[0]['newLogos'] || 0) +
                (action.payload.amount[0]['churnLogos'] || 0);
            initialValue = 0;
            hasNegative = hasNegative ? hasNegative : value + initialValue < 0;
            data.push({
                period: 'Closing Logos',
                value,
                initialValue,
            });
        }
        return {
            ...state,
            graphData: {
                ...state.graphData,
                [action.graph.graphName]: {
                    ...state.graphData[action.graph.graphName],
                    isLoading: false,
                    isFetched: true,

                    ...action.payload,
                    amount: data.filter((e) => e),
                    aggregateValue: `${aggregateValue}`,
                    hasNegative: hasNegative,
                },
            },
        };
    } else {
        //IF NONE OF THE WATERFALL GRAPH TYPES MATCH, RETURN NORMAL STATE
        return {
            ...state,
            graphData: {
                ...state.graphData,
                [action.graph.graphName]: {
                    ...state.graphData[action.graph.graphName],
                    isLoading: false,
                    isFetched: true,

                    ...action.payload,
                    amount: [],
                },
            },
        };
    }
};

const fetchDoubleBarSuccessHandler = (state, action) => {
    const [unit, conversionFunction] = getUnitAndConversionFunction(state.selectedCurrency);
    const data = action.payload.amount || [];
    let hasNegative = false;
    let incompleteData = false;
    if (action.graph.graphName === GRAPH_NAMES.CASH_MOVEMENT) {
        return {
            ...state,
            graphData: {
                ...state.graphData,
                [action.graph.graphName]: {
                    ...state.graphData[action.graph.graphName],
                    isLoading: false,
                    isFetched: true,

                    ...action.payload,
                    amount: data.length
                        ? data.map((amt) => {
                              hasNegative = hasNegative
                                  ? hasNegative
                                  : parseFloat(amt.cashIn) < 0 || parseFloat(amt.cashOut) < 0;
                              incompleteData = incompleteData
                                  ? incompleteData
                                  : !amt.cashIn || !amt.cashOut;
                              return {
                                  ...amt,
                                  cashIn: conversionFunction(amt.cashIn),
                                  cashOut: conversionFunction(amt.cashOut),
                              };
                          })
                        : [],
                    aggregateValue: `₹ ${conversionFunction(
                        action.payload.metaData.value,
                    )} ${unit}`,
                    hasNegative: hasNegative,
                    incompleteData: incompleteData,
                },
            },
        };
    } else if (action.graph.graphName === GRAPH_NAMES.WORKING_CAPITAL_CYCLE) {
        return {
            ...state,
            graphData: {
                ...state.graphData,
                [action.graph.graphName]: {
                    ...state.graphData[action.graph.graphName],
                    isLoading: false,
                    isFetched: true,

                    ...action.payload,
                    amount: data.length
                        ? data.map((amt) => {
                              hasNegative = hasNegative
                                  ? hasNegative
                                  : parseInt(amt['Payable Days']) < 0 ||
                                    parseInt(amt['Receivable Days']) < 0;
                              incompleteData = incompleteData
                                  ? incompleteData
                                  : !amt['Payable Days'] || !amt['Receivable Days'];
                              return {
                                  ...amt,
                                  payableDays: amt['Payable Days']
                                      ? parseInt(amt['Payable Days'])
                                      : null,
                                  receivableDays: amt['Receivable Days']
                                      ? parseInt(amt['Receivable Days'])
                                      : null,
                              };
                          })
                        : [],
                    aggregateValue: `${action.payload.metaData.value} Days`,
                    hasNegative: hasNegative,
                    incompleteData: incompleteData,
                },
            },
        };
    } else return;
};

const fetchStackBarSuccessHandler = (state, action) => {
    const [unit, conversionFunction] = getUnitAndConversionFunction(state.selectedCurrency);
    let hasNegative = false;
    let incompleteData = false;
    if (action.graph.graphName === GRAPH_NAMES.TOTAL_COST) {
        return {
            ...state,
            graphData: {
                ...state.graphData,
                [action.graph.graphName]: {
                    ...state.graphData[action.graph.graphName],
                    isLoading: false,
                    isFetched: true,

                    ...action.payload,
                    amount: action.payload.amount
                        ? action.payload.amount.map((amt) => {
                              hasNegative = hasNegative
                                  ? hasNegative
                                  : parseFloat(amt['Direct Costs']) < 0 ||
                                    parseFloat(amt['CAC']) < 0 ||
                                    parseFloat(amt['G&A']) < 0;
                              incompleteData = incompleteData
                                  ? incompleteData
                                  : !amt['Direct Costs'] || !amt['CAC'] || !amt['G&A'];
                              return {
                                  period: amt.period,
                                  'Direct Costs': conversionFunction(amt['Direct Costs']),
                                  CAC: conversionFunction(amt['CAC']),
                                  'G&A': conversionFunction(amt['G&A']),
                              };
                          })
                        : [],
                    aggregateValue: `₹ ${conversionFunction(
                        action.payload.metaData.value,
                    )} ${unit}`,
                    hasNegative: hasNegative,
                    incompleteData: incompleteData,
                },
            },
        };
    } else return;
};

const fetchBrushGraphSuccessHandler = (state, action) => {
    const [unit, conversionFunction] = getUnitAndConversionFunction(state.selectedCurrency);
    let hasNegative = false;
    let incompleteData = false;
    if (action.graph.graphName === GRAPH_NAMES.CASH_FLOW_MOVEMENT) {
        return {
            ...state,
            graphData: {
                ...state.graphData,
                [action.graph.graphName]: {
                    ...state.graphData[action.graph.graphName],
                    isLoading: false,
                    isFetched: true,

                    ...action.payload,
                    amount: action.payload.amount.map((amt) => {
                        hasNegative = hasNegative
                            ? hasNegative
                            : parseFloat(amt.Investing) < 0 ||
                              parseFloat(amt.Operating) < 0 ||
                              parseFloat(amt.Financing) < 0;
                        incompleteData = incompleteData
                            ? incompleteData
                            : !amt.Investing || !amt.Operating || !amt.Financing;
                        switch (action.graph.graphName) {
                            case GRAPH_NAMES.CASH_FLOW_MOVEMENT: {
                                return {
                                    ...amt,
                                    Investing: conversionFunction(amt.Investing),
                                    Operating: conversionFunction(amt.Operating),
                                    Financing: conversionFunction(amt.Financing),
                                };
                            }
                        }
                        return amt;
                    }),
                    aggregateValue: `₹ ${conversionFunction(
                        action.payload.metaData.value,
                    )} ${unit}`,
                    hasNegative: hasNegative,
                    incompleteData: incompleteData,
                },
            },
        };
    } else return;
};

const fetchLineBarSuccessHandler = (state, action) => {
    const [unit, conversionFunction] = getUnitAndConversionFunction(state.selectedCurrency);
    let hasNegative = false;
    let incompleteData = false;
    if (action.graph.graphName === GRAPH_NAMES.EBITDA) {
        return {
            ...state,
            graphData: {
                ...state.graphData,
                [action.graph.graphName]: {
                    ...state.graphData[action.graph.graphName],
                    isLoading: false,
                    isFetched: true,

                    ...action.payload,
                    amount:
                        action.payload.amount && action.payload.amount.length
                            ? action.payload.amount.map((amt) => {
                                  hasNegative = hasNegative
                                      ? hasNegative
                                      : parseFloat(amt.Revenue) < 0 ||
                                        parseFloat(amt.Ebitda) < 0 ||
                                        parseFloat(amt.ebitdaMargin) < 0;
                                  incompleteData = incompleteData
                                      ? incompleteData
                                      : !amt.Revenue || !amt.Ebitda || !amt.ebitdaMargin;
                                  return {
                                      period: amt.period,
                                      REVENUE: amt.revenue ? parseFloat(amt.Revenue) : null,
                                      EBITDA: amt.Ebitda ? conversionFunction(amt.Ebitda) : null,
                                      ebitdaMargin: amt.ebitdaMargin
                                          ? parseFloat(amt.ebitdaMargin)
                                          : null,
                                  };
                              })
                            : [],
                    aggregateValue: `${parseFloat(action.payload.metaData.value).toFixed(2)}%`,
                    hasNegative: hasNegative,
                    incompleteData: incompleteData,
                },
            },
        };
    } else if (action.graph.graphName === GRAPH_NAMES.CLOSING_LOGOS) {
        return {
            ...state,
            graphData: {
                ...state.graphData,
                [action.graph.graphName]: {
                    ...state.graphData[action.graph.graphName],
                    isLoading: false,
                    isFetched: true,

                    ...action.payload,
                    aggregateValue: action.payload.metaData.value,
                },
            },
        };
    } else return;
};

const setIsFetched = (state, action) => {
    return {
        ...state,
        graphData: {
            ...state.graphData,
            [action.payload]: {
                ...state.graphData[action.payload],
                isFetched: false,
            },
        },
    };
};

const changeSelectedCurrency = (state, action) => {
    let graphData = JSON.parse(JSON.stringify(state.graphData));
    Object.keys(graphData).forEach((graphName) => {
        graphData[graphName] = {
            ...state.graphData[graphName],
            isFetched: false,
        };
    });
    return {
        ...state,
        selectedCurrency: action.selectedCurrency,
        graphData: graphData,
    };
};

const resetAllGraphsIsFetched = (state, action) => {
    let graphData = JSON.parse(JSON.stringify(state.graphData));
    Object.keys(graphData).forEach((graphName) => {
        graphData[graphName] = {
            ...state.graphData[graphName],
            isFetched: false,
        };
    });
    return {
        ...state,
        graphData: graphData,
    };
};

const changeGraphOrders = (state, action) => {
    const unlockedGraphs = action?.data || [];
    const graphsSection1 = JSON.parse(JSON.stringify(state.pageSections[0].graphs));
    const graphsSection2 = JSON.parse(JSON.stringify(state.pageSections[1].graphs));
    let section_1_enabled: string[] = [],
        section_1_disabled: string[] = [],
        section_2_enabled: string[] = [],
        section_2_disabled: string[] = [];
    graphsSection1.forEach((graph) => {
        if (unlockedGraphs?.includes(graph)) section_1_enabled.push(graph);
        else section_1_disabled.push(graph);
    });
    graphsSection2.forEach((graph) => {
        if (unlockedGraphs?.includes(graph)) section_2_enabled.push(graph);
        else section_2_disabled.push(graph);
    });

    return {
        ...state,
        pageSections: [
            {
                ...state.pageSections[0],
                graphs: [...section_1_enabled, ...section_1_disabled],
            },
            {
                ...state.pageSections[1],
                graphs: [...section_2_enabled, ...section_2_disabled],
            },
        ],
    };
};

const reducer = (state: DashboardState = inititalState, action: any): DashboardState => {
    switch (action.type) {
        case CONSTANTS.SET_IS_LOADING:
            return setIsLoading(state, action);
        case CONSTANTS.SET_SELECTED_PERIOD:
            return setSelectedPeriod(state, action);
        case CONSTANTS.SET_IS_LOADING_GRAPH:
            return setIsLoadingGraph(state, action);
        case CONSTANTS.SET_IS_FETCHED:
            return setIsFetched(state, action);
        case CONSTANTS.SET_RECURRING_METRICS_DATES:
            return setRecurringMetricsDates(state, action);
        case CONSTANTS.RESET_ALL_GRAPHS_IS_FETCHED:
            return resetAllGraphsIsFetched(state, action);
        case CONSTANTS.CHANGE_SELECTED_CURRENCY:
            return changeSelectedCurrency(state, action);
        case CONSTANTS.CHANGE_GRAPH_ORDERS:
            return changeGraphOrders(state, action);

        case CONSTANTS.GET_DASHBOARD_METADATA_SUCCESS:
            return getDashboardMetadataSuccess(state, action);
        // case CONSTANTS.GET_GRAPH_DATA_SUCCESS:
        //     return getGraphDataSuccess(state, action);
        default:
            return state;
    }
};

export default reducer;
