import * as Effects from 'redux-saga/effects';
import * as apiService from './service';
import { Actions } from '../actiontypes';
import { CONSTANTS } from './constants';
import { toast } from 'react-toastify';

const call: any = Effects.call;
const put: any = Effects.put;
const takeLatest: any = Effects.takeLatest;

function* fetchClassifications(action: Actions.Classifications.FetchClassifications) {
    yield put({
        type: CONSTANTS.SET_LOADING_CLASSIFICATIONS,
        payload: true,
    });
    const response = yield call(apiService.getClassifications, action.classificationsPayload);
    switch (response && response.status) {
        case 200:
            yield put({
                type: CONSTANTS.FETCH_CLASSIFICATIONS_SUCCESS,
                payload: response.data.data,
            });
            yield put({
                type: CONSTANTS.FETCH_RAW_DATA,
                className: action.classificationsPayload.className,
                payload: action.rawDataPayload,
            });
            break;
        case 400:
            yield put({
                type: CONSTANTS.SET_LOADING_CLASSIFICATIONS,
                payload: false,
            });
            break;
        default:
            return toast.error('Error fetching classifications');
    }
}

function* fetchRawData(action: Actions.Classifications.FetchRawData) {
    yield put({
        type: CONSTANTS.SET_LOADING_RAW_DATA,
        payload: true,
    });
    const response = yield call(apiService.getRawData, action.className, action.payload);
    switch (response && response.status) {
        case 200:
            yield put({
                type: CONSTANTS.FETCH_RAW_DATA_SUCCESS,
                payload: response.data.data,
            });
            break;
        case 400:
            yield put({
                type: CONSTANTS.SET_LOADING_RAW_DATA,
                payload: false,
            });
            break;
        default:
            return toast.error('Error fetching table data');
    }
}

function* updateClassifications(action: Actions.Classifications.UpdateClassifications) {
    yield put({
        type: CONSTANTS.SET_UPDATING_CLASSIFICATIONS,
        payload: true,
    });
    const response = yield call(
        apiService.updateClassifications,
        action.payload,
        action.params,
        action.className,
    );
    switch (response && response.status) {
        case 200:
            toast.success('Classifications Updated!');
            yield put({
                type: CONSTANTS.UPDATE_CLASSIFICATIONS_SUCCESS,
                payload: response.data.data,
            });
            action.isEditing(false);
            yield put({
                type: CONSTANTS.FETCH_RAW_DATA,
                className: action.className,
                payload: action.refetchPayload,
            });
            break;
        case 400:
        default:
            // action.isEditing(false)
            yield put({
                type: CONSTANTS.SET_UPDATING_CLASSIFICATIONS,
                payload: false,
            });
            return toast.error(response.data.message);
    }
}

function* exportCsv(action: Actions.Classifications.FetchRawData) {
    const response = yield call(apiService.exportRawDataAsCsv, action.className, action.payload);
    switch (response && response.status) {
        case 200:
            return toast.success('Successfully exported CSV');
        case 400:
            return toast.error('Invalid Request');
        default:
            return toast.error('Something went wrong');
    }
}

export default function* actionWatcher() {
    yield takeLatest(CONSTANTS.FETCH_CLASSIFICATIONS, fetchClassifications);
    yield takeLatest(CONSTANTS.FETCH_RAW_DATA, fetchRawData);
    yield takeLatest(CONSTANTS.UPDATE_CLASSIFICATIONS, updateClassifications);
    yield takeLatest(CONSTANTS.EXPORT_CSV, exportCsv);
}
