import { getNext12Months } from '../../utils/utils';
const namespace = 'FORECASTS_';

export const CONSTANTS = {
    UNLOCK_FORECASTS: `${namespace}UNLOCK_FORECASTS`,
    UNLOCK_FORECASTS_SUCCESS: `${namespace}`,

    GET_FORECASTS_DATA: `${namespace}GET_FORECASTS_DATA`,

    GET_GRAPH_DATA: `${namespace}GET_GRAPH_DATA`,
    GET_GRAPH_DATA_SUCCESS: `${namespace}GET_GRAPH_DATA_SUCCESS`,
};

const months = getNext12Months();

export const FORECAST_ROW_TYPES = {
    MRR: 'MRR',
    MONTHLY_GROWTH: 'MONTHLY_GROWTH',
    CASH_IN_BANK: 'CASH_IN_BANK',
    MONTHLY_BURN: 'MONTHLY_BURN',
    EQUITY_RAISED: 'EQUITY_RAISED',
    EXTERNAL_DEBT: 'EXTERNAL_DEBT',
};

export const FORECAST_ROWS = {
    [FORECAST_ROW_TYPES.MRR]: {
        ...months.reduce((acc, curr) => ((acc[curr] = ''), acc), {}),
    },
    [FORECAST_ROW_TYPES.MONTHLY_GROWTH]: {
        ...months.reduce((acc, curr) => ((acc[curr] = ''), acc), {}),
    },
    [FORECAST_ROW_TYPES.CASH_IN_BANK]: {
        ...months.reduce((acc, curr) => ((acc[curr] = ''), acc), {}),
    },
    [FORECAST_ROW_TYPES.MONTHLY_BURN]: {
        ...months.reduce((acc, curr) => ((acc[curr] = ''), acc), {}),
    },
    [FORECAST_ROW_TYPES.EQUITY_RAISED]: {
        ...months.reduce((acc, curr) => ((acc[curr] = ''), acc), {}),
    },
    [FORECAST_ROW_TYPES.EXTERNAL_DEBT]: {
        ...months.reduce((acc, curr) => ((acc[curr] = ''), acc), {}),
    },
};

export const ROW_NAMES = {
    [FORECAST_ROW_TYPES.MRR]: 'Monthly Revenue',
    [FORECAST_ROW_TYPES.MONTHLY_GROWTH]: 'MoM Growth (%)',
    [FORECAST_ROW_TYPES.CASH_IN_BANK]: 'Cash in Bank',
    [FORECAST_ROW_TYPES.MONTHLY_BURN]: 'Monthly Burn',
    [FORECAST_ROW_TYPES.EQUITY_RAISED]: 'Equity Raise',
    [FORECAST_ROW_TYPES.EXTERNAL_DEBT]: 'External Debt Raise',
};
