import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

import {
    fetchClassificationsAndRawData,
    fetchRawData,
    resetDropdowns,
    resetRawData,
    setSelectedRowClassification,
    updateClassifications,
} from '../../../../store/RawData/actions';
import { CLASS_NAMES } from '../../../../store/RawData/constants';
import editIcon from '../../../../assets/edit-view.svg';
import blackCross from '../../../../assets/black-cross.svg';
import saveIcon from '../../../../assets/save-icon.svg';
import Loader from '../../../../common/_custom/Loader/Loader';
import Dropdown from '../../../../common/_custom/Dropdown';
import styles from './EditClassification.module.scss';
import Spinner from '../../../../common/_custom/Spinner/Spinner';
import { useUpdateEffect } from '../../../../utils/customHooks';
import {
    formatPeriodForTable,
    initializeDatesForFinancials,
    mixPanelTrack,
} from '../../../../utils/utils';

interface EditClassificationProps {
    tableName: string;
}

function EditClassification(props: EditClassificationProps) {
    const { tableName } = props;
    const [isEditing, setIsEditing] = useState(false);
    const [editedRows, setEditedRows] = useState<{}[]>([]);
    const [editedClassification, setEditedClassification] = useState<{}>({});
    const [selectedDropdownIndex, setSelectedDropdownIndex] = useState<null | number>();

    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.user);
    const classificationsData = useAppSelector((state) => state.rawData);
    const selectedPeriod = useAppSelector((state) => state.periodSelector.selectedPeriod);
    const classificationsPayload = { className: CLASS_NAMES[tableName] };
    const [fromDate, toDate] = initializeDatesForFinancials(selectedPeriod.name ?? '');
    const rawDataPayload = {
        investeeOrgId: user.investeeOrgId,
        startDate: fromDate,
        endDate: toDate,
        tenure: selectedPeriod.name,
        csv: false,
        totalRow: true,
        ...(params.classificationId === 'all'
            ? {}
            : {
                  classificationId: classificationsData.classificationId || params.classificationId,
              }),
    };

    useEffect(() => {
        dispatch(fetchClassificationsAndRawData(classificationsPayload, rawDataPayload));
        return () => {
            dispatch(resetRawData());
        };
        //eslint-disable-next-line
    }, []);

    useUpdateEffect(() => {
        dispatch(fetchRawData(CLASS_NAMES[tableName], rawDataPayload));
    }, [selectedPeriod]);

    const changeRowClassification = (selectedOption, index, row, initialRow) => {
        setEditedClassification((prev) => {
            return {
                ...prev,
                [initialRow.id]: {
                    current_classification: initialRow.selectedOption.name,
                    updated_classification: selectedOption.name,
                    legend: initialRow.legend,
                    subLegend: initialRow.subLegend,
                    current_frequency: selectedPeriod.name,
                    current_finance_Tab: tableName,
                },
            };
        });
        dispatch(setSelectedRowClassification({ selectedOption: selectedOption, index: index }));
        if (row.selectedOption.id !== selectedOption.id) {
            setEditedRows((rows) => [
                ...rows,
                {
                    id: row.id,
                    classificationId: selectedOption.id,
                    prevClassificationId: row.classification,
                },
            ]);
        }
    };

    const saveUpdatedRows = () => {
        let id = localStorage.getItem('user_id');
        Object.keys(editedClassification).map((key) => {
            mixPanelTrack({
                id,
                trackUserObj: editedClassification[key],
                trackCategoryName: 'Saved classification',
            });
        });
        dispatch(
            updateClassifications(
                CLASS_NAMES[tableName],
                { classifications: editedRows },
                { investeeOrgId: user.investeeOrgId },
                rawDataPayload,
                setIsEditing,
            ),
        );
    };

    const cancelEdit = () => {
        setIsEditing(false);
        dispatch(resetDropdowns());
    };

    const backToTable = () => {
        navigate(`/financials/${CLASS_NAMES[tableName]}`);
    };

    return (
        <div className={styles.EditClassification}>
            <div className={styles.EditClassificationHeader}>
                <div className={styles.EditClassificationBreadCrumb}>
                    <strong
                        onClick={backToTable}
                        className={styles.EditClassificationBreadCrumbClickable}
                    >
                        {tableName}&nbsp;&nbsp;
                    </strong>
                    <span>&gt;&nbsp;&nbsp;</span>
                    {params.classificationId &&
                    classificationsData?.classificationsMap[params.classificationId]
                        ? classificationsData?.classificationsMap[params.classificationId].name
                        : 'Raw Data'}
                </div>

                {!isEditing ? (
                    <div
                        className={styles.EditClassificationButton}
                        onClick={() => {
                            let id = localStorage.getItem('user_id');
                            let trackUserObj = {
                                current_finance_Tab: tableName,
                                current_frequency: selectedPeriod.name,
                            };
                            mixPanelTrack({
                                id,
                                trackUserObj,
                                trackCategoryName: 'Clicked on Edit classification CTA',
                            });
                            setIsEditing(true);
                        }}
                    >
                        <img src={editIcon} alt="edit" height="14px" width="14px" />
                        Edit Classification
                    </div>
                ) : (
                    <div className={styles.SaveClassification}>
                        <div>Save Classification:</div>
                        <div className={styles.ButtonContainer}>
                            <div className={styles.CancelButton} onClick={cancelEdit}>
                                <img src={blackCross} alt="edit" height="10px" width="10px" />
                                Cancel
                            </div>
                            <div className={styles.SaveButton} onClick={saveUpdatedRows}>
                                {classificationsData.updatingClassifications ? (
                                    <>
                                        Saving &nbsp;&nbsp;
                                        <Spinner />
                                    </>
                                ) : (
                                    <>
                                        <img src={saveIcon} alt="edit" height="10px" width="10px" />
                                        Save
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {classificationsData.loadingRawData ? (
                <Loader noFullScreen={true} />
            ) : (
                <div className={styles.TableContainer}>
                    <table className={styles.Table}>
                        <thead
                            className={styles.TableHead}
                            style={{ zIndex: classificationsData.data.rows.length + 1 }}
                        >
                            <tr>
                                {classificationsData.data.headers.length
                                    ? classificationsData.data.headers.map((column, i) => (
                                          <th
                                              key={i}
                                              className={clsx({
                                                  [styles.HeadMain]: i === 0,
                                                  [styles.HeadSecond]: i === 1,
                                                  [styles.HeadThird]: i === 2,
                                                  [styles.HeadItem]: i > 2,
                                              })}
                                          >
                                              {i > 2
                                                  ? formatPeriodForTable(column.name)
                                                  : column.name}
                                          </th>
                                      ))
                                    : null}
                            </tr>
                        </thead>
                        <tbody className={styles.TableBody}>
                            {classificationsData.data.rows.length
                                ? classificationsData.data.rows.map((row, i) => (
                                      <tr
                                          className={clsx({
                                              [styles.TableBodyRow]: true,
                                              [styles.TotalRow]: row?.legend === 'Total',
                                          })}
                                          key={i}
                                      >
                                          <td className={clsx(styles.BodyMain, styles.StickyFirst)}>
                                              {row?.subLegend ?? '-'}
                                          </td>

                                          <td
                                              className={clsx(styles.BodyItem, styles.StickySecond)}
                                          >
                                              {row?.legend ?? '-'}
                                          </td>

                                          <td
                                              className={clsx(styles.BodyItem, styles.StickyThird)}
                                              style={{
                                                  zIndex: selectedDropdownIndex === i ? '25' : '10',
                                              }}
                                              onClick={() => setSelectedDropdownIndex(i)}
                                          >
                                              {row?.legend !== 'Total' ? (
                                                  <Dropdown
                                                      selectedOption={row.selectedOption}
                                                      optionsList={
                                                          classificationsData.classifications
                                                      }
                                                      itemClickHandler={(item) =>
                                                          changeRowClassification(
                                                              item,
                                                              i,
                                                              row,
                                                              classificationsData
                                                                  .initialClassificationData.rows[
                                                                  i
                                                              ],
                                                          )
                                                      }
                                                      disabled={!isEditing}
                                                      small
                                                      collapseDropdown={selectedDropdownIndex !== i}
                                                  />
                                              ) : (
                                                  '-'
                                              )}
                                          </td>
                                          {classificationsData.data.headers.length
                                              ? classificationsData.data.headers
                                                    .slice(3)
                                                    .map((period, i) => (
                                                        <td key={i} className={styles.BodyItem}>
                                                            {isNaN(
                                                                parseFloat(
                                                                    row.amountsMap[period.name],
                                                                ),
                                                            )
                                                                ? '-'
                                                                : parseInt(
                                                                      row.amountsMap[period.name],
                                                                  ) < 0
                                                                ? `(${Math.abs(
                                                                      Math.round(
                                                                          parseFloat(
                                                                              row.amountsMap[
                                                                                  period.name
                                                                              ],
                                                                          ),
                                                                      ),
                                                                  ).toLocaleString('en-IN')})`
                                                                : Math.round(
                                                                      parseFloat(
                                                                          row.amountsMap[
                                                                              period.name
                                                                          ],
                                                                      ),
                                                                  ).toLocaleString('en-IN')}
                                                        </td>
                                                    ))
                                              : null}
                                      </tr>
                                  ))
                                : null}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}

export default EditClassification;
