// -- REDUX CONSTANTS --//

const namespace = 'DASHBOARD_';

export const CONSTANTS = {
    SET_IS_LOADING: `${namespace}SET_IS_LOADING`,
    SET_SELECTED_PERIOD: `${namespace}SET_SELECTED_PERIOD`,
    SET_IS_LOADING_GRAPH: `${namespace}SET_IS_LOADING_GRAPH`,
    SET_IS_FETCHED: `${namespace}SET_IS_FETCHED`,
    SET_RECURRING_METRICS_DATES: `${namespace}SET_RECURRING_METRICS_DATES`,
    RESET_ALL_GRAPHS_IS_FETCHED: `${namespace}RESET_ALL_GRAPHS_IS_FETCHED`,
    CHANGE_SELECTED_CURRENCY: `${namespace}CHANGE_SELECTED_CURRENCY`,
    CHANGE_GRAPH_ORDERS: `${namespace}CHANGE_GRAPH_ORDERS`,

    GET_DASHBOARD_METADATA: `${namespace}DET_DASHBOARD_METADATA`,
    GET_DASHBOARD_METADATA_SUCCESS: `${namespace}GET_DASHBOARD_METADATA_SUCCESS`,

    GET_GRAPH_DATA: `${namespace}GET_GRAPH_DATA`,
    GET_GRAPH_DATA_SUCCESS: `${namespace}GET_GRAPH_DATA_SUCCESS`,
};

// ---- xx ---- //

//-- GRAPH CONSTANTS --//

export const GRAPH_NAMES = {
    REVENUE: 'revenue',
    ARR_BRIDGE: 'arrBridge',
    CASH_BALANCE: 'cashBalance',
    CASH_MOVEMENT: 'cashMovement',
    CASH_FLOW_MOVEMENT: 'cashFlowMovement',
    CASH_FLOW_SNAPSHOT: 'cashFlowBridge',
    TOTAL_COST: 'totalCost',
    PNL_SNPASHOT: 'pnlSnapshot',
    EBITDA: 'ebitda',
    GROSS_MARGIN: 'grossMargin',
    CLOSING_LOGOS: 'closingLogos',
    CUSTOMER_BRIDGE: 'customerBridge',
    ARPA: 'arpa',
    WORKING_CAPITAL_CYCLE: 'workingCapitalCycle',
    META_DATA: 'metaData',
    ARR: 'arr',
};

export const GRAPH_TYPES = {
    LINE: 'linechart',
    WATERFALL: 'waterfall',
    STACKBAR: 'stackbar',
    DOUBLEBAR: 'DoubleBar', //not needed
    LINEBAR: 'linebarchart',
    BRUSH: 'brush',
    BARCHART: 'barchart',
    HORIZONTAL_BARCHART: 'horizontalbarchart',
    PIECHART: 'piechart',
    PIECHARTCUSTOMTOOLTIP: 'piechartwithcustomtooltip',
    VERTICALBARCHART: 'verticalBarchart',
    // INDIAMAP: 'indiamap',
    MONTHLYVIEW: 'monthlyview',
    HEATMAP: 'heatmap',
};

// -- xx -- //

export const Periods = {
    MONTHLY: 'Monthly',
    QUARTERLY: 'Quarterly',
    YEARLY: 'Yearly',
};

export const PeriodOptions = [
    {
        id: 1,
        name: 'Monthly',
        value: Periods.MONTHLY,
    },
    {
        id: 2,
        name: 'Quarterly',
        value: Periods.QUARTERLY,
    },
    {
        id: 3,
        name: 'Annually',
        value: Periods.YEARLY,
    },
];

//-------- CONSTANT TYPES ----------//

export const STAT_TYPES = {
    ARR_GROWTH_RATE: 'arrGrowthRate',
    GROSS_MARGIN: 'grossMargin',
    LTV_CAC: 'ltvCac',
    RUNWAY: 'runway',
    RECUR_SCORE: 'recurScore',
};

export const CURRENCY_TYPES = {
    LACS: 'LACS',
    CRORES: 'CRORES',
};

export const CURRENCY_DROPDOWN_OPTIONS = [
    { id: 1, value: CURRENCY_TYPES.LACS, name: 'INR Lacs' },
    { id: 2, value: CURRENCY_TYPES.CRORES, name: 'INR Crores' },
];
