import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import RootRoutes from './routes/RootRoutes';
import ApplyInterceptor from './common/_util/ApplyInterceptor';
import 'react-toastify/dist/ReactToastify.min.css';
import './scss/App.scss';

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <ApplyInterceptor />
                <RootRoutes />
            </BrowserRouter>

            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    );
}

export default App;
