import React, { useState } from 'react';
import { DropdownOption } from './dropdownTypes';
import bottomAngleIcon from './assets/bottomAngleIcon.svg';
import './Dropdown.scss';
import { useUpdateEffect } from '../../../utils/customHooks';

/**
 * @Description Custom Dropdown component
 * @param customClass custom Css class to apply desired styles to root Dropdown div
 * @param customProps custom props to pass to main dropdown Div
 * @param label custom string label for dropdown
 * @param optionsList array of objects where each object is a rendered option in the dropdown. Object is of structure : {id:number, label: string, value: string}
 * @param selectedOption the object from optionsList which is the current selected value
 * @param itemClickHandler function which runs on choosing an option from dropdown. Use this function to bind the redux/state value changing when selecting new option
 */

interface DropdownProps {
    customClass?: string;
    customProps?: any;
    label?: string;
    disabled?: boolean;
    small?: boolean;
    tiny?: boolean;
    medium?: boolean;
    transparent?: boolean;
    collapseDropdown?: boolean;
    nowrap?: boolean;
    selectedOption: DropdownOption;
    optionsList: DropdownOption[];
    itemClickHandler: (item: DropdownOption) => void;
}

const Dropdown = ({
    customClass,
    customProps,
    label,
    optionsList,
    selectedOption,
    itemClickHandler,
    disabled,
    small,
    tiny,
    medium,
    transparent,
    collapseDropdown,
    nowrap,
}: DropdownProps) => {
    const [showDetails, updateShowDetails] = useState(false);

    useUpdateEffect(() => {
        if (disabled) updateShowDetails(false);
        if (collapseDropdown) updateShowDetails(false);
    }, [disabled, collapseDropdown]);

    return (
        <React.Fragment>
            {label ? <label className="dropdownLabel">{label}</label> : null}
            <div
                className={customClass ? `Dropdown ${customClass}` : 'Dropdown'}
                {...customProps}
                onClick={() => {
                    if (disabled || optionsList?.length <= 1) return;
                    updateShowDetails(!showDetails);
                }}
                // style={disabled ? {cursor:"none"}:{}}
            >
                <div
                    className="Dropdown__Main"
                    style={{
                        ...(disabled
                            ? {
                                  border: '1px solid transparent',
                                  cursor: 'auto',
                              }
                            : {
                                  border: '1px solid #e5e5e5',
                              }),
                        ...(medium
                            ? {
                                  width: '250px',
                                  padding: '4px 12px',
                              }
                            : {}),
                        ...(small
                            ? {
                                  width: '150px',
                                  padding: '4px 12px',
                              }
                            : {}),
                        ...(tiny
                            ? {
                                  width: '90px',
                                  padding: '8px 6px',
                              }
                            : {}),
                        ...(transparent
                            ? {
                                  backgroundColor: 'transparent',
                                  border: 'none',
                              }
                            : {}),
                        ...(nowrap
                            ? {
                                  whiteSpace: 'nowrap',
                              }
                            : {}),
                    }}
                >
                    {selectedOption?.name || 'Select'}
                    <div
                        className={`Dropdown__Main--Toggle Dropdown__Main--Toggle--${
                            showDetails ? 'Show' : 'Hide'
                        }`}
                    >
                        {!disabled && optionsList?.length > 1 ? (
                            <img
                                className={`Dropdown__Main--Toggle--Verticle Dropdown__Main--Toggle--Verticle--${
                                    showDetails ? 'Show' : 'Hide'
                                }`}
                                src={bottomAngleIcon}
                                alt="bottomAngleIcon"
                                width="24px"
                                height="24px"
                            />
                        ) : null}
                    </div>

                    <div
                        className={`Dropdown__Extended Dropdown__Extended--${
                            showDetails ? 'Show' : 'Hide'
                        }`}
                    >
                        {optionsList?.map((item, index) => (
                            <div
                                onClick={() => {
                                    itemClickHandler(item);
                                }}
                                key={item?.id || index}
                                className="Dropdown__Extended__item"
                            >
                                {item?.name}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Dropdown;
