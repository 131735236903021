const namespace = 'FINANCIALS_';

export const CONSTANTS = {
    SET_IS_LOADING: `${namespace}SET_IS_LOADING`,

    FETCH_PNL_DATA: `${namespace}FETCH_PNL_DATA`,
    FETCH_PNL_DATA_SUCCESS: `${namespace}FETCH_PNL_DATA_SUCCESS`,

    FETCH_BALANCE_SHEET: `${namespace}FETCH_BALANCE_SHEET`,
    FETCH_BALANCE_SHEET_SUCCESS: `${namespace}FETCH_BALANCE_SHEET_SUCCESS`,

    FETCH_CASHFLOW_STATEMENT: `${namespace}FETCH_CASHFLOW_STATEMENT`,
    FETCH_CASHFLOW_STATEMENT_SUCCESS: `${namespace}FETCH_CASHFLOW_STATEMENT_SUCCESS`,

    SET_SELECTED_BANK_ACCOUNT: `${namespace}SET_SELECTED_BANK_ACCOUNT`,
    FETCH_BANK_STATEMENT: `${namespace}FETCH_BANK_STATEMENT`,
    FETCH_BANK_STATEMENT_SUCCESS: `${namespace}FETCH_BANK_STATEMENT_SUCCESS`,

    FETCH_INVOICES_DATA: `${namespace}FETCH_INVOICES_DATA`,
    FETCH_INVOICES_DATA_SUCCESS: `${namespace}FETCH_INVOICES_DATA_SUCCESS`,

    FETCH_PAGE_SOURCE_DATA: `${namespace}FETCH_PAGE_SOURCE_DATA`,
    FETCH_PAGE_SOURCE_DATA_SUCCESS: `${namespace}FETCH_PAGE_SOURCE_DATA_SUCCESS`,

    EXPORT_CSV: `${namespace}EXPORT_CSV`,
};

export const TABLES = {
    PNL: 'Profit & Loss',
    BALANCE_SHEET: 'Balance Sheet',
    CASHFLOW_STATEMENT: 'Cashflow Statement',
    BANK_STATEMENT: 'Bank Statement',
    INVOICING: 'Invoicing',
    GST: 'Gst',
};

export const TABLE_ROW_TYPES = {
    DATA: 'Data',
    SUMMARY: 'Summary',
    BLANK: 'Blank',
    HEADING: 'Heading',
    SECTION_HEADING: 'Section_Heading',
};

export const RAW_DATA_TYPES = {
    [TABLES.PNL]: 'profit-and-loss',
    [TABLES.BALANCE_SHEET]: 'balance-sheets',
    [TABLES.CASHFLOW_STATEMENT]: 'cash-flows',
};

export const FILE_DOWNLOAD_NAMES = {
    [TABLES.PNL]: 'Classified_PnL',
    [TABLES.BALANCE_SHEET]: 'Classified_BalanceSheet',
    [TABLES.CASHFLOW_STATEMENT]: 'Classified_CashFlow',
    [TABLES.BANK_STATEMENT]: 'Classified_BankStatement',
    [TABLES.INVOICING]: 'Classified_Invoicing',
    [TABLES.GST]: 'Classified_Gst',
};

export const ROW_COLORS = {
    TEXT: {
        WHITE: '#FFFFFF',
        ORANGE: '#FF6231',
        GREEN: '#00AA00',
        RED: '#D95656',
        YELLOW: '#E3752B',
        BLUE: '#4C8DFF',
        PURPLE: '#6021B3',
        BLACK: '#000000',
        BROWN: '#A72800',
        DARK_GREEN: '#1C8A0A',
    },
    BG: {
        RED: '#FFEDED',
        WHITE: '#FFFFFF',
        GREY: '#e3e3e3',
        GREEN: '#ECFCD8',
    },
};

// export const TABLE_ROWS = {
//     PNL : {
//         HEADER: "HEADER",
//         UNCLASSIFIED: "UNCLASSIFIED",
//         REVENUE: "REVENUE",
//         DIRECT_COSTS: "DIRECT_COSTS",
//         GROSS_MARGIN: "GROSS_MARGIN",
//         CAC: "CAC",
//         G_A: "G&A",
//         TOTAL_COSTS: "TOTAL_COSTS",
//         EDITDA: "EDITDA",
//         OTHER_INCOME: "OTHER_INCOME",
//         FINANCE_COSTS: "FINANCE_COSTS",
//         DEPRECIATION: "DEPRECIATION",
//         TAX: "TAX",
//         PAT: "PAT"
//     }
// }
