const namespace = 'BENCHMARKING_';

export const CONSTANTS = {
    SET_IS_LOADING: `${namespace}SET_IS_LOADING`,

    FETCH_CRITERIA_OPTIONS: `${namespace}FETCH_CRITERIA_OPTIONS`,
    FETCH_CRITERIA_OPTIONS_SUCCESS: `${namespace}FETCH_CRITERIA_OPTIONS_SUCCESS`,
    SET_SELECTED_CRITERIA: `${namespace}SET_SELECTED_CRITERIA`,

    SET_IS_LOADING_METRIC: `${namespace}SET_IS_LOADING_METRIC`,
    FETCH_METRIC_DATA: `${namespace}FETCH_GRAPH_DATA`,
    FETCH_METRIC_DATA_SUCCESS: `${namespace}FETCH_GRAPH_DATA_SUCCESS`,
    FETCH_METRIC_DATA_ERROR: `${namespace}FETCH_METRIC_DATA_ERROR`,
};

export const BENCHMARK_NAMES = {
    RUNWAY: 'Runway',
    RECEIVABLE_CYCLE: 'ReceivableCycle',
    DEBT_EQUITY: 'DebtEquity',
};

export const BENCHMARK_TYPES = {
    RUNWAY: 'runway',
    ARR_GROWTH: 'arrGrowth',
    RECUR_SCORE: 'recurScore',
    RECEIVABLE_CYCLES: 'receivableCycle',
    LTV_CAC: 'ltvCac',
    GROSS_MARGIN: 'grossMargin',
    DEBT_EQUITY: 'debtEquity',
    CUSTOMER_CONCENTRATION: 'customerConcentration',
    ARR_CHURN: 'arrChurn',
    RULE_OF_FORTY: 'ruleOfForty',
};
