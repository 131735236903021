import {CONSTANTS} from './constants';
import {Actions} from '../actiontypes';

interface LoginState {
    isLoading: boolean,
    loginData: {}[],
    count: number
}

const inititalState: LoginState = {
    isLoading: false,
    loginData: [],
    count: 1
};

// const fetchDataSuccess = (state:LoginState,action: Actions.ObjectPayloadAction):LoginState => {
//     return {
//         ...state,
//         isLoading: false,
//     }
// }

const reducer = (state:LoginState = inititalState,action: Actions.AllActionTypes): LoginState => {
    switch (action.type) {
        // case CONSTANTS.FETCH_DATA_SUCCESS: return fetchDataSuccess(state,action)
        default: return state;
    }
};

export default reducer;