import axios from 'axios';
import { API_ENDPOINTS } from '../apiEndpoints';

export function getMetricDataService(payload) {
    return axios({
        url: API_ENDPOINTS.BENCHMARKING.FETCH_METRIC_DATA.url(payload),
        method: API_ENDPOINTS.BENCHMARKING.FETCH_METRIC_DATA.method,
        params: API_ENDPOINTS.BENCHMARKING.FETCH_METRIC_DATA.params(payload),
    })
        .then((response) => response)
        .catch((err) => err.response);
}

export function getCriteriaOptionsService(payload) {
    return axios({
        url: API_ENDPOINTS.BENCHMARKING.FETCH_CRITERIA_OPTIONS.url(payload),
        method: API_ENDPOINTS.BENCHMARKING.FETCH_CRITERIA_OPTIONS.method,
    })
        .then((response) => response)
        .catch((err) => err.response);
}
