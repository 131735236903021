import * as Effects from 'redux-saga/effects';
import * as actions from './actions';
import * as apiService from './service';
import { Actions } from '../actiontypes';
import { CONSTANTS } from './constants';
import { toast } from 'react-toastify';
import { ROUTES } from '../../RoutesConst';
import { CONSTANTS as DASHBOARD_CONSTANTS } from '../Dashboard/constants';
import { CONSTANTS as DB_CONSTANTS } from '../DashboardNew/constants';

const call: any = Effects.call;
const put: any = Effects.put;
// const select: any = Effects.select;
const takeLatest: any = Effects.takeLatest;

function* fetchUserInfo(action: Actions.User.FetchInfoAction) {
    yield put(actions.setIsLoading(true));
    const response = yield call(apiService.getUserInfoService, action.payload);
    switch (response && response.status) {
        case 200:
            yield put({
                type: CONSTANTS.FETCH_INFO_SUCCESS,
                payload: response.data.data,
            });
            action?.onSuccess?.();
            break;
        case 400:
            yield put(actions.setIsLoading(false));
            break;
        case 500:
        case 502:
            window.location.href = 'https://app.recur.club';
            return toast.error('Invalid Org Id');
        default:
            return;
    }
}

function* updateOrgData(action: Actions.User.UpdateOrgDataAction) {
    yield put(actions.setUpdatingOrgData(true));
    const response = yield call(apiService.updateOrgDataService, action.payload);
    switch (response && response.status) {
        case 200:
            yield put({
                type: CONSTANTS.UPDATE_ORGANIZATION_DATA_SUCCESS,
                data: action.payload?.data,
            });
            break;
        default:
            yield put(actions.setUpdatingOrgData(false));
            return toast.error('Something went wrong');
    }
}

function* fetchUnlockedChartsCount(action: any) {
    const searchParams = new URLSearchParams(window.location.search);
    const is_aica = localStorage.getItem('is_aica') || false;
    const query_is_aica = searchParams.get('is_aica');

    if (query_is_aica) localStorage.setItem('is_aica', 'true');
    else localStorage.setItem('is_aica', 'false');

    yield put({
        type: CONSTANTS.SET_IS_LOADING_CHARTS_DATA,
        payload: true,
    });
    const response = yield call(apiService.getUnlockedChartsCountService, action.payload);
    const { navigate, location } = action.handlers;
    const unlockedCharts = response.data?.data?.unlocked || 0;
    const unlockedChartsArr = response.data?.data?.unlockedCharts || [];
    switch (response && response.status) {
        case 200:
            yield put({
                type: CONSTANTS.FETCH_UNLOCKED_CHARTS_COUNT_SUCCESS,
                data: response.data.data,
            });
            yield put({
                type: DASHBOARD_CONSTANTS.CHANGE_GRAPH_ORDERS,
                data: unlockedChartsArr,
            });
            if (navigate && location) {
                if (unlockedCharts === 0 && !is_aica)
                    navigate(`${ROUTES.PRIVATE.DATA_VAULT}?openUpload=true`);
                else if (location?.pathname === ROUTES.BASE)
                    navigate(`/${ROUTES.PRIVATE.DASHBOARD}`);
            }
            break;
        default:
            yield put({
                type: CONSTANTS.SET_IS_LOADING_CHARTS_DATA,
                payload: false,
            });
            return;
    }
}

export default function* actionWatcher() {
    yield takeLatest(CONSTANTS.FETCH_INFO, fetchUserInfo);
    yield takeLatest(CONSTANTS.UPDATE_ORGANIZATION_DATA, updateOrgData);
    yield takeLatest(CONSTANTS.FETCH_UNLOCKED_CHARTS_COUNT, fetchUnlockedChartsCount);
}
