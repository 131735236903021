import dayjs from 'dayjs';
import { CONSTANTS } from './constants';
import { Actions } from '../actiontypes';
import { DropdownTypes } from '../../common/_custom/Dropdown';
const now = dayjs();
const previousMonthLastDate = now.subtract(1, 'month').endOf('month');
const monthNumber = parseInt(now.format('MM'));
const quarterFromDate = now.subtract(12 + ((monthNumber % 3 || 3) - 1), 'months');

export interface PeriodSelectorState {
    selectedPeriod: DropdownTypes.DropdownOption;
    periodOptions: DropdownTypes.DropdownOption[];
    fromDate: string;
    toDate: string;
}

const inititalState: PeriodSelectorState = {
    selectedPeriod: {
        id: 1,
        name: 'Monthly',
    },
    periodOptions: [
        {
            id: 1,
            name: 'Monthly',
        },
        {
            id: 2,
            name: 'Quarterly',
        },
        {
            id: 3,
            name: 'Yearly',
        },
    ],
    fromDate: quarterFromDate.format('YYYY-MM-01 00:00:00'),
    toDate: previousMonthLastDate.format('YYYY-MM-DD HH:mm:ss'),
};

const setSelectedPeriod = (state, action) => {
    return {
        ...state,
        selectedPeriod: action.payload,
    };
};

const resetDropdown = (state) => {
    return {
        ...state,
        selectedPeriod: {
            id: null,
            name: null,
        },
    };
};

const setFromDate = (state, action) => {
    return {
        ...state,
        fromDate: action.payload,
    };
};

const setToDate = (state, action) => {
    return {
        ...state,
        toDate: action.payload,
    };
};

const reducer = (
    state: PeriodSelectorState = inititalState,
    action: Actions.AllActionTypes,
): PeriodSelectorState => {
    switch (action.type) {
        case CONSTANTS.SET_SELECTED_PERIOD:
            return setSelectedPeriod(state, action);
        case CONSTANTS.RESET_DROPDOWN:
            return resetDropdown(state);
        case CONSTANTS.SET_TO_DATE:
            return setToDate(state, action);
        case CONSTANTS.SET_FROM_DATE:
            return setFromDate(state, action);
        default:
            return state;
    }
};

export default reducer;
