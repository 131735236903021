import axios from 'axios';
import { API_ENDPOINTS } from '../apiEndpoints';
import { coreBaseUrl } from '../../utils/axios-interceptor';

export function getUserInfoService(payload) {
    return axios({
        baseURL: coreBaseUrl,
        url: API_ENDPOINTS.USER.GET_INFO.url,
        method: API_ENDPOINTS.USER.GET_INFO.method,
        data: payload,
    })
        .then((response) => response)
        .catch((err) => err.response);
}

export function updateOrgDataService(payload) {
    return axios({
        baseURL: coreBaseUrl,
        url: API_ENDPOINTS.USER.UPDATE_ORG_DATA.url(payload),
        method: API_ENDPOINTS.USER.UPDATE_ORG_DATA.method,
        data: payload.data,
    });
}

export function getUnlockedChartsCountService(payload) {
    return axios({
        url: API_ENDPOINTS.USER.GET_UNLOCKED_CHARTS_COUNT.url(payload),
        method: API_ENDPOINTS.USER.GET_UNLOCKED_CHARTS_COUNT.method,
    });
}
