export const ROUTES = {
    BASE: '/',
    PUBLIC: {
        LOGIN: 'login',
    },
    PRIVATE: {
        DASHBOARD: 'dashboard',
        FINANCIALS: {
            BASE: '/financials',

            PNL: '/financials/profit-and-loss',
            PNL_RAW: '/financials/profit-and-loss/raw/:classificationId',

            BALANCE_SHEET: '/financials/balance-sheets',
            BALANCE_SHEET_RAW: '/financials/balance-sheets/raw/:classificationId',

            CASHFLOW: '/financials/cash-flows',
            CASHFLOW_RAW: '/financials/cash-flows/raw/:classificationId',

            BANK_STATEMENT: '/financials/bank-statement',
            INVOICING: '/financials/invoicing',

            BANK_STATEMENT_SUBURLS: {
                CRED_TRANSACTION_NUM: 'cred-trans-num',
                CRED_TRANSACTION_AMT: 'cred-trans-amt',
                CRED_TRANSACTION_AVG: 'cred-trans-avg',
                DEB_TRANSACTION_NUM: 'deb-trans-num',
                DEB_TRANSACTION_AMT: 'deb-trans-amt',
                DEB_TRANSACTION_AVG: 'deb-trans-avg',
            },
            PNL_SUBURLS: {
                REVENUE: 'revenue',
                DIRECT_COSTS: 'direct-costs',
                CAC: 'cac',
                GNA: 'ga',
                OTHER_INCOME: 'other-income',
                FINANCE_COSTS: 'finance-costs',
                DEPRECIATION: 'depreciation',
                TAX: 'tax',
            },
            BALANCE_SHEETS_SUBURLS: {
                SHARE_CAPITAL: 'share-capital',
                RESERVE_SURPLUS: 'reserve-surplus',
                DIRECTOR_SHAREHOLDER_LOANS: 'dir-share-loans',
                SECURED_LOANS: 'secured-loans',
                UNSECURED_LOANS: 'unsecured-loans',
                NON_CURRENT_LIABILITIES: 'non-current-liabilities',
                TRADE_PAYABLES: 'trade-payables',
                SALARY_PAYABLES: 'salary-payables',
                STATUTORY_LIABILITIES: 'statutory-liabilities',
                OTHER_LIABILITIES: 'other-liabilities',
                TANGIBLE_ASSETS: 'tangible-assets',
                INTANGIBLE_ASSETS: 'intangible-assets',
                INVESTMENTS: 'investments',
                NON_CURRENT_ASSETS: 'non-current-assets',
                INVENTORIES: 'inventories',
                TRADE_RECEIVABLES: 'trade-receivables',
                CASH_AND_CASH_EQUIVALENTS: '',
                OTHER_ADVANCES_DEPOSITS: '',
                BALANCE_STATUTORY_AUTHORITIES: '',
                OTHER_ASSETS: '',
            },
        },
        BENCHMARKING: 'benchmarking',
        DATA_VAULT: 'data-vault',
        FORECAST: 'forecast',
    },
};
