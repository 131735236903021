import { put, call, select, takeLatest } from "redux-saga/effects";
import * as actions from './actions';
// import * as actionTypes from './actionTypes';
import {CONSTANTS} from "./constants";

// function* fetchData(payload: actionTypes.fetchDataAction) {
//     // window.alert("hi")
//     // yield put(actions.fetchDataSucces([{a:"hi"}]))
// }

// function alert(){
//     // window.alert("Fetch")
// }

export default function* actionWatcher() {
    // yield takeLatest(CONSTANTS.FETCH_DATA, fetchData);
}
  