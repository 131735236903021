/**
 * @Description
 * Component listing all routes for the application
 */

import { Suspense, useEffect, lazy } from 'react';
import axios from 'axios';
import { Routes, Route, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { ROUTES } from '../RoutesConst';
import { TABLES } from '../store/Financials/constants';
import { EditClassification } from '../modules/Financials/components';
import { fetchUserInfo, setInvesteeOrgId } from '../store/User/actions';
import { addTokenToHeaders } from '../utils/axios-interceptor';
import { toast } from 'react-toastify';

const PrivateRoutes = lazy(() => import('./PrivateRoutes'));
const DashboardNew = lazy(() => import('../modules/DashboardNew'));
const FinancialsRoutes = lazy(() => import('./FinancialsRoutes'));
const Benchmarking = lazy(() => import('../modules/Benchmarking'));
const Table = lazy(() => import('../common/Table/Table'));
const DataVault = lazy(() => import('../modules/DataVault'));
const Forecast = lazy(() => import('../modules/Forecast'));

function RootRoutes() {
    const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const investeeOrgId =
            searchParams.get('investeeOrgId') || localStorage.getItem('investee_org_id');
        const cerberusToken =
            searchParams.get('cerberusToken') || localStorage.getItem('cerberus_token');
        const userId = searchParams.get('userId') || localStorage.getItem('user_id');

        if (cerberusToken) {
            localStorage.setItem('cerberus_token', cerberusToken);
            addTokenToHeaders(axios, investeeOrgId, cerberusToken, userId);
        }
        if (investeeOrgId) {
            dispatch(setInvesteeOrgId(investeeOrgId));
            localStorage.setItem('investee_org_id', investeeOrgId);
        }
        if (userId) {
            localStorage.setItem('user_id', userId);
        }
        if (investeeOrgId && cerberusToken && userId) {
            dispatch(
                fetchUserInfo({ investeeOrgId: investeeOrgId, userId: userId }, resetQueryParams),
            );
        } else {
            toast.error('Invalid Url, Redirecting');
            setTimeout(() => {
                window.location.href = 'https://app.recur.club';
            }, 1000);
        }
        //eslint-disable-next-line
    }, []);

    const resetQueryParams = () => {
        const updatedSearchParms = searchParams;
        updatedSearchParms.delete('investeeOrgId');
        updatedSearchParms.delete('cerberusToken');
        updatedSearchParms.delete('userId');
        setSearchParams(updatedSearchParms);
    };
    return (
        <Routes>
            <Route path={ROUTES.PUBLIC.LOGIN} element={<>Login</>} />

            {isAuthenticated ? (
                <Route path={ROUTES.BASE} element={<PrivateRoutes />}>
                    <Route
                        path={ROUTES.PRIVATE.DASHBOARD}
                        element={
                            <Suspense fallback={<></>}>
                                <DashboardNew />
                            </Suspense>
                        }
                    />

                    <Route
                        path={ROUTES.PRIVATE.FINANCIALS.BASE}
                        element={
                            <Suspense fallback={<></>}>
                                <FinancialsRoutes />
                            </Suspense>
                        }
                    >
                        <Route index element={<Table tableName={TABLES.PNL} />} />

                        <Route
                            path={ROUTES.PRIVATE.FINANCIALS.PNL}
                            element={<Table tableName={TABLES.PNL} />}
                        />
                        <Route
                            path={ROUTES.PRIVATE.FINANCIALS.BALANCE_SHEET}
                            element={<Table tableName={TABLES.BALANCE_SHEET} />}
                        />
                        <Route
                            path={ROUTES.PRIVATE.FINANCIALS.CASHFLOW}
                            element={<Table tableName={TABLES.CASHFLOW_STATEMENT} />}
                        />
                        <Route
                            path={ROUTES.PRIVATE.FINANCIALS.BANK_STATEMENT}
                            element={<Table tableName={TABLES.BANK_STATEMENT} />}
                        />
                        <Route
                            path={ROUTES.PRIVATE.FINANCIALS.INVOICING}
                            element={<Table tableName={TABLES.INVOICING} />}
                        />

                        <Route
                            path={ROUTES.PRIVATE.FINANCIALS.PNL_RAW}
                            element={<EditClassification tableName={TABLES.PNL} />}
                        />
                        <Route
                            path={ROUTES.PRIVATE.FINANCIALS.CASHFLOW_RAW}
                            element={<EditClassification tableName={TABLES.CASHFLOW_STATEMENT} />}
                        />
                        <Route
                            path={ROUTES.PRIVATE.FINANCIALS.BALANCE_SHEET_RAW}
                            element={<EditClassification tableName={TABLES.BALANCE_SHEET} />}
                        />
                    </Route>

                    <Route
                        path={ROUTES.PRIVATE.BENCHMARKING}
                        element={
                            <Suspense fallback={<></>}>
                                <Benchmarking />
                            </Suspense>
                        }
                    />
                    <Route
                        path={ROUTES.PRIVATE.DATA_VAULT}
                        element={
                            <Suspense fallback={<></>}>
                                <DataVault />
                            </Suspense>
                        }
                    />

                    <Route
                        path={ROUTES.PRIVATE.FORECAST}
                        element={
                            <Suspense fallback={<></>}>
                                <Forecast />
                            </Suspense>
                        }
                    />
                </Route>
            ) : null}
        </Routes>
    );
}

export default RootRoutes;
