import * as Effects from 'redux-saga/effects';
import * as apiService from './service';
import { CONSTANTS } from './constants';
const call: any = Effects.call;
const put: any = Effects.put;
// const select: any = Effects.select;
const takeLatest: any = Effects.takeLatest;

function* fetchFinboxRedirectingUrlGenerator(action: any) {
    const response = yield call(apiService.fetchFinboxRedirectingUrl, action.payload);
    switch (response && response.status) {
        case 200:
            yield put({
                type: CONSTANTS.GET_FINBOX_REDIRECTING_URL_SUCCESS,
                url: response.data.data.redirect_url,
            });
            break;
        default:
            yield put({
                type: CONSTANTS.GET_FINBOX_REDIRECTING_URL_FAILURE,
                payload: true,
            });
            break;
    }
}

// function* fetchMonthlyDataGenerator(action: any) {
//     yield put({
//         type: CONSTANTS.SET_IS_LOADING,
//         payload: true,
//     });
//     const response = yield call(apiService.fetchMonthlyData, action.payload);
//     switch (response && response.status) {
//         case 200:
//             yield put({
//                 type: CONSTANTS.GET_MONTHLY_DATA_SUCCESS,
//                 monthlyData: response.data.data,
//             });
//             break;
//         case 400:
//             yield put({
//                 type: CONSTANTS.SET_IS_LOADING,
//                 payload: false,
//             });
//             break;
//         default:
//             return;
//     }
// }

export default function* actionWatcher() {
    yield takeLatest(CONSTANTS.GET_FINBOX_REDIRECTING_URL, fetchFinboxRedirectingUrlGenerator);
    // yield takeLatest(CONSTANTS.GET_MONTHLY_DATA, fetchMonthlyDataGenerator);
}
