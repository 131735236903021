import { CONSTANTS } from './constants';
import { Actions } from '../actiontypes';

export interface UserState {
    isAuthenticated: boolean;
    isLoading: boolean;
    infoFetched: boolean;
    investeeOrgId: string;
    investeeOnboardedId: string;
    name: string;
    userName: string;

    updatingOrgData: boolean;
    investeeOrganization: {
        tally_connector: boolean;
    };
    charts: {
        loadingChartsData: boolean;
        total: number;
        unlocked: number;
    };
}

const inititalState: UserState = {
    isAuthenticated: true,
    isLoading: false,
    infoFetched: false,
    investeeOrgId: '',
    investeeOnboardedId: '',
    name: '',
    userName: '',
    updatingOrgData: false,
    investeeOrganization: {
        tally_connector: false,
    },
    charts: {
        loadingChartsData: true,
        total: 15,
        unlocked: 1,
    },
};

const setIsLoading = (state, action) => {
    return {
        ...state,
        isLoading: action.payload,
    };
};

const setIsLoadingCharts = (state, action) => {
    return {
        ...state,
        charts: {
            ...state.charts,
            loadingChartsData: action.payload,
        },
    };
};

const setUpdatingOrgData = (state, action) => {
    return {
        ...state,
        updatingOrgData: action.payload,
    };
};

const fetchInfoSuccess = (state, action) => {
    return {
        ...state,
        isLoading: false,
        infoFetched: true,
        ...action.payload,
    };
};

const setInvesteeOrgId = (state, action) => {
    return {
        ...state,
        investeeOrgId: action.payload,
    };
};

const setInvesteeOnboardedId = (state, action) => {
    return {
        ...state,
        investeeOnboardedId: action.payload,
    };
};

const updateOrgDataSuccess = (state, action) => {
    return {
        ...state,
        updatingOrgData: false,
        investeeOrganization: {
            ...state.investeeOrganization,
            ...action?.data?.investeeorganization,
        },
    };
};

const fetchUnlockedChartsCountSuccess = (state, action) => {
    return {
        ...state,
        charts: {
            ...state.charts,
            ...action.data,
            loadingChartsData: false,
        },
    };
};

const reducer = (state: UserState = inititalState, action: Actions.AllActionTypes): UserState => {
    switch (action.type) {
        case CONSTANTS.SET_IS_LOADING:
            return setIsLoading(state, action);
        case CONSTANTS.SET_IS_LOADING_CHARTS_DATA:
            return setIsLoadingCharts(state, action);
        case CONSTANTS.SET_UPDATING_ORG_DATA:
            return setUpdatingOrgData(state, action);
        case CONSTANTS.SET_INVESTEE_ORG_ID:
            return setInvesteeOrgId(state, action);
        case CONSTANTS.SET_INVESTEE_ONBOARDED_ID:
            return setInvesteeOnboardedId(state, action);
        case CONSTANTS.FETCH_INFO_SUCCESS:
            return fetchInfoSuccess(state, action);
        case CONSTANTS.UPDATE_ORGANIZATION_DATA_SUCCESS:
            return updateOrgDataSuccess(state, action);
        case CONSTANTS.FETCH_UNLOCKED_CHARTS_COUNT_SUCCESS:
            return fetchUnlockedChartsCountSuccess(state, action);
        default:
            return state;
    }
};

export default reducer;
