import dayjs from 'dayjs';
import UTC from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ROUTES } from '../RoutesConst';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { CURRENCY_TYPES } from '../store/Dashboard/constants';
import { store } from '../store/store';
import { Mixpanel } from './mixpanels';

export const LAKH = 100000;
export const CRORE = 10000000;
dayjs.extend(quarterOfYear);
dayjs.extend(UTC);
dayjs.extend(customParseFormat);

export const isMobile = window.innerWidth < 800;

export const getRawDataClassfication = () => {
    const path = window.location.pathname;
    return path.split('/')[2];
};

export const formatPeriod = (period) => {
    //THIS FUNCTION IS USED TO MOD X AXIS OF GRAPHS AND PERIOD IN GRAPH METADATA
    const today = dayjs();
    const currentYear = today.format('YYYY');
    const currentMonth = today.format('MM');
    let val = period;
    if (typeof val !== 'string') val += '';
    if (val.includes('q')) {
        let periods = val.split('_');
        periods[0] = periods[0].replace('q', 'Q');
        periods[1] = parseInt(periods[1].slice(-2));
        val = `${periods[0]}'FY${periods[1] - 1}-${periods[1]}`;
        return val;
    }
    if (val.includes('_20')) return val.replace('_20', "'");
    else if (val.includes('20') && val.indexOf('20') === 0) {
        const currentFy =
            parseInt(currentMonth) <= 3 ? parseInt(currentYear) - 1 : parseInt(currentYear);
        let fyVal = parseInt(val.slice(-2));
        return `${parseInt(period) === currentFy ? 'YTD ' : ''}FY'${fyVal}-${fyVal + 1}`;
    }
    return val;
};

export const formatPeriodForTable = (period) => {
    //THIS FUNCTION IS USED TO FORMAT HEADERS FOR TABLE
    const state = store.getState();
    const selectedPeriod = state.periodSelector.selectedPeriod.name;

    let val = period;
    if (typeof val !== 'string') val += '';
    if (val === '-') return val;

    switch (selectedPeriod) {
        case 'Yearly': {
            //string format: 2021 => convert to FY'20-21
            let fyVal = parseInt(val.slice(-2));
            return `FY'${fyVal}-${fyVal + 1}`;
        }
        case 'Quarterly': {
            //string format: q3_2020 => convert to Q3'20
            val = val.replace('_20', "'").replace('q', 'Q');
            return val;
        }
        case 'Monthly': {
            //string format Aug_2020 => convert to Aug'20
            return val.replace('_20', "'");
        }
        default:
            return '';
    }
};

export const fillMissingData = (
    rowsArray: {}[] | undefined,
    headersArray: { period?: string }[],
) => {
    const rowsLength = rowsArray?.length;
    const headersLength = headersArray?.length;
    if (rowsLength && rowsLength < headersLength) {
        let newRows = [...rowsArray];
        for (let i = rowsLength; i < headersLength; i++) {
            newRows[i] = {
                period: headersArray[i]?.period,
                amount: '-',
            };
        }
        return newRows;
    } else return rowsArray;
};

export function randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export function convertArrayToMap(arr, key): {}[] {
    return arr.reduce(function (map, obj) {
        map[obj[key]] = obj;
        return map;
    }, {});
}

export function isEmpty(data: {}[]): Boolean {
    if (!data) return false;
    if (Array.isArray(data)) return !(data.length > 0);
    return !(Object.keys(data).length > 0);
}

export function getPrevious12Months() {
    let today = new Date();
    today.setMonth(today.getMonth() - 12);
    let dates: string[] = [];
    for (let i = 0; i < 13; i++) {
        let month = today.getMonth();
        let year = today.getFullYear();
        dates.push(`${month + 1 < 10 ? '0' : ''}${month + 1}-${year}`);
        if (month === 0) {
            today.setMonth(1);
        }
        today.setMonth(month + 1);
    }
    return dates;
}

export function getNext12Months() {
    let today = new Date();
    today.setMonth(today.getMonth());
    let dates: string[] = [];
    for (let i = 0; i < 12; i++) {
        let month = today.getMonth();
        let year = today.getFullYear();
        dates.push(`${month + 1 < 10 ? '0' : ''}${month + 1}-${year}`);
        if (month === 0) {
            today.setMonth(1);
        }
        today.setMonth(month + 1);
    }
    return dates;
}

export function getHeadersForForecasts() {
    let headers = [
        {
            key: 'name',
            value: 'Particulars',
        },
    ];

    const months = getNext12Months();
    months.forEach((month) => {
        headers.push({
            key: 'period',
            value: month,
        });
    });
    return headers;
}

export const formatMonthName = (monthString: string) => {
    let [month, year] = monthString?.split('-');
    return monthString ? `${monthsEnum[month]?.name}'${year?.replace('20', '')}` : ' ';
};

export const monthsEnum = {
    '01': {
        name: 'Jan',
        fullName: 'January',
    },
    '02': {
        name: 'Feb',
        fullName: 'February',
    },
    '03': {
        name: 'Mar',
        fullName: 'March',
    },
    '04': {
        name: 'Apr',
        fullName: 'April',
    },
    '05': {
        name: 'May',
        fullName: 'May',
    },
    '06': {
        name: 'Jun',
        fullName: 'June',
    },
    '07': {
        name: 'Jul',
        fullName: 'July',
    },
    '08': {
        name: 'Aug',
        fullName: 'August',
    },
    '09': {
        name: 'Sep',
        fullName: 'September',
    },
    '10': {
        name: 'Oct',
        fullName: 'October',
    },
    '11': {
        name: 'Nov',
        fullName: 'November',
    },
    '12': {
        name: 'Dec',
        fullName: 'December',
    },
};

export function getInitialsFromName(string) {
    var names = string.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
}

export function isFinancialsPage() {
    return window.location.pathname.includes(ROUTES.PRIVATE.FINANCIALS.BASE);
}

export function initializeDatepickerDates(periodSelectorVariant: string | null) {
    const DATE_FORMAT = 'YYYY-MM-DD';
    if (!periodSelectorVariant) return [];
    const now = dayjs();
    const previousMonthLastDate = now.subtract(1, 'month').endOf('month');
    const monthlyStartDate = previousMonthLastDate.subtract(11, 'months').startOf('month');

    const currentQuarterStart = now.startOf('quarter');
    const quarterlyStartDate = currentQuarterStart.subtract(3, 'quarters').startOf('quarter');

    const currentFinancialYearStart =
        now.month() < 3
            ? now
                  .year(now.year() - 1)
                  .startOf('year')
                  .add(3, 'month')
            : now.startOf('year').add(3, 'month');
    const previousFinancialYearStart = currentFinancialYearStart.subtract(1, 'year');

    let toDate = '';
    let fromDate = '';
    switch (periodSelectorVariant) {
        case 'Monthly':
            toDate = previousMonthLastDate.format(DATE_FORMAT);
            fromDate = monthlyStartDate.format(DATE_FORMAT);
            break;
        case 'Quarterly':
            toDate = now.format(DATE_FORMAT);
            fromDate = quarterlyStartDate.format(DATE_FORMAT);
            break;
        case 'Yearly':
            toDate = now.format(DATE_FORMAT);
            fromDate = previousFinancialYearStart.format(DATE_FORMAT);
            break;
    }

    return [fromDate, toDate, previousMonthLastDate.format(DATE_FORMAT)];
}

// export function intializeDatesForRecurringMetrics(periodSelectorVariant: string) {
//     //Initializing dates for Recurring metrics. (Graphs in Section 2 of Dashboard)
//     //Current month/quarter/year is skipped in this case.
//     const now = dayjs();
//     const yearAgoDate = now.subtract(12, 'months');
//     const twoYearDate = now.subtract(24, 'months');
//     const monthNumber = parseInt(now.format('MM'));
//     const quarterFromDate = now.subtract(12 + ((monthNumber % 3 || 3) - 1), 'months');
//     const quarterToDate = dayjs().subtract(1, 'quarter').endOf('quarter');

//     let toDate = '';
//     let fromDate = '';
//     switch (periodSelectorVariant) {
//         case 'Monthly':
//             fromDate = `${yearAgoDate.format('YYYY-MM-01 00:00:00')}`;
//             toDate = `${now.subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')}`;
//             break;
//         case 'Quarterly':
//             fromDate = `${quarterFromDate.format('YYYY-MM-01 00:00:00')}`;
//             toDate = `${quarterToDate.format('YYYY-MM-DD HH:mm:ss')}`;
//             break;
//         case 'Yearly':
//             fromDate = `${twoYearDate.format('YYYY-01-01 00:00:00')}`;
//             toDate = `${
//                 monthNumber < 4
//                     ? now.subtract(1, 'year').endOf('quarter').format('YYYY-MM-DD HH:mm:ss')
//                     : now.startOf('year').endOf('quarter').format('YYYY-MM-DD HH:mm:ss')
//             }`;
//             break;
//     }

//     return [fromDate, toDate];
// }

export function initializeDatesForFinancials(periodSelectorVariant: string) {
    const now = dayjs();
    const yearAgoDate = now.subtract(23, 'months');
    const twoYearDate = now.subtract(36, 'months');
    const monthNumber = parseInt(now.format('MM'));
    const quarterFromDate = now.subtract(21 + ((monthNumber % 3 || 3) - 1), 'months');

    let toDate = now.format('YYYY-MM-DD HH:mm:ss');
    let fromDate = '';
    switch (periodSelectorVariant) {
        case 'Monthly':
            fromDate = `${yearAgoDate.format('YYYY-MM-01 00:00:00')}`;
            break;
        case 'Quarterly':
            fromDate = `${quarterFromDate.format('YYYY-MM-01 00:00:00')}`;
            break;
        case 'Yearly':
            fromDate = `${twoYearDate.format('YYYY-01-01 00:00:00')}`;
            break;
    }

    return [fromDate, toDate];
}

export function getFormattedDate(
    date: string,
    periodSelectorVariant: string | null,
    isFinancials = false,
) {
    //USED IN DATEPICKER
    if (!date || !periodSelectorVariant) return '-';

    const dateTime = dayjs(date);
    if (isFinancials) return dateTime.format('YYYY');

    switch (periodSelectorVariant) {
        case 'Monthly':
            return dateTime.format("MMM'YY");
        case 'Quarterly': {
            let fyQuarter = Math.ceil((parseInt(dateTime.format('MM')) - 3) / 3);
            fyQuarter = fyQuarter <= 0 ? 4 : fyQuarter;
            return `Q${fyQuarter}'FY${parseInt(
                dateTime.add(parseInt(dateTime.format('MM')) > 3 ? 1 : 0, 'years').format('YY'),
            )}`;
        }
        case 'Yearly':
            return dateTime.format('YYYY');
        default:
            return dateTime.format("MMM'YY");
    }
}

export function downloadCsvFromResponse(fileDta, fileName) {
    const href = URL.createObjectURL(fileDta);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `${fileName}.csv`); //or any other extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
}

export const convertToLacs = (val: number | string, hideDecimals = false) => {
    if (val === 0) return 0;
    if (!val) return null;

    if (hideDecimals) return Math.round((typeof val === 'string' ? parseFloat(val) : val) / 100000);
    return parseFloat(((typeof val === 'string' ? parseFloat(val) : val) / 100000).toFixed(2));
};

export const convertToCrores = (val: number | string) => {
    if (val === 0) return 0;
    if (!val) return null;
    return parseFloat(((typeof val === 'string' ? parseFloat(val) : val) / 10000000).toFixed(2));
};

export const convertToLacsOrCrores = (val: number | string) => {
    if (val === 0) return 0;
    if (!val) return null;
    let num = typeof val === 'string' ? parseFloat(val) : val;

    if (num < 10000000) return `${convertToLacs(val, true)}L`;
    else return `${convertToCrores(val)}Cr`;
};

export const getUnitAndConversionFunction = (selectedCurrency): any => {
    switch (selectedCurrency.value) {
        case CURRENCY_TYPES.LACS: {
            return ['lacs', convertToLacs];
        }
        case CURRENCY_TYPES.CRORES: {
            return ['cr', convertToCrores];
        }
    }
};

export const getTransformedHeadersAndRowsForCsv = (tableData) => {
    let headers = tableData?.headers ?? [];
    let rows = tableData?.rows ?? [];
    let transformedHeaders: any = [];
    let transformedRows: any = [];

    transformedHeaders = headers.map((header, index) => ({
        label: header.key === 'period' ? formatPeriodForTable(header?.name) : header?.name,
        key: index === 0 ? header?.key : `${header?.name}.amount`,
    }));
    transformedRows = rows.map((row) => {
        return {
            ...row,
            ...row.amountsMap,
        };
    });
    return {
        transformedHeaders,
        transformedRows,
    };
};

export function customTimeout(ms, callback) {
    return new Promise((resolve: Function) =>
        setTimeout(() => {
            callback && callback();
            resolve();
        }, ms),
    );
}
export const isEnvProd = process.env.REACT_APP_ENVIRONMENT === 'production';

export const DAYJS_DATE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]';

export const mixPanelTrack = ({ id, setUserObj = {}, trackUserObj, trackCategoryName }) => {
    if (!isEnvProd) return;
    Mixpanel.identify(id);
    if (setUserObj)
        Mixpanel.people.set({
            ...setUserObj,
            // ip_address: window.localStorage.getItem('myIp'),
            $time: dayjs().utc().utcOffset(330).format(DAYJS_DATE_FORMAT),
            userAgent: window.navigator.userAgent,
        });
    // event properties should flow to user property
    if (trackUserObj)
        Mixpanel.track(trackCategoryName, {
            ...trackUserObj,
            // ip_address: window.localStorage.getItem('myIp'),
            $time: dayjs().utc().utcOffset(330).format(DAYJS_DATE_FORMAT),
            userAgent: window.navigator.userAgent,
        });
};
