import axios from 'axios';
import { downloadCsvFromResponse } from '../../utils/utils';
import { API_ENDPOINTS } from '../apiEndpoints';
import { RAW_DATA_FILE_DOWNLOAD_NAMES } from './constants';

export function getClassifications(payload) {
    return axios({
        url: API_ENDPOINTS.EDIT_CLASSIFICATIONS.GET_CLASSIFICATIONS.url,
        method: API_ENDPOINTS.EDIT_CLASSIFICATIONS.GET_CLASSIFICATIONS.method,
        params: API_ENDPOINTS.EDIT_CLASSIFICATIONS.GET_CLASSIFICATIONS.params(payload),
    })
        .then((response) => response)
        .catch((err) => err.response);
}

export function getRawData(className, payload) {
    return axios({
        url: API_ENDPOINTS.EDIT_CLASSIFICATIONS.GET_RAW_DATA.url(className),
        method: API_ENDPOINTS.EDIT_CLASSIFICATIONS.GET_RAW_DATA.method,
        params: API_ENDPOINTS.EDIT_CLASSIFICATIONS.GET_RAW_DATA.params(payload),
    })
        .then((response) => response)
        .catch((err) => err.response);
}

export function updateClassifications(payload, params, className) {
    return axios({
        url: API_ENDPOINTS.EDIT_CLASSIFICATIONS.UPDATE_CLASSIFICATIONS.url(className),
        method: API_ENDPOINTS.EDIT_CLASSIFICATIONS.UPDATE_CLASSIFICATIONS.method,
        params: API_ENDPOINTS.EDIT_CLASSIFICATIONS.UPDATE_CLASSIFICATIONS.params(params),
        data: payload,
    })
        .then((response) => response)
        .catch((err) => err.response);
}

export function exportRawDataAsCsv(className, payload) {
    return axios({
        url: API_ENDPOINTS.EDIT_CLASSIFICATIONS.GET_RAW_DATA.url(className),
        method: API_ENDPOINTS.EDIT_CLASSIFICATIONS.GET_RAW_DATA.method,
        params: API_ENDPOINTS.EDIT_CLASSIFICATIONS.GET_RAW_DATA.params(payload),
        responseType: 'blob',
    })
        .then((response) => {
            const filename = `${RAW_DATA_FILE_DOWNLOAD_NAMES[className]}_${payload.tenure}`;
            downloadCsvFromResponse(response.data, filename);
            return response;
        })
        .catch((err) => err.response);
}
