import axios from 'axios';
import { coreBaseUrl } from '../../utils/axios-interceptor';
import { API_ENDPOINTS } from '../apiEndpoints';

export function fetchFinboxRedirectingUrl(payload) {
    return axios({
        baseURL: `${coreBaseUrl}/api`,
        url: API_ENDPOINTS.DATA_VAULT.GET_FINBOX_REDIRECTING_URL.url,
        method: API_ENDPOINTS.DATA_VAULT.GET_FINBOX_REDIRECTING_URL.method,
        data: payload,
    })
        .then((response) => response)
        .catch((err) => err.response);
}

export function fetchMonthlyDataService(payload) {
    return axios({
        baseURL: `${coreBaseUrl}/api`,
        url: API_ENDPOINTS.DATA_VAULT.GET_MONTHLY_DATA.url(payload),
        method: API_ENDPOINTS.DATA_VAULT.GET_MONTHLY_DATA.method,
    })
        .then((response) => response)
        .catch((err) => err.response);
}

export function getIntegrationInfoService(payload) {
    return axios({
        baseURL: `${coreBaseUrl}/api`,
        url: API_ENDPOINTS.DATA_VAULT.GET_INTEGRATIONS.url(payload),
        method: API_ENDPOINTS.DATA_VAULT.GET_INTEGRATIONS.method,
    })
        .then((response) => response)
        .catch((err) => err.response);
}

export function getMonthlyViewDataService(payload) {
    return axios({
        url: API_ENDPOINTS.DATA_VAULT.GET_MONTHLY_VIEW_DATA.url(payload),
        method: API_ENDPOINTS.DATA_VAULT.GET_MONTHLY_VIEW_DATA.method,
    })
        .then((response) => response)
        .catch((err) => err.response);
}
