import * as Effects from 'redux-saga/effects';
import * as apiService from './service';
import { CONSTANTS } from './constants';
import { toast } from 'react-toastify';
import { takeEvery } from 'redux-saga/effects';
const call: any = Effects.call;
const put: any = Effects.put;
// const select: any = Effects.select;
const takeLatest: any = Effects.takeLatest;
/**
 * @TODO: Separate the loaders for fetch dashboard info and fetchDashboardMetadata to show seaparate loaders
 *
 */

function* fetchDashboardMetadata(action: any) {
    yield put({
        type: CONSTANTS.SET_IS_LOADING,
        payload: true,
    });
    const response = yield call(apiService.getDashboardMetadataService, action.payload);
    switch (response && response.status) {
        case 200:
            yield put({
                type: CONSTANTS.GET_DASHBOARD_METADATA_SUCCESS,
                payload: response.data.data,
            });
            break;
        case 400:
            yield put({
                type: CONSTANTS.SET_IS_LOADING,
                payload: false,
            });
            break;
        default:
            return toast.error('Something went wrong');
    }
}

function* fetchGraphData(action: any) {
    yield put({
        type: CONSTANTS.SET_IS_LOADING_GRAPH,
        payload: true,
        graphName: action.graph.graphName,
    });
    const response = yield call(apiService.getGraphData, action.payload, action.graph);
    switch (response && response.status) {
        case 200:
            yield put({
                type: CONSTANTS.GET_GRAPH_DATA_SUCCESS,
                payload: response.data.data,
                graph: action.graph,
            });
            break;
        case 400:
            yield put({
                type: CONSTANTS.SET_IS_LOADING_GRAPH,
                payload: false,
                graphName: action.graphName,
            });
            break;
        default:
            return;
    }
}

export default function* actionWatcher() {
    yield takeLatest(CONSTANTS.GET_DASHBOARD_METADATA, fetchDashboardMetadata);
    // yield takeEvery(CONSTANTS.GET_GRAPH_DATA, fetchGraphData);
}
