import { CONSTANTS, BENCHMARK_TYPES } from './constants';
import { Actions } from '../actiontypes';
import { DropdownTypes } from '../../common/_custom/Dropdown';

export interface BenchmarkingState {
    isLoading: boolean;
    metricsToShow: string[];
    metricsData: MetricsData;
    selectedCriteria: DropdownTypes.DropdownOption;
    criteriaOptions: DropdownTypes.DropdownOption[];
}

export type MetricsData = {
    [x: string]: Metric;
};

export type Metric = {
    isLoading: boolean;
    isFetched: boolean;
    type: string;
    label: string;
    value: string;
    valueType: string;
    dataNotAvailable: boolean;
    invertedGraph?: boolean;
    median?: string;
    noOfCompanies?: string;
    percentile?: string;
    tooltipText: string;
    date?: string;
};

const inititalState: BenchmarkingState = {
    isLoading: false,
    selectedCriteria: {
        id: null,
        name: null,
    },
    criteriaOptions: [],
    metricsToShow: [
        BENCHMARK_TYPES.RECUR_SCORE,
        BENCHMARK_TYPES.RUNWAY,
        BENCHMARK_TYPES.ARR_CHURN,
        BENCHMARK_TYPES.ARR_GROWTH,
        BENCHMARK_TYPES.CUSTOMER_CONCENTRATION,
        BENCHMARK_TYPES.DEBT_EQUITY,
        BENCHMARK_TYPES.GROSS_MARGIN,
        BENCHMARK_TYPES.LTV_CAC,
        BENCHMARK_TYPES.RECEIVABLE_CYCLES,
        BENCHMARK_TYPES.RULE_OF_FORTY,
    ],
    metricsData: {
        [BENCHMARK_TYPES.RUNWAY]: {
            isLoading: true,
            isFetched: false,
            dataNotAvailable: false,
            type: BENCHMARK_TYPES.RUNWAY,
            label: 'Runway',
            value: '',
            valueType: ' months',
            tooltipText:
                'Runway refers to the number of months the company is expected to remain solvent at the current cash burn rate, in absence of any additional external financing.\nRunway = Closing cash balance / Average monthly burn (including operating, investing and financing cash outflows)',
        },
        [BENCHMARK_TYPES.RECEIVABLE_CYCLES]: {
            isLoading: true,
            isFetched: false,
            dataNotAvailable: false,
            type: BENCHMARK_TYPES.RECEIVABLE_CYCLES,
            label: 'Receivable Days',
            value: '',
            valueType: ' days',
            tooltipText:
                'Receivable Cycle or Receivable Days refers to the number of days an invoice is outstanding before it is collected.\nReceivable cycle = Trade receivables / Revenue * 365',
        },
        [BENCHMARK_TYPES.DEBT_EQUITY]: {
            isLoading: true,
            isFetched: false,
            dataNotAvailable: false,
            type: BENCHMARK_TYPES.DEBT_EQUITY,
            label: 'Debt Equity',
            value: '',
            valueType: '%',
            tooltipText:
                'The ratio of debt to equity shows the leverage used by a business to finance its operations or assets. Higher the leverage, higher is the risk posed upon the shareholders.\nDebt / Equity = Total borrowings / Shareholders’ funds',
        },
        [BENCHMARK_TYPES.ARR_CHURN]: {
            isLoading: true,
            isFetched: false,
            dataNotAvailable: false,
            type: BENCHMARK_TYPES.ARR_CHURN,
            label: 'ARR Churn',
            value: '',
            valueType: '%',
            tooltipText:
                'ARR lost due to subscription or contract cancellations. Churn rate is a metric to track % of customers the company is not able to retain on a yearly basis.\nARR churn = Churned ARR / Opening Subscription ARR',
        },
        [BENCHMARK_TYPES.ARR_GROWTH]: {
            isLoading: true,
            isFetched: false,
            dataNotAvailable: false,
            type: BENCHMARK_TYPES.ARR_GROWTH,
            label: 'ARR Growth Rate',
            value: '',
            valueType: '%',
            tooltipText:
                'Growth in ARR over a period of one year.\nARR growth = (Closing ARR / Opening ARR) - 1',
        },
        [BENCHMARK_TYPES.CUSTOMER_CONCENTRATION]: {
            isLoading: true,
            isFetched: false,
            dataNotAvailable: false,
            type: BENCHMARK_TYPES.CUSTOMER_CONCENTRATION,
            label: 'Customer Concentration',
            value: '',
            valueType: '%',
            tooltipText:
                'Refers to how a business’s revenue is spread across its customer base. Customer concentration is calculated as the percentage of ARR contributed by top five customers',
        },
        [BENCHMARK_TYPES.GROSS_MARGIN]: {
            isLoading: true,
            isFetched: false,
            dataNotAvailable: false,
            type: BENCHMARK_TYPES.GROSS_MARGIN,
            label: 'Gross Margin',
            value: '',
            valueType: '%',
            tooltipText:
                'Gross margin compares the gross profit of a company with its revenue. Calculated as the difference between Revenue and Cost of goods/services sold, gross profit shows the earnings of a company after paying off its direct costs.\nGross Margin = (Revenue - Direct costs) / Revenue',
        },
        [BENCHMARK_TYPES.LTV_CAC]: {
            isLoading: true,
            isFetched: false,
            dataNotAvailable: false,
            type: BENCHMARK_TYPES.LTV_CAC,
            label: 'LTV/CAC',
            value: '',
            valueType: '',
            tooltipText:
                'Lifetime Value (LTV) refers to the monetary value (calculated as gross margin in absolute terms) which is expected to be derived from a new customer over its lifetime. Customer Acquisition Cost (CAC) refers to the cost of acquiring a new customer, including sales and marketing spends. The ratio of LTV to CAC gives a comparison between the value of a new customer over its lifetime relative to the cost of acquisition.\nLTV = Average Revenue Per Account (ARPA) * Gross Margin / Churn\nCAC = Sum of all Sales and Marketing spends / Number of customers added',
        },
        [BENCHMARK_TYPES.RECUR_SCORE]: {
            isLoading: true,
            isFetched: false,
            dataNotAvailable: false,
            type: BENCHMARK_TYPES.RECUR_SCORE,
            label: 'Recur Score',
            value: '',
            valueType: '/100',
            tooltipText:
                "Recur Score is a proprietary scoring engine developed based on the company's recurring revenue metrics, financial metrics, management & governance risk and historical performance on Recur platform.",
        },
        [BENCHMARK_TYPES.RULE_OF_FORTY]: {
            isLoading: true,
            isFetched: false,
            dataNotAvailable: false,
            type: BENCHMARK_TYPES.RULE_OF_FORTY,
            label: 'Rule of Forty',
            value: '',
            valueType: '%',
            tooltipText:
                'Rule of 40 is a principle which states that a business’s net margin and ARR growth when added, should exceed 40%. It reflects the financial health and sustainability of the business and is an indicator to quickly assess the health of a business because it balances growth versus burn.\nRule of 40 = Net margin (+) ARR growth',
        },
    },
};

const setIsLoading = (state, action) => {
    return {
        ...state,
        isLoading: action.payload,
    };
};

const setIsLoadingMetric = (state, action) => {
    return {
        ...state,
        metricsData: {
            ...state.metricsData,
            [action.metricType]: {
                ...state.metricsData[action.metricType],
                isLoading: action.payload,
            },
        },
    };
};

const fetchCriteriaOptionsSuccess = (state, action) => {
    const options = action.payload;
    const criteriaOptions = Object.keys(options).map((option, index) => ({
        id: index + 1,
        name: `${options[option]?.name?.replaceAll('_', ' ')}: ${options[option]?.value}`,
        value: options[option]?.name,
    }));
    return {
        ...state,
        criteriaOptions: criteriaOptions,
        selectedCriteria: criteriaOptions[0],
    };
};

const fetchMetricSuccess = (state, action) => {
    const valueNotAvailable = isNaN(parseInt(action.payload?.value));
    const percentileNotAvailable = isNaN(parseInt(action.payload?.percentile));
    const percentile = percentileNotAvailable ? '' : `${parseInt(action.payload?.percentile)}`;
    let value;
    let median;

    switch (action.metricType) {
        case BENCHMARK_TYPES.GROSS_MARGIN:
        case BENCHMARK_TYPES.RULE_OF_FORTY:
        case BENCHMARK_TYPES.DEBT_EQUITY:
        case BENCHMARK_TYPES.ARR_GROWTH:
        case BENCHMARK_TYPES.ARR_CHURN:
        case BENCHMARK_TYPES.CUSTOMER_CONCENTRATION:
        case BENCHMARK_TYPES.LTV_CAC: {
            value = valueNotAvailable ? '-' : `${parseFloat(action.payload?.value).toFixed(2)}`;
            median = isNaN(parseInt(action.payload?.median))
                ? '-'
                : `${parseFloat(action.payload?.median).toFixed(2)}`;
            break;
        }
        default: {
            value = valueNotAvailable ? '-' : `${parseInt(action.payload?.value)}`;
            median = isNaN(parseInt(action.payload?.median))
                ? '-'
                : `${parseInt(action.payload?.median)}`;
        }
    }

    return {
        ...state,
        metricsData: {
            ...state.metricsData,
            [action.metricType]: {
                ...state.metricsData[action.metricType],
                ...action.payload,
                isLoading: false,
                isFetched: true,
                dataNotAvailable: !!(valueNotAvailable || percentileNotAvailable),
                value: value,
                percentile: percentile,
                median: median,

                ...(action.metricType === BENCHMARK_TYPES.RUNWAY && {
                    valueType: parseInt(value) > 1 ? ' months' : ' month',
                }),
            },
        },
    };
};

const fetchMetricError = (state, action) => {
    return {
        ...state,
        metricsData: {
            ...state.metricsData,
            [action.metricType]: {
                ...state.metricsData[action.metricType],
                isFetched: true,
            },
        },
    };
};

const setSelectedCriteria = (state, action) => {
    let metricsData = JSON.parse(JSON.stringify(state.metricsData));
    state.metricsToShow.map((metric) => {
        metricsData[metric].isFetched = false;
        return metric;
    });
    return {
        ...state,
        selectedCriteria: action.payload,
        metricsData: metricsData,
    };
};

const reducer = (
    state: BenchmarkingState = inititalState,
    action: Actions.AllActionTypes,
): BenchmarkingState => {
    switch (action.type) {
        case CONSTANTS.SET_IS_LOADING:
            return setIsLoading(state, action);
        case CONSTANTS.SET_IS_LOADING_METRIC:
            return setIsLoadingMetric(state, action);
        case CONSTANTS.FETCH_METRIC_DATA_SUCCESS:
            return fetchMetricSuccess(state, action);
        case CONSTANTS.FETCH_CRITERIA_OPTIONS_SUCCESS:
            return fetchCriteriaOptionsSuccess(state, action);
        case CONSTANTS.FETCH_METRIC_DATA_ERROR:
            return fetchMetricError(state, action);
        case CONSTANTS.SET_SELECTED_CRITERIA:
            return setSelectedCriteria(state, action);
        default:
            return state;
    }
};

export default reducer;
