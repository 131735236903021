import axios from 'axios';
import { downloadCsvFromResponse } from '../../utils/utils';
import { API_ENDPOINTS } from '../apiEndpoints';
import { FILE_DOWNLOAD_NAMES } from './constants';

export function getPnlData(payload) {
    return axios({
        url: API_ENDPOINTS.FINANCIALS.PNL.url,
        method: API_ENDPOINTS.FINANCIALS.PNL.method,
        params: API_ENDPOINTS.FINANCIALS.PNL.params(payload),
    })
        .then((response) => response)
        .catch((err) => err.response);
}

export function getBalanceSheet(payload) {
    return axios({
        url: API_ENDPOINTS.FINANCIALS.BALANCE_SHEET.url,
        method: API_ENDPOINTS.FINANCIALS.BALANCE_SHEET.method,
        params: API_ENDPOINTS.FINANCIALS.BALANCE_SHEET.params(payload),
    })
        .then((response) => response)
        .catch((err) => err.response);
}

export function getCashFlowStatement(payload) {
    return axios({
        url: API_ENDPOINTS.FINANCIALS.CASHFLOW_STATEMENT.url,
        method: API_ENDPOINTS.FINANCIALS.CASHFLOW_STATEMENT.method,
        params: API_ENDPOINTS.FINANCIALS.CASHFLOW_STATEMENT.params(payload),
    })
        .then((response) => response)
        .catch((err) => err.response);
}

export function getBankStatement(payload) {
    return axios({
        url: API_ENDPOINTS.FINANCIALS.BANK_STATEMENT.url,
        method: API_ENDPOINTS.FINANCIALS.BANK_STATEMENT.method,
        params: API_ENDPOINTS.FINANCIALS.BANK_STATEMENT.params(payload),
    })
        .then((response) => response)
        .catch((err) => err.response);
}

export function getInvoicesData(payload) {
    return axios({
        url: API_ENDPOINTS.FINANCIALS.INVOICES.url,
        method: API_ENDPOINTS.FINANCIALS.INVOICES.method,
        params: API_ENDPOINTS.FINANCIALS.INVOICES.params(payload),
    })
        .then((response) => response)
        .catch((err) => err.response);
}

export function exportFinancialsAsCsv(payload) {
    return axios({
        url: API_ENDPOINTS.FINANCIALS.EXPORT_CSV.url(payload.tableName),
        method: API_ENDPOINTS.FINANCIALS.EXPORT_CSV.method,
        params: API_ENDPOINTS.FINANCIALS.EXPORT_CSV.params(payload),
        responseType: 'blob',
    })
        .then((response) => {
            const filename = `${FILE_DOWNLOAD_NAMES[payload.tableName]}_${payload.tenure}`;
            downloadCsvFromResponse(response.data, filename);
            return response;
        })
        .catch((err) => err.response);
}

export function fetchPageSourceData(payload) {
    return axios({
        url: API_ENDPOINTS.FINANCIALS.PAGE_SOURCE.url(payload),
        method: API_ENDPOINTS.FINANCIALS.PAGE_SOURCE.method,
    })
        .then((response) => response)
        .catch((error) => error.response);
}
