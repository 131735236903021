import { all } from 'redux-saga/effects';
import loginSagaWatcher from './Login/saga';
import userSagaWatcher from './User/saga';
import dashboardSagaWatcher from './Dashboard/saga';
import dashboardSagaWatcherNew from './DashboardNew/saga';
import financialsSagaWatcher from './Financials/saga';
import benchmarkingSagaWatcher from './Benchmarking/saga';
import classificationsSagaWatcher from './RawData/saga';
import dataVaultSagaWatcher from './DataVault/saga';
import forecastsSagsWatcher from './Forecast/saga';

export default function* rootSaga() {
    yield all([
        loginSagaWatcher(),
        userSagaWatcher(),
        dashboardSagaWatcher(),
        dashboardSagaWatcherNew(),
        financialsSagaWatcher(),
        benchmarkingSagaWatcher(),
        classificationsSagaWatcher(),
        dataVaultSagaWatcher(),
        forecastsSagsWatcher(),
    ]);
}
