import { CONSTANTS } from './constants';
import { Actions } from '../actiontypes';
import {
    transformBalanceSheetDataRows,
    transformedInvoiceDataRows,
    transformedCashflowsData,
    transformedPnlData,
    transformedBankStatementData,
    transformHeaders,
} from './transformer';
import { DropdownOption } from '../../common/_custom/Dropdown/dropdownTypes';

//@TODO: remove all any types
//@TODO: remove individual data loader, keep single loader
interface FinancialsState {
    isLoading: boolean;
    pnlData: {
        table: TableData;
        tables: TableData[];
        isFetched: boolean;
        bankAccountsDropdownOptions?: DropdownOption[];
        selectedBankAccount?: DropdownOption;
    };
    balanceSheetData: {
        table: TableData;
        tables: TableData[];
        isFetched: boolean;
        bankAccountsDropdownOptions?: DropdownOption[];
        selectedBankAccount?: DropdownOption;
    };
    cashflowData: {
        table: TableData;
        tables: TableData[];
        isFetched: boolean;
        bankAccountsDropdownOptions?: DropdownOption[];
        selectedBankAccount?: DropdownOption;
    };
    bankStatementsData: {
        table: TableData;
        tables: TableData[];
        isFetched: boolean;
        bankAccountsDropdownOptions?: DropdownOption[];
        selectedBankAccount?: DropdownOption;
    };
    invoicingData: {
        table: TableData;
        tables: TableData[];
        isFetched: boolean;
        bankAccountsDropdownOptions?: DropdownOption[];
        selectedBankAccount?: DropdownOption;
    };
    invoicingSource?: string;
}

interface TableData {
    headers: {
        name: string;
    }[];
    rows: {
        classification: string;
        type: string;
        color: string;
        bg: string;
        amounts: {
            period: string;
            amount: number;
        }[];
        amountsMap: {
            [period: string]: {
                period: string;
                amount: number;
            };
        };
    }[];
    bankName?: string;
    accountNumber?: string;
}

const inititalState: FinancialsState = {
    isLoading: false,
    pnlData: {
        table: {
            headers: [],
            rows: [],
        },
        tables: [],
        isFetched: false,
    },
    balanceSheetData: {
        table: {
            headers: [],
            rows: [],
        },
        tables: [],
        isFetched: false,
    },
    cashflowData: {
        table: {
            headers: [],
            rows: [],
        },
        tables: [],
        isFetched: false,
    },
    bankStatementsData: {
        table: {
            rows: [],
            headers: [],
        },
        tables: [],
        isFetched: false,
        bankAccountsDropdownOptions: [],
        selectedBankAccount: {
            id: null,
            name: null,
        },
    },
    invoicingData: {
        table: {
            rows: [],
            headers: [],
        },
        tables: [],
        isFetched: false,
    },
};

const setIsLoading = (state, action) => {
    return {
        ...state,
        isLoading: action.payload,
    };
};

const setSelectedBankAccount = (state, action) => {
    const selectedOption = action.payload;
    const selectedTable =
        state.bankStatementsData.bankStatementsDataEnum[selectedOption.id] ?? null;
    const transformedRows = selectedTable ? transformedBankStatementData(selectedTable) : [];
    const transformedHeaders = selectedTable ? transformHeaders(selectedTable) : [];
    return {
        ...state,
        bankStatementsData: {
            ...state.bankStatementsData,
            selectedBankAccount: action.payload,
            table: {
                rows: transformedRows,
                headers: transformedHeaders,
            },
        },
    };
};

const fetchBankStatementsDataSuccess = (state, action) => {
    const summaryData = action.payload?.summaryData;
    const selectedBankAccount = state.bankStatementsData?.selectedBankAccount;
    const selectedBankAccountId = selectedBankAccount?.id;
    //Check if any bank is already selected in the bank accounts dropdown
    //If yes, the same bank account should be selected by default

    let bankAccountsDropdownOptions: DropdownOption[] = [];
    let bankStatementsDataEnum =
        summaryData && summaryData?.length
            ? summaryData?.reduce((map, tableData) => {
                  const bank = tableData?.bankName;
                  const account = tableData?.accountNumber;
                  map[`${bank}${account}`] = {
                      ...tableData,
                  };
                  bankAccountsDropdownOptions.push({
                      id: `${bank}${account}`,
                      name: `${bank?.toUpperCase()} ${account ? 'XXXX' : ''}${
                          account ? account.slice(-4) : ''
                      }`,
                  });
                  return map;
              }, {})
            : null;

    const tableData = selectedBankAccountId
        ? bankStatementsDataEnum[selectedBankAccountId]
        : action.payload?.summaryData[0];
    const transformedRows = tableData ? transformedBankStatementData(tableData) : [];
    const transformedHeaders = tableData ? transformHeaders(tableData) : [];

    return {
        ...state,
        isLoading: false,
        bankStatementsData: {
            ...action.payload,
            table: {
                rows: transformedRows,
                headers: transformedHeaders,
            },
            bankStatementsDataEnum,
            bankAccountsDropdownOptions,
            selectedBankAccount: selectedBankAccountId
                ? selectedBankAccount
                : bankAccountsDropdownOptions?.length
                ? bankAccountsDropdownOptions[0]
                : {},
            isFetched: true,
        },
    };
};

const fetchPnlDataSuccess = (state, action) => {
    const tableData = action.payload.data;
    const transformedRows = transformedPnlData(tableData);
    const transformedHeaders = transformHeaders(tableData);
    return {
        ...state,
        isLoading: false,
        pnlData: {
            table: {
                rows: transformedRows,
                headers: transformedHeaders,
            },
            isFetched: true,
        },
    };
};

const fetchBalanceSheetDatasuccess = (state, action) => {
    const tableData = action.payload.data;
    const transformedRows = transformBalanceSheetDataRows(tableData);
    const transformedHeaders = transformHeaders(tableData);
    return {
        ...state,
        isLoading: false,
        balanceSheetData: {
            table: {
                rows: transformedRows,
                headers: transformedHeaders,
            },
            isFetched: true,
        },
    };
};

const fetchCashflowDataSuccess = (state, action) => {
    const tableData = action.payload.data;
    const transformedRows = transformedCashflowsData(tableData);
    const transformedHeaders = transformHeaders(tableData);
    return {
        ...state,
        isLoading: false,
        cashflowData: {
            table: {
                rows: transformedRows,
                headers: transformedHeaders,
            },
            isFetched: true,
        },
    };
};

const fetchInvoicesDataSuccess = (state, action) => {
    const tableData = action.payload.data;
    const transformedRows = transformedInvoiceDataRows(tableData);
    const transformedHeaders = transformHeaders(tableData);
    return {
        ...state,
        isLoading: false,
        invoicingData: {
            table: {
                rows: transformedRows,
                headers: transformedHeaders,
            },
            isFetched: true,
        },
    };
};

const fetchPageSourceData = (state, action) => {
    const invoicingSource = action.payload.invoicingSource;
    return {
        ...state,
        invoicingSource: invoicingSource,
    };
};

const reducer = (
    state: FinancialsState = inititalState,
    action: Actions.AllActionTypes,
): FinancialsState => {
    switch (action.type) {
        case CONSTANTS.SET_IS_LOADING:
            return setIsLoading(state, action);

        case CONSTANTS.FETCH_PNL_DATA_SUCCESS:
            return fetchPnlDataSuccess(state, action);
        case CONSTANTS.FETCH_BALANCE_SHEET_SUCCESS:
            return fetchBalanceSheetDatasuccess(state, action);
        case CONSTANTS.FETCH_CASHFLOW_STATEMENT_SUCCESS:
            return fetchCashflowDataSuccess(state, action);

        case CONSTANTS.SET_SELECTED_BANK_ACCOUNT:
            return setSelectedBankAccount(state, action);
        case CONSTANTS.FETCH_BANK_STATEMENT_SUCCESS:
            return fetchBankStatementsDataSuccess(state, action);
        case CONSTANTS.FETCH_INVOICES_DATA_SUCCESS:
            return fetchInvoicesDataSuccess(state, action);
        case CONSTANTS.FETCH_PAGE_SOURCE_DATA_SUCCESS:
            return fetchPageSourceData(state, action);
        default:
            return state;
    }
};

export default reducer;
