import * as Effects from 'redux-saga/effects';
import * as apiService from './service';
import { CONSTANTS } from './constants';
import { Actions } from '../actiontypes';
import { toast } from 'react-toastify';
const call: any = Effects.call;
const takeLatest: any = Effects.takeLatest;

function* getGraphData(action: any) {
    const response = yield call(apiService.getGraphData, action.payload);
    action.onSuccess(response.data);
    switch (response && response.status) {
        case 200:
            toast.success('Successfully updated forecasts data');
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
            break;
        default:
            break;
    }
}

function* getForecastsData(action: Actions.Forecasts.FetchForecastsDataAction) {
    const response = yield call(apiService.fetchForecastsData, action.payload);

    action?.responseHandlers?.onSuccess(response.data?.data);
    switch (response && response.status) {
        case 200:
            break;
        default:
            break;
    }
}

function* unlockForecasts(action: Actions.Forecasts.UnlockForecastsAction) {
    const response = yield call(apiService.updateForecastStatus, action.payload);
    switch (response && response.status) {
        case 200:
            action.onSuccess();
            break;
        default:
            toast.error(response.data?.responseData?.responseMessage ?? 'Some error occured!');
            break;
    }
    action.doFinally();
}

export default function* actionWatcher() {
    yield takeLatest(CONSTANTS.GET_GRAPH_DATA, getGraphData);
    yield takeLatest(CONSTANTS.GET_FORECASTS_DATA, getForecastsData);
    yield takeLatest(CONSTANTS.UNLOCK_FORECASTS, unlockForecasts);
}
