import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { combineReducers } from 'redux';
import rootSaga from './sagas';
import periodSelectorReducer from './PeriodSelector/reducer';
import loginReducer from './Login/reducer';
import userReducer from './User/reducer';
import dashboardReducer from './Dashboard/reducer';
import dashboardReducerNew from './DashboardNew/reducer';
import financialsReducer from './Financials/reducer';
import benchmarkingReducer from './Benchmarking/reducer';
import rawDataReducer from './RawData/reducer';
import dataVaultReducer from './DataVault/reducer';

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
    login: loginReducer,
    user: userReducer,
    periodSelector: periodSelectorReducer,
    dashboard: dashboardReducer,
    dashboardNew: dashboardReducerNew,
    financials: financialsReducer,
    benchmarking: benchmarkingReducer,
    rawData: rawDataReducer,
    dataVault: dataVaultReducer,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (defaultMiddleware) =>
        defaultMiddleware({
            serializableCheck: false,
        }).concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
