import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { GET, POST } from '../utils/axios-interceptor';
import { GRAPH_NAMES } from './Dashboard/constants';
import { TABLES } from './Financials/constants';

dayjs.extend(customParseFormat);

export const API_ENDPOINTS = {
    DASHBOARD: {
        GET_INFO: {
            url: '/insights/dashboard/info',
            method: GET,
            params: {
                limit: 10,
                offset: 0,
            },
        },
        GET_METADATA: {
            url: '/insights/dashboard/metaData',
            method: POST,
            params: {},
        },
        GRAPHS: {
            [GRAPH_NAMES.ARR]: {
                url: '/insights/dashboard/arr',
                method: POST,
                params: {},
            },
            [GRAPH_NAMES.ARR_BRIDGE]: {
                url: '/insights/dashboard/arrBridge',
                method: POST,
                params: {},
            },
            // [GRAPH_NAMES.REVENUE]: {
            //     url: '/insights/dashboard/revenue',
            //     method: POST,
            //     params: {},
            // },
            [GRAPH_NAMES.CASH_BALANCE]: {
                url: '/insights/dashboard/cashBalance',
                method: POST,
                params: {},
            },
            [GRAPH_NAMES.CASH_MOVEMENT]: {
                url: '/insights/dashboard/cashMovement',
                method: POST,
                params: {},
            },
            [GRAPH_NAMES.CASH_FLOW_MOVEMENT]: {
                url: '/insights/dashboard/cashFlowMovement',
                method: POST,
                params: {},
            },
            [GRAPH_NAMES.CASH_FLOW_SNAPSHOT]: {
                url: '/insights/dashboard/cashFlowSnapshot',
                method: POST,
                params: {},
            },
            [GRAPH_NAMES.TOTAL_COST]: {
                url: '/insights/dashboard/totalCosts',
                method: POST,
                params: {},
            },
            [GRAPH_NAMES.PNL_SNPASHOT]: {
                url: '/insights/dashboard/pnlSnapshot',
                method: POST,
                params: {},
            },
            [GRAPH_NAMES.EBITDA]: {
                url: '/insights/dashboard/ebitda',
                method: POST,
                params: {},
            },
            [GRAPH_NAMES.GROSS_MARGIN]: {
                url: '/insights/dashboard/grossMargin',
                method: POST,
                params: {},
            },
            [GRAPH_NAMES.CLOSING_LOGOS]: {
                url: '/insights/dashboard/closingLogos',
                method: POST,
                params: {},
            },
            [GRAPH_NAMES.CUSTOMER_BRIDGE]: {
                url: '/insights/dashboard/customerBridge',
                method: POST,
                params: {},
            },
            [GRAPH_NAMES.ARPA]: {
                url: '/insights/dashboard/arpa',
                method: POST,
                params: {},
            },
            [GRAPH_NAMES.WORKING_CAPITAL_CYCLE]: {
                url: '/insights/dashboard/workingCapital',
                method: POST,
                params: {},
            },
        },
    },
    DASHBOARD_NEW: {
        GET_CONFIG: {
            url: `/config/insights/dahboard`,
            method: GET,
            params: {
                requestId: '1234rd1',
            },
        },
        // cards changed to graphs
        GET_HEADLINE_DATA: {
            url: `/insights/tab/data`,
            method: GET,
            params: (payload) => ({
                requestId: 1323423,
                tabName: payload.tabName,
                startDate: payload.startDate,
                endDate: payload.endDate,
                tenure: payload.tenure,
            }),
        },
        GET_GRAPH_DATA: {
            url: `/insights/tab/data`,
            method: GET,
            params: (payload) => ({
                requestId: 1323423,
                // multiple graphs can be passed as comma separated string
                graphs: payload.graphNames?.join(','),
                tabName: payload.tabName,
                startDate: payload.startDate,
                endDate: payload.endDate,
                tenure: payload.tenure,
            }),
        },
    },
    USER: {
        GET_INFO: {
            url: '/api/insights/dashboard/info',
            method: POST,
            params: {},
        },
        UPDATE_ORG_DATA: {
            url: (payload) => `/api/investeeorganizations/${payload.investeeOrgId}/data-update`,
            method: POST,
            params: {},
        },
        GET_UNLOCKED_CHARTS_COUNT: {
            url: (payload) => `insights/dashboard/${payload.investeeOrgId}/noOfChartsLocked`,
            method: GET,
            params: {},
        },
    },
    FINANCIALS: {
        PNL: {
            url: '/mis/profit-and-loss/classified',
            method: GET,
            params: (payload) => ({
                investeeOrgId: payload.investeeOrgId,
                source: payload.source,
                tenure: payload.tenure,
                startDate: payload.startDate,
                endDate: payload.endDate,
                subClassificationId: payload.subClassificationId,
                classificationId: payload.classificationId,
                csv: payload.csv,
            }),
        },
        BALANCE_SHEET: {
            url: '/mis/balance-sheets/classified',
            method: GET,
            params: (payload) => ({
                investeeOrgId: payload.investeeOrgId,
                source: payload.source,
                tenure: payload.tenure,
                startDate: payload.startDate,
                endDate: payload.endDate,
                subClassificationId: payload.subClassificationId,
                classificationId: payload.classificationId,
                csv: payload.csv,
            }),
        },
        CASHFLOW_STATEMENT: {
            url: '/mis/cash-flows/classified',
            method: GET,
            params: (payload) => ({
                investeeOrgId: payload.investeeOrgId,
                source: payload.source,
                tenure: payload.tenure,
                startDate: payload.startDate,
                endDate: payload.endDate,
                subClassificationId: payload.subClassificationId,
                classificationId: payload.classificationId,
                csv: payload.csv,
            }),
        },
        INVOICES: {
            url: '/invoices/classified?',
            method: GET,
            params: (payload) => ({
                investeeOrgId: payload.investeeOrgId,
                source: payload.source,
                tenure: payload.tenure,
                startDate: payload.startDate,
                endDate: payload.endDate,
                subClassificationId: payload.subClassificationId,
                classificationId: payload.classificationId,
                csv: payload.csv,
                totalRow: payload.totalRow,
            }),
        },
        BANK_STATEMENT: {
            url: '/bank/bank_stmt/summary',
            method: GET,
            params: (payload) => ({
                investeeOrgId: payload.investeeOrgId,
                source: payload.source,
                tenure: payload.tenure,
                startDate: payload.startDate,
                endDate: payload.endDate,
                subClassificationId: payload.subClassificationId,
                classificationId: payload.classificationId,
                csv: payload.csv,
            }),
        },
        EXPORT_CSV: {
            url: (tableName: string) => {
                switch (tableName) {
                    case TABLES.PNL:
                        return API_ENDPOINTS.FINANCIALS.PNL.url;
                    case TABLES.BALANCE_SHEET:
                        return API_ENDPOINTS.FINANCIALS.BALANCE_SHEET.url;
                    case TABLES.CASHFLOW_STATEMENT:
                        return API_ENDPOINTS.FINANCIALS.CASHFLOW_STATEMENT.url;
                    case TABLES.BANK_STATEMENT:
                        return API_ENDPOINTS.FINANCIALS.BANK_STATEMENT.url;
                    case TABLES.INVOICING:
                        return API_ENDPOINTS.FINANCIALS.INVOICES.url;
                }
            },
            method: GET,
            params: (payload) => ({
                investeeOrgId: payload.investeeOrgId,
                source: payload.source,
                tenure: payload.tenure,
                startDate: payload.startDate,
                endDate: payload.endDate,
                subClassificationId: payload.subClassificationId,
                classificationId: payload.classificationId,
                csv: payload.csv,
            }),
        },
        PAGE_SOURCE: {
            url: (payload) => `/insights/financials/${payload.investeeOrgId}/pageSource`,
            method: GET,
            params: {},
        },
    },
    EDIT_CLASSIFICATIONS: {
        GET_CLASSIFICATIONS: {
            url: '/classifications',
            method: GET,
            params: (payload) => ({
                className: payload.className,
            }),
        },
        GET_RAW_DATA: {
            url: (className) => {
                return `/mis/${className}/raw`;
            },
            method: GET,
            params: (payload) => ({
                investeeOrgId: payload.investeeOrgId,
                startDate: payload.startDate,
                endDate: payload.endDate,
                tenure: payload.tenure,
                classificationId: payload.classificationId,
                source: 'Insights',
                csv: payload.csv,
                totalRow: payload.totalRow,
                subClassificationId: -1,
            }),
        },
        UPDATE_CLASSIFICATIONS: {
            url: (className) => {
                return `/mis/${className}/update-classifications`;
            },
            method: 'PUT',
            params: (payload) => ({
                investeeOrgId: payload.investeeOrgId,
                source: 'Insights',
            }),
        },
    },
    BENCHMARKING: {
        FETCH_METRIC_DATA: {
            url: (payload) => {
                return `benchmark/${payload.metricType}`;
            },
            method: GET,
            params: (payload) => ({
                investeeOrgId: payload.investeeOrgId,
                criterion: payload.criterion,
            }),
        },
        FETCH_CRITERIA_OPTIONS: {
            url: (payload) => `/benchmark/options?investeeOrgId=${payload.investeeOrgId}`,
            method: GET,
            params: {},
        },
    },
    DATA_VAULT: {
        GET_FINBOX_REDIRECTING_URL: {
            url: '/finbox/get-session-url',
            method: POST,
            params: {},
        },
        GET_MONTHLY_DATA: {
            url: (payload) =>
                `fetch-monthly-onboarding-status/${payload.investeeOrgId}?path=${dayjs().format(
                    'YYYY-MM',
                )}&isInvesteeOrgId=true`,
            method: GET,
            params: {},
        },
        GET_INTEGRATIONS: {
            url: (payload) => `data-vault/${payload.investeeOnboardedId}/investee-integration-info`,
            method: GET,
            params: {},
        },
        GET_MONTHLY_VIEW_DATA: {
            url: (payload) => `insights/data-vault/${payload.investeeOrgId}/monthly-view`,
            method: GET,
            params: {},
        },
    },
    FORECASTS: {
        GET_FORECASTS_DATA: {
            url: () => `/insights/forecast/fetchForecast`,
            method: GET,
            params: (payload) => ({
                investeeOrgId: payload.investeeOrgId,
            }),
        },
        GET_GRAPH_DATA: {
            url: () => `/insights/forecast/recurLimit`,
            method: POST,
            params: (payload) => ({
                investeeOrgId: payload.investeeOrgId,
            }),
        },
        UNLOCK_FORECASTS: {
            url: () => 'api/investeeorganization/updateForecastStatus',
            method: POST,
        },
    },
};
