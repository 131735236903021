import { ROUTES } from '../../RoutesConst';
import { convertArrayToMap, fillMissingData } from '../../utils/utils';
import { ROW_COLORS, TABLE_ROW_TYPES } from './constants';

interface graphDataType {
    rows: {
        classification: string;
        name?: string;
        classificationName?: string;
        mis_amounts?: {}[];
        invoice_amounts?: {}[];
        amounts: {}[];
        // // amountsMap: {
        // //   [key:string] : {
        // //     period?: string,
        // //     name?:string
        // //   }
        // }
        label?: string;
    }[];
    headers: {
        period?: string;
        key?: string;
        name?: string;
    }[];
}

export function transformedAmounts(amounts) {
    let transformedAmounts = amounts.map((amt) => ({
        ...amt,
        amount: parseFloat(amt.amount).toFixed(2),
    }));
    return transformedAmounts;
}

export function transformBalanceSheetDataRows(graphData) {
    if (!graphData.rows.length) return [];
    if (graphData.rows.length === 1 && graphData.rows[0].classificationName === 'Unclassified') {
        let rows = [
            {
                classification: 'Unclassified',
                ...graphData.rows[0],
                amounts: graphData.rows[0].mis_amounts,
                amountsMap: convertArrayToMap(graphData.rows[0].mis_amounts, 'period'),
                type: TABLE_ROW_TYPES.DATA,
                color: ROW_COLORS.TEXT.RED,
                bg: ROW_COLORS.BG.RED,
            },
        ];
        return rows;
    } else {
        let rows = [
            {
                classification: 'Unclassified',
                type: TABLE_ROW_TYPES.DATA,
                color: ROW_COLORS.TEXT.RED,
                bg: ROW_COLORS.BG.RED,
            },
            {
                classification: 'Equity & Liabilities',
                type: TABLE_ROW_TYPES.SECTION_HEADING,
                color: ROW_COLORS.TEXT.RED,
                bg: ROW_COLORS.BG.RED,
                textAlign: 'center',
            },
            {
                classification: 'Shareholders Funds',
                type: TABLE_ROW_TYPES.HEADING,
                color: ROW_COLORS.TEXT.ORANGE,
                bg: ROW_COLORS.BG.WHITE,
            },
        ];
        graphData.rows.map((row) => {
            switch (row.classificationName && row.classificationName.trim()) {
                case 'Unclassified': {
                    rows[0] = {
                        ...row,
                        ...rows[0],
                        amounts: row.mis_amounts,
                        amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                    };
                    break;
                }
                case 'Share Capital': {
                    rows[3] = {
                        ...row,
                        classification: row.classificationName,
                        amounts: row.mis_amounts,
                        amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                        type: TABLE_ROW_TYPES.DATA,
                        color: ROW_COLORS.TEXT.ORANGE,
                        bg: ROW_COLORS.BG.WHITE,
                        label: 'Sh',
                    };
                    break;
                }
                case 'Reserves and Surplus': {
                    rows[4] = {
                        ...row,
                        classification: row.classificationName,
                        amounts: row.mis_amounts,
                        amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                        type: TABLE_ROW_TYPES.DATA,
                        color: ROW_COLORS.TEXT.ORANGE,
                        bg: ROW_COLORS.BG.WHITE,
                        label: 'R',
                    };
                    break;
                }
                case 'Director / Shareholder Loans': {
                    rows[5] = {
                        ...row,
                        classification: row.classificationName,
                        amounts: row.mis_amounts,
                        amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                        type: TABLE_ROW_TYPES.DATA,
                        color: ROW_COLORS.TEXT.ORANGE,
                        bg: ROW_COLORS.BG.WHITE,
                        label: 'D',
                    };
                    break;
                }
                case 'Total Shareholder Funds': {
                    rows[6] = {
                        ...row,
                        classification: row.classificationName,
                        amounts: row.mis_amounts,
                        amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                        type: TABLE_ROW_TYPES.SUMMARY,
                        color: ROW_COLORS.TEXT.BLACK,
                        bg: ROW_COLORS.BG.GREY,
                        formula: 'Sh + R',
                    };
                    break;
                }
                case 'Secured Loans': {
                    rows[7] = {
                        ...row,
                        classification: row.classificationName,
                        amounts: row.mis_amounts,
                        amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                        type: TABLE_ROW_TYPES.DATA,
                        color: ROW_COLORS.TEXT.RED,
                        bg: ROW_COLORS.BG.WHITE,
                        label: 'Se',
                    };
                    break;
                }
                case 'Unsecured Loans': {
                    rows[8] = {
                        ...row,
                        classification: row.classificationName,
                        amounts: row.mis_amounts,
                        amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                        type: TABLE_ROW_TYPES.DATA,
                        color: ROW_COLORS.TEXT.RED,
                        bg: ROW_COLORS.BG.WHITE,
                        label: 'U',
                    };
                    break;
                }
                case 'Non Current Liabilities': {
                    rows[9] = {
                        ...row,
                        classification: row.classificationName,
                        amounts: row.mis_amounts,
                        amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                        type: TABLE_ROW_TYPES.DATA,
                        color: ROW_COLORS.TEXT.RED,
                        bg: ROW_COLORS.BG.WHITE,
                        label: 'N',
                    };
                    break;
                }
                case 'Total Non Current Liabilities': {
                    rows[10] = {
                        ...row,
                        classification: row.classificationName,
                        amounts: row.mis_amounts,
                        amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                        type: TABLE_ROW_TYPES.SUMMARY,
                        color: ROW_COLORS.TEXT.BLACK,
                        bg: ROW_COLORS.BG.GREY,
                        formula: 'Se + U + N',
                    };
                    break;
                }
                case 'Trade Payables': {
                    rows[11] = {
                        ...row,
                        classification: row.classificationName,
                        amounts: row.mis_amounts,
                        amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                        type: TABLE_ROW_TYPES.DATA,
                        color: ROW_COLORS.TEXT.BROWN,
                        bg: ROW_COLORS.BG.WHITE,
                        label: 'T',
                    };
                    break;
                }
                case 'Salary Payable': {
                    rows[12] = {
                        ...row,
                        classification: row.classificationName,
                        amounts: row.mis_amounts,
                        amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                        type: TABLE_ROW_TYPES.DATA,
                        color: ROW_COLORS.TEXT.BROWN,
                        bg: ROW_COLORS.BG.WHITE,
                        label: 'Sa',
                    };
                    break;
                }
                case 'Statutory Liabilities': {
                    rows[13] = {
                        ...row,
                        classification: row.classificationName,
                        amounts: row.mis_amounts,
                        amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                        type: TABLE_ROW_TYPES.DATA,
                        color: ROW_COLORS.TEXT.BROWN,
                        bg: ROW_COLORS.BG.WHITE,
                        label: 'St',
                    };
                    break;
                }
                case 'Other Liabilities': {
                    rows[14] = {
                        ...row,
                        classification: row.classificationName,
                        amounts: row.mis_amounts,
                        amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                        type: TABLE_ROW_TYPES.DATA,
                        color: ROW_COLORS.TEXT.BROWN,
                        bg: ROW_COLORS.BG.WHITE,
                        label: 'Ot',
                    };
                    break;
                }
                case 'Total Current Liabilities': {
                    rows[15] = {
                        ...row,
                        classification: row.classificationName,
                        amounts: row.mis_amounts,
                        amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                        type: TABLE_ROW_TYPES.SUMMARY,
                        color: ROW_COLORS.TEXT.BLACK,
                        bg: ROW_COLORS.BG.GREY,
                        formula: 'T + Sa + St + Ot',
                    };
                    break;
                }
                case 'Total Liabilities': {
                    rows[16] = {
                        ...row,
                        classification: row.classificationName,
                        amounts: row.mis_amounts,
                        amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                        type: TABLE_ROW_TYPES.SUMMARY,
                        color: ROW_COLORS.TEXT.BLACK,
                        bg: ROW_COLORS.BG.GREY,
                        noBorder: true,
                        formula: 'Se + U + N + T + Sa + St + Ot',
                    };
                    break;
                }
                case 'Total Equities Liabilities': {
                    rows[17] = {
                        ...row,
                        classification: row.classificationName,
                        amounts: row.mis_amounts,
                        amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                        type: TABLE_ROW_TYPES.SUMMARY,
                        color: ROW_COLORS.TEXT.BLACK,
                        bg: ROW_COLORS.BG.GREY,
                        noBorder: true,
                        formula: 'Se + U + N + T + Sa + St + Ot + S + R',
                    };
                    break;
                }
                case 'Tangible Assets': {
                    rows[18] = {
                        ...row,
                        classification: row.classificationName,
                        amounts: row.mis_amounts,
                        amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                        type: TABLE_ROW_TYPES.DATA,
                        color: ROW_COLORS.TEXT.GREEN,
                        bg: ROW_COLORS.BG.WHITE,
                        label: 'Ta',
                    };
                    break;
                }
                case 'Intangible Assets': {
                    rows[19] = {
                        ...row,
                        classification: row.classificationName,
                        amounts: row.mis_amounts,
                        amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                        type: TABLE_ROW_TYPES.DATA,
                        color: ROW_COLORS.TEXT.GREEN,
                        bg: ROW_COLORS.BG.WHITE,
                        label: 'In',
                    };
                    break;
                }
                case 'Investments': {
                    rows[20] = {
                        ...row,
                        classification: row.classificationName,
                        amounts: row.mis_amounts,
                        amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                        type: TABLE_ROW_TYPES.DATA,
                        color: ROW_COLORS.TEXT.GREEN,
                        bg: ROW_COLORS.BG.WHITE,
                        label: 'It',
                    };
                    break;
                }
                case 'Non Current Assets': {
                    rows[21] = {
                        ...row,
                        classification: row.classificationName,
                        amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                        amounts: row.mis_amounts,
                        type: TABLE_ROW_TYPES.DATA,
                        color: ROW_COLORS.TEXT.GREEN,
                        bg: ROW_COLORS.BG.WHITE,
                        label: 'Nc',
                    };
                    break;
                }
                case 'Total Non Current Assets': {
                    rows[22] = {
                        ...row,
                        classification: row.classificationName,
                        amounts: row.mis_amounts,
                        amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                        type: TABLE_ROW_TYPES.SUMMARY,
                        color: ROW_COLORS.TEXT.BLACK,
                        bg: ROW_COLORS.BG.GREY,
                        formula: 'Ta + In',
                    };
                    break;
                }
                case 'Inventories': {
                    rows[23] = {
                        ...row,
                        classification: row.classificationName,
                        amounts: row.mis_amounts,
                        amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                        type: TABLE_ROW_TYPES.DATA,
                        color: ROW_COLORS.TEXT.DARK_GREEN,
                        bg: ROW_COLORS.BG.WHITE,
                        label: 'Iv',
                    };
                    break;
                }
                case 'Trade Receivables': {
                    rows[24] = {
                        ...row,
                        classification: row.classificationName,
                        amounts: row.mis_amounts,
                        amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                        type: TABLE_ROW_TYPES.DATA,
                        color: ROW_COLORS.TEXT.DARK_GREEN,
                        bg: ROW_COLORS.BG.WHITE,
                        label: 'Tr',
                    };
                    break;
                }
                case 'Cash And Cash Equivalents': {
                    rows[25] = {
                        ...row,
                        classification: row.classificationName,
                        amounts: row.mis_amounts,
                        amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                        type: TABLE_ROW_TYPES.DATA,
                        color: ROW_COLORS.TEXT.DARK_GREEN,
                        bg: ROW_COLORS.BG.WHITE,
                        label: 'C',
                    };
                    break;
                }
                case 'Other Advances / Deposits': {
                    rows[26] = {
                        ...row,
                        classification: row.classificationName,
                        amounts: row.mis_amounts,
                        amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                        type: TABLE_ROW_TYPES.DATA,
                        color: ROW_COLORS.TEXT.DARK_GREEN,
                        bg: ROW_COLORS.BG.WHITE,
                        label: 'Od',
                    };
                    break;
                }
                case 'Balance with Statutory Authorities': {
                    rows[27] = {
                        ...row,
                        classification: row.classificationName,
                        amounts: row.mis_amounts,
                        amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                        type: TABLE_ROW_TYPES.DATA,
                        color: ROW_COLORS.TEXT.DARK_GREEN,
                        bg: ROW_COLORS.BG.WHITE,
                        label: 'B',
                    };
                    break;
                }
                case 'Other Assets': {
                    rows[28] = {
                        ...row,
                        classification: row.classificationName,
                        amounts: row.mis_amounts,
                        amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                        type: TABLE_ROW_TYPES.DATA,
                        color: ROW_COLORS.TEXT.DARK_GREEN,
                        bg: ROW_COLORS.BG.WHITE,
                        label: 'Oa',
                    };
                    break;
                }
                case 'Total Current Assets': {
                    rows[29] = {
                        ...row,
                        classification: row.classificationName,
                        amounts: row.mis_amounts,
                        amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                        type: TABLE_ROW_TYPES.SUMMARY,
                        color: ROW_COLORS.TEXT.BLACK,
                        bg: ROW_COLORS.BG.GREY,
                        formula: 'Tr + C + Od + B + Oa',
                        label: 'Tc',
                    };
                    break;
                }
                case 'Total Assets': {
                    rows[30] = {
                        ...row,
                        classification: row.classificationName,
                        amounts: row.mis_amounts,
                        amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                        type: TABLE_ROW_TYPES.SUMMARY,
                        color: ROW_COLORS.TEXT.BLACK,
                        bg: ROW_COLORS.BG.GREY,
                        label: 'D',
                        noBorder: true,
                        formula: 'Tc + Ta + In',
                    };
                    break;
                }
            }
            return row;
        });
        rows.splice(7, 0, {
            classification: 'Non-Current Liabilities',
            type: TABLE_ROW_TYPES.HEADING,
            color: ROW_COLORS.TEXT.RED,
            bg: ROW_COLORS.BG.WHITE,
        });
        rows.splice(12, 0, {
            classification: 'Current Liabilities',
            type: TABLE_ROW_TYPES.HEADING,
            color: ROW_COLORS.TEXT.BROWN,
            bg: ROW_COLORS.BG.WHITE,
        });
        rows.splice(
            20,
            0,
            {
                classification: 'Assets',
                type: TABLE_ROW_TYPES.SECTION_HEADING,
                color: ROW_COLORS.TEXT.GREEN,
                bg: ROW_COLORS.BG.GREEN,
            },
            {
                classification: 'Non-Current Assets',
                type: TABLE_ROW_TYPES.HEADING,
                color: ROW_COLORS.TEXT.GREEN,
                bg: ROW_COLORS.BG.WHITE,
            },
        );
        rows.splice(27, 0, {
            classification: 'Current Assets',
            type: TABLE_ROW_TYPES.HEADING,
            color: ROW_COLORS.TEXT.DARK_GREEN,
            bg: ROW_COLORS.BG.WHITE,
        });
        return rows;
    }
}

export function transformedInvoiceDataRows(graphData: graphDataType) {
    let rows = graphData.rows.map((row) => ({
        ...row,
        classification: row.name,
        type: row?.name === 'Total' ? TABLE_ROW_TYPES.SUMMARY : TABLE_ROW_TYPES.DATA,
        color: ROW_COLORS.TEXT.BLACK,
        bg: row?.name === 'Total' ? ROW_COLORS.BG.GREY : ROW_COLORS.BG.WHITE,
        amounts: row.invoice_amounts,
        amountsMap: convertArrayToMap(row.invoice_amounts, 'period'),
    }));
    return rows;
}

export function transformedCashflowsData(graphData: graphDataType) {
    let headersArray = [...graphData.headers];
    headersArray.splice(0, 1);

    let rows: {}[] = [];
    graphData.rows.map((row) => {
        let filledRows = fillMissingData(row.mis_amounts, headersArray);
        switch (row.classificationName && row.classificationName.trim()) {
            case 'Unclassified': {
                rows[0] = {
                    ...row,
                    classification: row.classificationName,
                    amounts: filledRows,
                    amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                    type: TABLE_ROW_TYPES.DATA,
                    color: ROW_COLORS.TEXT.RED,
                    bg: ROW_COLORS.BG.RED,
                };
                break;
            }
            case 'Operating': {
                rows[1] = {
                    ...row,
                    classification: row.classificationName,
                    amounts: filledRows,
                    amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                    type: TABLE_ROW_TYPES.DATA,
                    color: ROW_COLORS.TEXT.ORANGE,
                    bg: ROW_COLORS.BG.WHITE,
                    label: 'O',
                };
                break;
            }
            case 'Investing': {
                rows[2] = {
                    ...row,
                    classification: row.classificationName,
                    amounts: filledRows,
                    amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                    type: TABLE_ROW_TYPES.DATA,
                    color: ROW_COLORS.TEXT.ORANGE,
                    bg: ROW_COLORS.BG.WHITE,
                    label: 'I',
                };
                break;
            }
            case 'Financing Outflow': {
                rows[3] = {
                    ...row,
                    classification: row.classificationName,
                    amounts: filledRows,
                    amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                    type: TABLE_ROW_TYPES.DATA,
                    color: ROW_COLORS.TEXT.ORANGE,
                    bg: ROW_COLORS.BG.WHITE,
                    label: 'Fo',
                };
                break;
            }
            case 'Financing Inflow': {
                rows[4] = {
                    ...row,
                    classification: row.classificationName,
                    amounts: filledRows,
                    amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                    type: TABLE_ROW_TYPES.DATA,
                    color: ROW_COLORS.TEXT.ORANGE,
                    bg: ROW_COLORS.BG.WHITE,
                    label: 'Fi',
                };
                break;
            }
            case 'Net Cash Flow': {
                rows[5] = {
                    ...row,
                    classification: row.classificationName,
                    amounts: filledRows,
                    amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                    type: TABLE_ROW_TYPES.SUMMARY,
                    color: ROW_COLORS.TEXT.BLACK,
                    bg: ROW_COLORS.BG.GREY,
                    formula: 'O + I + Fi + Fo',
                };
                break;
            }
            case 'Opening Balance': {
                rows[6] = {
                    ...row,
                    classification: row.classificationName,
                    amounts: filledRows,
                    amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                    type: TABLE_ROW_TYPES.SUMMARY,
                    color: ROW_COLORS.TEXT.BLACK,
                    bg: ROW_COLORS.BG.GREY,
                    noBorder: true,
                };
                break;
            }
            case 'Closing Balance': {
                rows[7] = {
                    ...row,
                    classification: row.classificationName,
                    amounts: filledRows,
                    amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                    type: TABLE_ROW_TYPES.SUMMARY,
                    color: ROW_COLORS.TEXT.BLACK,
                    bg: ROW_COLORS.BG.GREY,
                    noBorder: true,
                };
                break;
            }
        }
        return row;
    });
    // rows.splice(
    //     rows.length,
    //     0,
    //     {
    //         classification: 'Net Cash Flow',
    //         type: TABLE_ROW_TYPES.SUMMARY,
    //         color: ROW_COLORS.TEXT.BLACK,
    //         bg: ROW_COLORS.BG.GREY,
    //     },
    //     {
    //         classification: 'Opening Balance',
    //         type: TABLE_ROW_TYPES.SUMMARY,
    //         color: ROW_COLORS.TEXT.BLACK,
    //         bg: ROW_COLORS.BG.GREY,
    //     },
    //     {
    //         classification: 'Closing Balance',
    //         type: TABLE_ROW_TYPES.SUMMARY,
    //         color: ROW_COLORS.TEXT.BLACK,
    //         bg: ROW_COLORS.BG.GREY,
    //     },
    // );
    return rows;
}

export function transformedBankStatementData(graphData: graphDataType) {
    let rows: {}[] = [];
    rows[0] = {
        classification: 'Credit Transaction',
        type: TABLE_ROW_TYPES.HEADING,
        color: ROW_COLORS.TEXT.GREEN,
        bg: ROW_COLORS.BG.WHITE,
    };
    graphData.rows.map((row) => {
        switch (row.classification.trim()) {
            case 'Total No. of Credit Transaction': {
                rows[1] = {
                    ...row,
                    amountsMap: convertArrayToMap(row.amounts, 'period'),
                    type: TABLE_ROW_TYPES.DATA,
                    color: ROW_COLORS.TEXT.GREEN,
                    bg: ROW_COLORS.BG.WHITE,
                };
                break;
            }
            case 'Total Amount of Credit Transaction': {
                rows[2] = {
                    ...row,
                    amountsMap: convertArrayToMap(row.amounts, 'period'),
                    type: TABLE_ROW_TYPES.DATA,
                    color: ROW_COLORS.TEXT.GREEN,
                    bg: ROW_COLORS.BG.WHITE,
                };
                break;
            }
            case 'Average Credit Transaction Size': {
                rows[3] = {
                    ...row,
                    amountsMap: convertArrayToMap(row.amounts, 'period'),
                    type: TABLE_ROW_TYPES.DATA,
                    color: ROW_COLORS.TEXT.GREEN,
                    bg: ROW_COLORS.BG.WHITE,
                };
                break;
            }
            case 'Total No. of Debit Transaction': {
                rows[4] = {
                    ...row,
                    amountsMap: convertArrayToMap(row.amounts, 'period'),
                    type: TABLE_ROW_TYPES.DATA,
                    color: ROW_COLORS.TEXT.ORANGE,
                    bg: ROW_COLORS.BG.WHITE,
                };
                break;
            }
            case 'Total Amount of Debit Transaction': {
                rows[5] = {
                    ...row,
                    amountsMap: convertArrayToMap(row.amounts, 'period'),
                    type: TABLE_ROW_TYPES.DATA,
                    color: ROW_COLORS.TEXT.ORANGE,
                    bg: ROW_COLORS.BG.WHITE,
                };
                break;
            }
            case 'Average Debit Transaction Size': {
                rows[6] = {
                    ...row,
                    amountsMap: convertArrayToMap(row.amounts, 'period'),
                    type: TABLE_ROW_TYPES.DATA,
                    color: ROW_COLORS.TEXT.ORANGE,
                    bg: ROW_COLORS.BG.WHITE,
                };
                break;
            }
            case 'Opening Balance': {
                rows[7] = {
                    ...row,
                    amountsMap: convertArrayToMap(row.amounts, 'period'),
                    type: TABLE_ROW_TYPES.SUMMARY,
                    color: ROW_COLORS.TEXT.BLACK,
                    bg: ROW_COLORS.BG.GREY,
                };
                break;
            }
            case 'Closing Balance': {
                rows[8] = {
                    ...row,
                    amountsMap: convertArrayToMap(row.amounts, 'period'),
                    type: TABLE_ROW_TYPES.SUMMARY,
                    color: ROW_COLORS.TEXT.BLACK,
                    bg: ROW_COLORS.BG.GREY,
                    noBorder: true,
                };
                break;
            }
            case 'Net Cashflow': {
                rows[9] = {
                    ...row,
                    amountsMap: convertArrayToMap(row.amounts, 'period'),
                    type: TABLE_ROW_TYPES.SUMMARY,
                    color: ROW_COLORS.TEXT.BLACK,
                    bg: ROW_COLORS.BG.GREY,
                    noBorder: true,
                };
                break;
            }
            case 'Average EOD Balance': {
                rows[10] = {
                    ...row,
                    amountsMap: convertArrayToMap(row.amounts, 'period'),
                    type: TABLE_ROW_TYPES.SUMMARY,
                    color: ROW_COLORS.TEXT.BLACK,
                    bg: ROW_COLORS.BG.GREY,
                    noBorder: true,
                };
                break;
            }
        }
        return row;
    });
    rows.splice(
        4,
        0,
        {
            classification: '',
            type: TABLE_ROW_TYPES.BLANK,
            color: ROW_COLORS.TEXT.GREEN,
            bg: ROW_COLORS.BG.WHITE,
        },
        {
            classification: 'Debit Transaction',
            type: TABLE_ROW_TYPES.HEADING,
            color: ROW_COLORS.TEXT.GREEN,
            bg: ROW_COLORS.BG.WHITE,
        },
    );
    rows.splice(9, 0, {
        classification: '',
        type: TABLE_ROW_TYPES.BLANK,
        color: ROW_COLORS.TEXT.GREEN,
        bg: ROW_COLORS.BG.GREY,
    });
    return rows;
}

export function transformedPnlData(graphData: graphDataType) {
    let rows: {}[] = [];
    graphData.rows.map((row) => {
        switch (row.classificationName?.trim()) {
            case 'Unclassified':
                rows[0] = {
                    ...row,
                    classification: row.classificationName,
                    amounts: row.mis_amounts,
                    amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                    type: TABLE_ROW_TYPES.DATA,
                    color: ROW_COLORS.TEXT.RED,
                    bg: ROW_COLORS.BG.RED,
                    count: row.mis_amounts?.length,
                };
                break;
            case 'Revenue':
                rows[1] = {
                    ...row,
                    classification: row.classificationName,
                    amounts: row.mis_amounts,
                    amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                    type: TABLE_ROW_TYPES.DATA,
                    color: ROW_COLORS.TEXT.GREEN,
                    bg: ROW_COLORS.BG.WHITE,
                    link: ROUTES.PRIVATE.FINANCIALS.PNL_SUBURLS.REVENUE,
                    label: 'R',
                };
                break;
            case 'Direct Costs':
                rows[2] = {
                    ...row,
                    classification: row.classificationName,
                    amounts: row.mis_amounts,
                    amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                    type: TABLE_ROW_TYPES.DATA,
                    color: ROW_COLORS.TEXT.YELLOW,
                    bg: ROW_COLORS.BG.WHITE,
                    link: ROUTES.PRIVATE.FINANCIALS.PNL_SUBURLS.DIRECT_COSTS,
                    label: 'D',
                };
                break;
            case 'Gross Margin':
                rows[3] = {
                    ...row,
                    classification: 'Gross Margin',
                    amounts: row.mis_amounts,
                    amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                    type: TABLE_ROW_TYPES.SUMMARY,
                    color: ROW_COLORS.TEXT.BLACK,
                    bg: ROW_COLORS.BG.GREY,
                    formula: 'R - D',
                };
                break;
            case 'CAC':
                rows[4] = {
                    ...row,
                    classification: row.classificationName,
                    amounts: row.mis_amounts,
                    amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                    type: TABLE_ROW_TYPES.DATA,
                    color: ROW_COLORS.TEXT.YELLOW,
                    bg: ROW_COLORS.BG.WHITE,
                    link: ROUTES.PRIVATE.FINANCIALS.PNL_SUBURLS.CAC,
                    label: 'C',
                };
                break;
            case 'G&A':
                rows[5] = {
                    ...row,
                    classification: row.classificationName,
                    amounts: row.mis_amounts,
                    amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                    type: TABLE_ROW_TYPES.DATA,
                    color: ROW_COLORS.TEXT.YELLOW,
                    bg: ROW_COLORS.BG.WHITE,
                    link: ROUTES.PRIVATE.FINANCIALS.PNL_SUBURLS.GNA,
                    label: 'G',
                };
                break;
            case 'Total Cost':
                rows[6] = {
                    ...row,
                    classification: 'Total Costs',
                    amounts: row.mis_amounts,
                    amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                    type: TABLE_ROW_TYPES.SUMMARY,
                    color: ROW_COLORS.TEXT.BLACK,
                    bg: ROW_COLORS.BG.GREY,
                    formula: 'D + C + G',
                };
                break;
            case 'Ebitda':
                rows[7] = {
                    ...row,
                    classification: row.classificationName?.toUpperCase(),
                    amounts: row.mis_amounts,
                    amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                    type: TABLE_ROW_TYPES.SUMMARY,
                    color: ROW_COLORS.TEXT.BLACK,
                    bg: ROW_COLORS.BG.GREY,
                    formula: 'R - D - C - G',
                };
                break;
            case 'Other Income':
                rows[8] = {
                    ...row,
                    classification: row.classificationName,
                    amounts: row.mis_amounts,
                    amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                    type: TABLE_ROW_TYPES.DATA,
                    color: ROW_COLORS.TEXT.BLUE,
                    bg: ROW_COLORS.BG.WHITE,
                    link: ROUTES.PRIVATE.FINANCIALS.PNL_SUBURLS.OTHER_INCOME,
                    label: 'I',
                };
                break;
            case 'Finance Costs':
                rows[9] = {
                    ...row,
                    classification: 'Finance Costs',
                    amounts: row.mis_amounts,
                    amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                    type: TABLE_ROW_TYPES.DATA,
                    color: ROW_COLORS.TEXT.PURPLE,
                    bg: ROW_COLORS.BG.WHITE,
                    link: ROUTES.PRIVATE.FINANCIALS.PNL_SUBURLS.FINANCE_COSTS,
                    label: 'F',
                };
                break;
            case 'Depreciation':
                rows[10] = {
                    ...row,
                    classification: row.classificationName,
                    amounts: row.mis_amounts,
                    amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                    type: TABLE_ROW_TYPES.DATA,
                    color: ROW_COLORS.TEXT.PURPLE,
                    bg: ROW_COLORS.BG.WHITE,
                    link: ROUTES.PRIVATE.FINANCIALS.PNL_SUBURLS.DEPRECIATION,
                    label: 'D',
                };
                break;
            case 'Tax': {
                rows[11] = {
                    ...row,
                    classification: row.classificationName,
                    amounts: row.mis_amounts,
                    amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                    type: TABLE_ROW_TYPES.DATA,
                    color: ROW_COLORS.TEXT.PURPLE,
                    bg: ROW_COLORS.BG.WHITE,
                    link: ROUTES.PRIVATE.FINANCIALS.PNL_SUBURLS.TAX,
                    label: 'T',
                };
                break;
            }
            case 'PAT':
                rows[12] = {
                    ...row,
                    classification: 'Profit After Tax',
                    amounts: row.mis_amounts,
                    amountsMap: convertArrayToMap(row.mis_amounts, 'period'),
                    type: TABLE_ROW_TYPES.SUMMARY,
                    color: ROW_COLORS.TEXT.BLACK,
                    bg: ROW_COLORS.BG.GREY,
                    formula: 'E + I - F - D - T',
                };
                break;
        }
        return row;
    });
    rows.splice(7, 0, {
        classification: '',
        type: TABLE_ROW_TYPES.BLANK,
        color: ROW_COLORS.TEXT.BLACK,
        bg: ROW_COLORS.BG.WHITE,
    });

    return rows;
}

export function transformHeaders(graphData: graphDataType) {
    if (graphData.headers && graphData.headers.length) {
        let headers = graphData.headers;
        for (let i = headers.length; i <= 12; i++) {
            headers = [...headers, { name: '-', key: 'period' }];
        }
        return headers;
    } else return [];
}
