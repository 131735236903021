import { CONSTANTS, DATA_UPLOAD_STEPS } from './constants';

interface DataVaultState {
    isLoading: boolean;
    finboxRedirectingUrl: string;
    finboxError: boolean;
    monthlyData: any;
    uploadData: {
        steps: Step[];
        stepsOrder: string[];
    };
}

interface Step {
    id: string;
    label: string;
    subSteps?: Step[];
}

const inititalState: DataVaultState = {
    isLoading: false,
    finboxRedirectingUrl: '',
    finboxError: false,
    monthlyData: null,

    uploadData: {
        steps: [
            { id: DATA_UPLOAD_STEPS.BANK_STATEMENT, label: 'Bank Statement', subSteps: [] },
            {
                id: DATA_UPLOAD_STEPS.GST_INVOICING,
                label: 'Gst And Invoicing',
                subSteps: [
                    { id: DATA_UPLOAD_STEPS.GST_INVOICING_STEPS.GST_DATA, label: 'GST Data' },
                    {
                        id: DATA_UPLOAD_STEPS.GST_INVOICING_STEPS.INVOICING_DATA,
                        label: 'Invoicing Data',
                    },
                ],
            },
            {
                id: DATA_UPLOAD_STEPS.FINANCIALS,
                label: 'Financials',
                subSteps: [
                    { id: DATA_UPLOAD_STEPS.FINANCIALS_STEPS.PNL, label: 'PNL' },
                    { id: DATA_UPLOAD_STEPS.FINANCIALS_STEPS.CASHFLOW, label: 'Cashflow' },
                    {
                        id: DATA_UPLOAD_STEPS.FINANCIALS_STEPS.BALANCE_SHEET,
                        label: 'Balance Sheet',
                    },
                ],
            },
            { id: DATA_UPLOAD_STEPS.ADDITIONAL_DATA, label: 'Additional Data', subSteps: [] },
        ],
        stepsOrder: [
            DATA_UPLOAD_STEPS.BANK_STATEMENT,
            DATA_UPLOAD_STEPS.GST_INVOICING_STEPS.GST_DATA,
            DATA_UPLOAD_STEPS.GST_INVOICING_STEPS.INVOICING_DATA,
            DATA_UPLOAD_STEPS.FINANCIALS_STEPS.PNL,
            DATA_UPLOAD_STEPS.FINANCIALS_STEPS.CASHFLOW,
            DATA_UPLOAD_STEPS.FINANCIALS_STEPS.BALANCE_SHEET,
            DATA_UPLOAD_STEPS.ADDITIONAL_DATA,
        ],
    },
};

const setIsLoading = (state, action) => {
    return {
        ...state,
        isLoading: action.payload,
    };
};

const setFinboxRedirectingUrl = (state, action) => {
    return {
        ...state,
        finboxRedirectingUrl: action.url,
    };
};

const setFinboxError = (state, action) => {
    return {
        ...state,
        finboxError: action.payload,
    };
};

const setMonthlyData = (state, action) => {
    return {
        ...state,
        monthlyData: action.monthlyData,
    };
};

const reducer = (state: DataVaultState = inititalState, action: any): DataVaultState => {
    switch (action.type) {
        case CONSTANTS.SET_IS_LOADING:
            return setIsLoading(state, action);

        case CONSTANTS.GET_FINBOX_REDIRECTING_URL_SUCCESS:
            return setFinboxRedirectingUrl(state, action);
        case CONSTANTS.GET_FINBOX_REDIRECTING_URL_FAILURE:
            return setFinboxError(state, action);
        case CONSTANTS.GET_MONTHLY_DATA_SUCCESS:
            return setMonthlyData(state, action);
        default:
            return state;
    }
};

export default reducer;
