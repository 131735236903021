import { GRAPH_NAMES } from './constants';

export const GRAPH_COLORS = {
    //REVENUE GRAPHS
    [GRAPH_NAMES.REVENUE]: {
        primary: '#000000',
        secondary: '',
        tertiary: '',
    },
    [GRAPH_NAMES.REVENUE_GROWTH]: {
        primary: '#B7ED9D',
        secondary: '#ED6E72',
        tertiary: '',
    },
    [GRAPH_NAMES.EXISTING_VS_NEW_CUSTOMER]: {
        primary: '#000000',
        secondary: '#B7ED9D',
        tertiary: '',
    },
    [GRAPH_NAMES.SPLIT_OF_EXISTING_CUSTOMERS]: {
        primary: '#000000',
        secondary: '#B7ED9D',
        tertiary: '',
    },
    [GRAPH_NAMES.DOMESTIC_REVENUE_ACROSS_STATES]: {
        primary: '#0057BC',
        secondary: '#2F8FFF',
        tertiary: '#6FD4FF',
        fourth: '#96BCFF',
        fifth: '#E9F1FF',
    },
    [GRAPH_NAMES.AVG_REVENUE_PER_CUSTOMER]: {
        primary: '#000000',
        secondary: '',
        tertiary: '',
    },
    [GRAPH_NAMES.CUSTOMER_CONCENTRATION]: {
        primary: '#DEEDFF',
        secondary: '#2F8FFF',
        tertiary: '',
    },
    [GRAPH_NAMES.TOP_FIVE_CUSTOMERS]: {
        primary: '#2F8FFF',
        secondary: '#000000',
        tertiary: '',
    },
    [GRAPH_NAMES.REVENUE_BRIDGE]: {
        primary: '#2F8FFF',
        secondary: '#6FD4FF',
        tertiary: '#B2D6FF',
        fourth: '#547397',
        fifth: '#2F8FFF',
    },
    [GRAPH_NAMES.REVENUES_BY_SEGMENT]: {
        primary: '#E5EAFC',
        secondary: '#0057BC',
        tertiary: '#2F8FFF',
        fourth: '#97E0FF',
    },
    [GRAPH_NAMES.REVENUE_BY_HSN_CATEGORY]: {
        primary: '#2F8FFF',
        secondary: '',
        tertiary: '',
    },
    [GRAPH_NAMES.CUSTOMER_COHORT]: {
        primary: '#2F8FFF',
        secondary: '',
        tertiary: '',
    },
    [GRAPH_NAMES.REVENUE_TYPE]: {
        primary: '#97E0FF',
        secondary: '#000000',
        tertiary: '#2F8FFF',
    },

    // VENODR EXPENSES GRAPHS
    [GRAPH_NAMES.VENDOR_EXPENSES_VS_REVENUE]: {
        primary: '#000000',
        secondary: '#E5C8EA',
        tertiary: '',
    },
    [GRAPH_NAMES.EXPENSES_CHANGE]: {
        primary: '#750080',
        secondary: '#B7ED9D',
        tertiary: '',
    },
    [GRAPH_NAMES.EXISTING_VS_NEW_EXPENSES]: {
        primary: '#000000',
        secondary: '#E5C8EA',
        tertiary: '',
    },
    [GRAPH_NAMES.SPLIT_OF_EXISTING_VENDORS]: {
        primary: '#EEE0F0',
        secondary: '#750080',
        tertiary: '',
    },
    [GRAPH_NAMES.DOMESTIC_PURCHASE_ACROSS_STATES]: {
        primary: '#750080',
        secondary: '#BA80BF',
        tertiary: '#D6B2D9',
        fourth: '#F7B9FF',
        fifth: '#F5EDF6',
    },
    [GRAPH_NAMES.AVG_EXPENSE_PER_VENDOR]: {
        primary: '#750080',
        secondary: '',
        tertiary: '',
    },
    [GRAPH_NAMES.VENDOR_EXPENSE_CONCENTRATION]: {
        primary: '#EEE0F0',
        secondary: '#750080',
        tertiary: '',
    },
    [GRAPH_NAMES.TOP_FIVE_VENDORS]: {
        primary: '#750080',
        secondary: '',
        tertiary: '',
    },
    [GRAPH_NAMES.TOP_FIVE_NON_COMPLIANT_VENDORS]: {
        primary: '#750080',
        secondary: '',
        tertiary: '',
    },
    //COMPLIANCES GRAPHS
    [GRAPH_NAMES.SALES_UNDER_REPORTING]: {
        primary: '#ED6E72',
        secondary: '#ED6E72',
        tertiary: '',
    },
    [GRAPH_NAMES.ITC_REPORTED_VS_CLAIMED]: {
        primary: '#97E0FF',
        secondary: '#2F8FFF',
        tertiary: '',
    },
    //BANKING AND FINANCIALS GRAPHS
    [GRAPH_NAMES.CASH_BALANCE]: {
        primary: '#2F8FFF',
        secondary: '',
        tertiary: '',
    },
    [GRAPH_NAMES.CASH_FLOW_MOVEMENT]: {
        primary: '#0057BC',
        secondary: '#2F8FFF',
        tertiary: '#6FD4FF',
    },
    [GRAPH_NAMES.TOTAL_COSTS]: {
        primary: '#0057BC',
        secondary: '#2F8FFF',
        tertiary: '#6FD4FF',
    },
    [GRAPH_NAMES.GROSS_MARGIN]: {
        primary: '#000000',
        secondary: '',
        tertiary: '',
    },
    [GRAPH_NAMES.EBITDA]: {
        primary: '#2F8FFF',
        secondary: '#6FD4FF',
        tertiary: '',
    },
    [GRAPH_NAMES.PNL_SNAPSHOT]: {
        primary: '#2F8FFF',
        secondary: '#547397',
        tertiary: '#547397',
        fourth: '#547397',
        fifth: '#2F8FFF',
    },
    [GRAPH_NAMES.WORKING_CAPITAL_CYCLE]: {
        primary: '#2F8FFF',
        secondary: '#6FD4FF',
        tertiary: '',
    },
    [GRAPH_NAMES.CASHFLOW_SNAPSHOT]: {
        primary: '#2F8FFF',
        secondary: '#6FD4FF',
        tertiary: '#547397',
        fourth: '#547397',
        fifth: '#6FD4FF',
        sixth: '#2F8FFF',
    },
};

export const defaultColors = {
    primary: '#000000',
    secondary: '#000000',
    tertiary: '#000000',
};
