/**
 * @Description
 * This file intended to intercept incoming request and response.
 * Main utility will to authorize the user else redirect to login page.
 * or we want the error to display api failure and sentry logs can be put here.
 */

import axios, { AxiosError, AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios';
import { NavigateFunction } from 'react-router-dom';
import { toast } from 'react-toastify';

export const API_STATUS = {
    SUCCESS: 'Success',
    ERROR: 'Error',
    MNEMOSYNE_SUCCESS: 'OK',
};
export const GET = 'GET';
export const POST = 'POST';

export const JAVA_API_SUCCESS_CODES = {
    SUCCESS: 20,
};

export interface CustomRequest extends AxiosRequestConfig {
    isAuthRequired?: boolean;
}

export interface CommonResponse {
    status: 'Success' | 'Failed';
    data: object;
}

let source = axios.CancelToken.source();
export const uwBaseUrl = process.env.REACT_APP_UNDERWRITING_ENGINE_URL;
export const coreBaseUrl = process.env.REACT_APP_API_URL;
export const polusBaseUrl = process.env.REACT_APP_POLUS_URL;
// export const uwBaseUrl = "http://localhost:8000/underwriting";  //underwriting_engine local
// export const coreBaseUrl = 'http://localhost:8070'; //coreapi local
export interface CustomRequest extends AxiosRequestConfig {
    isAuthRequired?: boolean;
}

export interface CommonResponse {
    status: 'Success' | 'Failed';
    data: object;
}

const onRequest = (config: CustomRequest, navigate: NavigateFunction): CustomRequest => {
    return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    // captureMessagesViaErrorLogger(error.message);
    return Promise.reject(error);
};

const onResponse = (response: AxiosResponse, navigate: NavigateFunction): AxiosResponse => {
    return response;
};

const onResponseError = (error: AxiosError, navigate: NavigateFunction): Promise<AxiosError> => {
    if (error.response?.status === 401) {
        source.cancel();
        toast.error('Unauthorized Access!, Redirecting');
        navigate('/');
        setTimeout(() => {
            window.location.href = 'https://app.recur.club';
        }, 1000);
    }
    // captureMessagesViaErrorLogger(error.message);
    return Promise.reject(error);
};

export function applyInterceptor(
    axiosInstance: AxiosInstance,
    navigate: NavigateFunction,
): AxiosInstance {
    axiosInstance.defaults.baseURL = uwBaseUrl;
    axiosInstance.defaults.cancelToken = source.token;
    axiosInstance.interceptors.request.use((req) => onRequest(req, navigate), onRequestError);
    axiosInstance.interceptors.response.use(
        (res) => onResponse(res, navigate),
        (err) => onResponseError(err, navigate),
    );
    axiosInstance.interceptors.request.use(
        function (config) {
            return config;
        },
        function (error) {
            return Promise.reject(error);
        },
    );
    return axiosInstance;
}

export function addTokenToHeaders(
    axiosInstance: AxiosInstance,
    investeeOrgId,
    cerberusToken,
    userId,
) {
    axiosInstance.defaults.headers['x-cerberus-token'] = cerberusToken;
    axiosInstance.defaults.headers['x-organization-id'] = investeeOrgId;
    axiosInstance.defaults.headers['x-user-id'] = userId;
}
