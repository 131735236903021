import { CONSTANTS } from './constants';
import { Actions } from '../actiontypes';
import { DropdownOption } from '../../common/_custom/Dropdown/dropdownTypes';
import { transformHeaders } from '../Financials/transformer';

export interface EditClassificationsState {
    classificationId: number;
    loadingClassifications: boolean;
    classifications: Classification[];
    classificationsMap: ClassificationsMap;
    loadingRawData: boolean;
    data: {
        headers: {
            name: string;
            key: string;
        }[];
        rows: {
            subLegend: string;
            legend: string;
            mis_amounts: {
                amount: string;
                period: string;
            }[];
            amountsMap: amountsMap;
            selectedOption: DropdownOption;
        }[];
    };
    initialClassificationData: {
        headers: {
            name: string;
            key: string;
        }[];
        rows: {
            subLegend: string;
            legend: string;
            mis_amounts: {
                amount: string;
                period: string;
            }[];
            amountsMap: amountsMap;
            selectedOption: DropdownOption;
        }[];
    };
    updatingClassifications: boolean;
}

interface ClassificationsMap {
    [num: number]: {
        id: number;
        name: string;
    };
}

interface amountsMap {
    [period: string]: string;
}

interface Classification {
    id: number;
    name: string;
    class: string;
}

const inititalState: EditClassificationsState = {
    classificationId: 0,
    loadingClassifications: false,
    loadingRawData: false,
    classifications: [],
    classificationsMap: {},
    data: {
        headers: [],
        rows: [],
    },
    initialClassificationData: {
        headers: [],
        rows: [],
    },
    updatingClassifications: false,
};

const setLoadingClassifications = (state, action) => {
    return {
        ...state,
        loadingClassifications: action.payload,
    };
};

const setLoadingRawData = (state, action) => {
    return {
        ...state,
        loadingRawData: action.payload,
    };
};

const setClassificationId = (state, action) => {
    return {
        ...state,
        classificationId: action.payload,
    };
};

const fetchClassificationsSuccess = (state, action) => {
    const classifications = action.payload.data;
    const classificationsMap = classifications.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});
    return {
        ...state,
        loadingClassifications: false,
        classifications: classifications,
        classificationsMap: classificationsMap,
    };
};

const fetchRawDataSuccess = (state, action) => {
    const data = action.payload.data;
    const transformedHeaders = transformHeaders(data);
    const transformedRows = data.rows.map((row) => {
        const amountsMap = row.mis_amounts.reduce(function (map, obj) {
            map[obj.period] = obj.amount;
            return map;
        }, {});
        return {
            ...row,
            amountsMap: amountsMap,
            selectedOption: state.classificationsMap[row.classification],
        };
    });
    return {
        ...state,
        loadingRawData: false,
        data: {
            headers: transformedHeaders,
            rows: transformedRows,
        },
        initialClassificationData: {
            headers: transformedHeaders,
            rows: transformedRows,
        },
    };
};

const SetRowClassification = (state, action) => {
    const updatedRows = JSON.parse(JSON.stringify(state.data.rows));
    updatedRows[action.payload.index].selectedOption = action.payload.selectedOption;
    return {
        ...state,
        data: {
            ...state.data,
            rows: updatedRows,
        },
    };
};

const setUpdatingClassifications = (state, action) => {
    return {
        ...state,
        updatingClassifications: action.payload,
    };
};

const updatingClassificationsSuccess = (state) => {
    return {
        ...state,
        updatingClassifications: false,
    };
};

const resetRawData = (state) => {
    return {
        ...state,
        data: {
            headers: [],
            rows: [],
        },
    };
};

const resetDropdowns = (state) => {
    return {
        ...state,
        data: {
            ...state.data,
            rows: [
                ...state.data.rows.map((row) => ({
                    ...row,
                    selectedOption: state.classificationsMap[row.classification],
                })),
            ],
        },
    };
};

const reducer = (
    state: EditClassificationsState = inititalState,
    action: Actions.AllActionTypes,
): EditClassificationsState => {
    switch (action.type) {
        case CONSTANTS.SET_LOADING_CLASSIFICATIONS:
            return setLoadingClassifications(state, action);
        case CONSTANTS.SET_LOADING_RAW_DATA:
            return setLoadingRawData(state, action);
        case CONSTANTS.SET_CLASSIFICATION_ID:
            return setClassificationId(state, action);
        case CONSTANTS.RESET_RAW_DATA:
            return resetRawData(state);
        case CONSTANTS.RESET_DROPDOWNS:
            return resetDropdowns(state);
        case CONSTANTS.FETCH_CLASSIFICATIONS_SUCCESS:
            return fetchClassificationsSuccess(state, action);
        case CONSTANTS.FETCH_RAW_DATA_SUCCESS:
            return fetchRawDataSuccess(state, action);
        case CONSTANTS.SET_ROW_CLASSIFICATION:
            return SetRowClassification(state, action);
        case CONSTANTS.SET_UPDATING_CLASSIFICATIONS:
            return setUpdatingClassifications(state, action);
        case CONSTANTS.UPDATE_CLASSIFICATIONS_SUCCESS:
            return updatingClassificationsSuccess(state);
        default:
            return state;
    }
};

export default reducer;
