import { TABLES } from '../Financials/constants';

const namespace = 'CLASSIFICATIONS_';

export const CONSTANTS = {
    SET_LOADING_CLASSIFICATIONS: `${namespace}SET_LOADING_CLASSIFICATIONS`,
    SET_LOADING_RAW_DATA: `${namespace}SET_LOADING_RAW_DATA`,
    SET_CLASSIFICATION_ID: `${namespace}SET_CLASSIFICATION_ID`,
    RESET_RAW_DATA: `${namespace}RESET_RAW_DATA`,
    RESET_DROPDOWNS: `${namespace}RESET_DROPDOWNS`,

    FETCH_CLASSIFICATIONS: `${namespace}FETCH_CLASSIFICATIONS`,
    FETCH_CLASSIFICATIONS_SUCCESS: `${namespace}FETCH_CLASSIFICATIONS_SUCCESS`,

    FETCH_RAW_DATA: `${namespace}FETCH_RAW_DATA`,
    FETCH_RAW_DATA_SUCCESS: `${namespace}FETCH_RAW_DATA_SUCCESS`,

    SET_ROW_CLASSIFICATION: `${namespace}SET_ROW_CLASSIFICATION`,
    SET_UPDATING_CLASSIFICATIONS: `${namespace}SET_UPDATING_CLASSIFICATIONS`,

    UPDATE_CLASSIFICATIONS: `${namespace}UPDATE_CLASSIFICATIONS`,
    UPDATE_CLASSIFICATIONS_SUCCESS: `${namespace}UPDATE_CLASSIFICATIONS_SUCCESS`,

    EXPORT_CSV: `${namespace}EXPORT_CSV`,
};

export const CLASS_NAMES = {
    [TABLES.PNL]: 'profit-and-loss',
    [TABLES.BALANCE_SHEET]: 'balance-sheets',
    [TABLES.CASHFLOW_STATEMENT]: 'cash-flows',
};

export const RAW_DATA_CLASSIFICATIONS = {
    PNL: 'profit-and-loss',
    BALANCE_SHEET: 'balance-sheets',
    CAHSFLOW_STATEMENT: 'cash-flows',
};

export const RAW_DATA_FILE_DOWNLOAD_NAMES = {
    [RAW_DATA_CLASSIFICATIONS.PNL]: 'Raw_PnL',
    [RAW_DATA_CLASSIFICATIONS.BALANCE_SHEET]: 'Raw_BalanceSheet',
    [RAW_DATA_CLASSIFICATIONS.CAHSFLOW_STATEMENT]: 'Raw_Cashflow',
};
