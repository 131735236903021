import axios from 'axios';
import { polusBaseUrl } from '../../utils/axios-interceptor';
import { API_ENDPOINTS } from '../apiEndpoints';

export function getDashboardConfig(payload) {
    return axios({
        baseURL: polusBaseUrl,
        url: API_ENDPOINTS.DASHBOARD_NEW.GET_CONFIG.url,
        method: API_ENDPOINTS.DASHBOARD_NEW.GET_CONFIG.method,
        params: API_ENDPOINTS.DASHBOARD_NEW.GET_CONFIG.params,
    })
        .then((response) => response)
        .catch((err) => err.response);
}

export function getHeadlineData(payload) {
    return axios({
        baseURL: polusBaseUrl,
        url: API_ENDPOINTS.DASHBOARD_NEW.GET_HEADLINE_DATA.url,
        method: API_ENDPOINTS.DASHBOARD_NEW.GET_HEADLINE_DATA.method,
        params: API_ENDPOINTS.DASHBOARD_NEW.GET_HEADLINE_DATA.params(payload),
    })
        .then((response) => response)
        .catch((err) => err.response);
}

export function getGraphData(payload) {
    return axios({
        baseURL: polusBaseUrl,
        url: API_ENDPOINTS.DASHBOARD_NEW.GET_GRAPH_DATA.url,
        method: API_ENDPOINTS.DASHBOARD_NEW.GET_GRAPH_DATA.method,
        params: API_ENDPOINTS.DASHBOARD_NEW.GET_GRAPH_DATA.params(payload),
    })
        .then((response) => response)
        .catch((err) => err.response);
}
