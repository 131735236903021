import * as Effects from 'redux-saga/effects';
import * as apiService from './service';
import { CONSTANTS } from './constants';
import { toast } from 'react-toastify';
import { isTabConfigValid } from './transformer';
import { takeEvery } from 'redux-saga/effects';
// import { takeEvery } from 'redux-saga/effects';
const call: any = Effects.call;
const put: any = Effects.put;
// const select: any = Effects.select;
const takeLatest: any = Effects.takeLatest;

function* fetchDashboardConfigData(action: any) {
    yield put({
        type: CONSTANTS.SET_IS_LOADING,
        payload: true,
    });
    const response = yield call(apiService.getDashboardConfig, action.payload);
    // const { mainConfig } = require('./config.json');
    const {
        data: { mainConfig },
    } = response;
    const { selectedTabId } = mainConfig;
    const selectedTabConfig = mainConfig?.tabConfigs?.[selectedTabId];
    const charts = selectedTabConfig?.chartPanel?.cards || [];
    switch (response && response.status) {
        case 200:
            yield put({
                type: CONSTANTS.GET_DASHBOARD_CONFIG_SUCCESS,
                payload: mainConfig,
            });
            if (charts.length)
                yield put({
                    type: CONSTANTS.INITIALIZE_GRAPH_CARDS,
                    payload: { charts: charts },
                });
            if (selectedTabId) action?.payload?.setDefaultTab(selectedTabId);
            break;
        case 400:
            yield put({
                type: CONSTANTS.SET_IS_LOADING,
                payload: false,
            });
            break;
        default:
            return toast.error('Something went wrong');
    }
}

function* fetchHeadlineData(action: any) {
    yield put({
        type: CONSTANTS.SET_IS_LOADING_HEADLINE,
        payload: true,
    });
    const response = yield call(apiService.getHeadlineData, action.payload);
    const {
        data: { tabData },
    } = response;
    switch (response && response.status) {
        case 200:
            yield put({
                type: CONSTANTS.GET_HEADLINE_DATA_SUCCESS,
                payload: tabData,
            });
            break;
        case 400:
            yield put({
                type: CONSTANTS.SET_IS_LOADING_HEADLINE,
                payload: false,
            });
            break;
        default:
            return toast.error('Something went wrong');
    }
}

function* fetchGraphData(action: any) {
    const graphName = action?.payload?.graphNames?.[0] || '--';

    yield put({
        type: CONSTANTS.SET_IS_LOADING_GRAPH,
        payload: true,
        graphName: graphName,
    });

    const response = yield call(apiService.getGraphData, action.payload);
    let graphs;
    graphs = response?.data?.graphs;
    const responseCode = response.data?.responseData?.responseCode;
    switch (responseCode) {
        case 20:
            yield put({
                type: CONSTANTS.GET_GRAPH_DATA_SUCCESS,
                payload: {
                    graphNames: Object.keys(graphs || {}),
                    data: graphs,
                },
            });
            break;
        case 40:
            yield put({
                type: CONSTANTS.GET_GRAPH_DATA_ERROR,
                payload: {
                    graphNames: [graphName],
                    data: graphs,
                },
            });
            yield put({
                type: CONSTANTS.SET_IS_LOADING_HEADLINE,
                payload: false,
            });
            break;
        default:
            return toast.error('Something went wrong');
    }
}

export default function* actionWatcher() {
    yield takeLatest(CONSTANTS.GET_DASHBOARD_CONFIG, fetchDashboardConfigData);
    yield takeLatest(CONSTANTS.GET_HEADLINE_DATA, fetchHeadlineData);
    yield takeEvery(CONSTANTS.GET_GRAPH_DATA, fetchGraphData);
}
