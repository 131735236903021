// -- REDUX CONSTANTS --//

const namespace = 'DATA_VAULT_';

export const CONSTANTS = {
    SET_IS_LOADING: `${namespace}SET_IS_LOADING`,
    GET_FINBOX_REDIRECTING_URL: `${namespace}GET_FINBOX_REDIRECTING_URL`,
    GET_FINBOX_REDIRECTING_URL_SUCCESS: `${namespace}GET_FINBOX_REDIRECTING_URL_SUCCESS`,
    GET_FINBOX_REDIRECTING_URL_FAILURE: `${namespace}GET_FINBOX_REDIRECTING_URL_FAILURE`,
    GET_MONTHLY_DATA: `${namespace}GET_MONTHLY_DATA`,
    GET_MONTHLY_DATA_SUCCESS: `${namespace}GET_MONTHLY_DATA_SUCCESS`,
    GET_SIGNED_RESPONSE: `${namespace}GET_SIGNED_RESPONSE`,
    GET_SIGNED_RESPONSE_STATUS: `${namespace}GET_SIGNED_RESPONSE_STATUS`,
    UPDATE_META_DATA: `${namespace}UPDATE_META_DATA`,
    UPDATE_META_DATA_STATUS: `${namespace}UPDATE_META_DATA_STATUS`,
};

export const DATA_UPLOAD_STEPS = {
    BANK_STATEMENT: 'BANK_STATEMENT',
    GST_INVOICING: 'GST_INVOICING',
    GST_INVOICING_STEPS: {
        GST_DATA: 'GST_DATA',
        INVOICING_DATA: 'INVOICING_DATA',
    },
    FINANCIALS: 'FINANCIALS',
    FINANCIALS_STEPS: {
        PNL: 'PNL',
        CASHFLOW: 'CASHFLOW',
        BALANCE_SHEET: 'BALANCE_SHEET',
    },
    ADDITIONAL_DATA: 'ADDITIONAL_DATA',
};

export const MONTHLY_VIEW_DATA_TYPES = {
    NOT_UPLOADED: 'Not Uploaded',
    UPLOADED: 'Uploaded',
    INGESTED: 'Ingested',
};
// ---- xx ---- //
