import { Tab } from '../../common/TabsNew/Tabs';
import { CONSTANTS } from './constants';
import { defaultColors, GRAPH_COLORS } from './graphColorData';
import { chartInitData } from './transformer';
import { graphDataType, StatCardData } from './types';

interface DashboardNew {
    isLoading: boolean;
    loadingHeadline: boolean;
    config: null | {
        tabsCount: number;
        tabConfigs: any;
        tabs: Tab[];
        selectedTabId: string;
    };
    headlineData: null | {
        cards: string[];
        data: { [x: string]: StatCardData };
    };
    charts: null | graphDataType[];
}

const inititalState: any = {
    isLoading: false,
    loadingHeadline: false,
    config: null,
    headlineData: null,
};

const setIsLoading = (state, action) => {
    return {
        ...state,
        isLoading: action.payload,
    };
};

const setIsLoadingHeadline = (state, action) => {
    return {
        ...state,
        loadingHeadline: action.payload,
    };
};

const setIsLoadingGraph = (state, action) => {
    const graphName = action.graphName;
    return {
        ...state,
        charts: {
            ...state.charts,
            [graphName]: {
                ...state?.charts?.[graphName],
                isLoading: true,
            },
        },
    };
};

const resetIsFetched = (state, action) => {
    const graphName = action.payload;

    return {
        ...state,
        charts: {
            ...state.charts,
            [graphName]: {
                ...state?.charts?.[graphName],
                isFetched: false,
                isLoading: true,
            },
        },
    };
};
const initalizeCharts = (state, action) => {
    let chartsEnum = {};

    action.payload.charts.forEach((chart) => {
        const colors = GRAPH_COLORS?.[chart] || defaultColors;
        chartsEnum[chart] = { ...chartInitData, colors };
    });
    return {
        ...state,
        charts: chartsEnum,
    };
};

const setConfigData = (state, action) => {
    return {
        ...state,
        isLoading: false,
        config: {
            ...action.payload,
        },
    };
};

const getHeadlineDataSuccess = (state, action) => {
    return {
        ...state,
        loadingHeadline: false,
        headlineData: {
            ...action?.payload?.headlinePanelData,
        },
    };
};

const getGraphDataSuccess = (state, action) => {
    let charts = state.charts;
    let newChartsObj = {};
    action.payload.graphNames.forEach((graphName) => {
        newChartsObj[graphName] = {
            ...charts[graphName],
            ...action.payload.data[graphName],
            isFetched: true,
            isLoading: false,
            fetchError: false,
        };
    });
    return {
        ...state,
        charts: { ...state.charts, ...newChartsObj },
    };
};

const getGraphDataError = (state, action) => {
    let charts = state.charts;
    let newChartsObj = {};
    action.payload.graphNames.forEach((graphName) => {
        newChartsObj[graphName] = {
            ...charts[graphName],
            // ...action.payload.data[graphName],
            isFetched: true,
            isLoading: false,
            fetchError: true,
        };
    });
    return {
        ...state,
        charts: { ...state.charts, ...newChartsObj },
    };
};

const reducer = (state: DashboardNew = inititalState, action: any): DashboardNew => {
    switch (action.type) {
        case CONSTANTS.SET_IS_LOADING:
            return setIsLoading(state, action);
        case CONSTANTS.SET_IS_LOADING_HEADLINE:
            return setIsLoadingHeadline(state, action);
        case CONSTANTS.SET_IS_LOADING_GRAPH:
            return setIsLoadingGraph(state, action);
        case CONSTANTS.RESET_IS_FETCHED_GRAPH:
            return resetIsFetched(state, action);
        case CONSTANTS.INITIALIZE_GRAPH_CARDS:
            return initalizeCharts(state, action);
        case CONSTANTS.GET_DASHBOARD_CONFIG_SUCCESS:
            return setConfigData(state, action);
        case CONSTANTS.GET_HEADLINE_DATA_SUCCESS:
            return getHeadlineDataSuccess(state, action);
        case CONSTANTS.GET_GRAPH_DATA_SUCCESS:
            return getGraphDataSuccess(state, action);
        case CONSTANTS.GET_GRAPH_DATA_ERROR:
            return getGraphDataError(state, action);
        default:
            return state;
    }
};

export default reducer;
