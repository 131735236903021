import axios from "axios";
import { API_ENDPOINTS } from "../apiEndpoints";

// export function getInfoService() {
//     return axios({
//         url: API_ENDPOINTS.DASHBOARD.GET_INFO.url,
//         method: API_ENDPOINTS.DASHBOARD.GET_INFO.method,
//         // params: API_ENDPOINTS.DASHBOARD.GET_INFO.params,
//         data: {}
//     })
//     .then(response => response)
//     .catch(err => err.response)
// }

export function getDashboardMetadataService(payload) {
    return axios({
        url: API_ENDPOINTS.DASHBOARD.GET_METADATA.url,
        method: API_ENDPOINTS.DASHBOARD.GET_METADATA.method,
        data: payload
    })
    .then(response => response)
    .catch(err => err.response)
}

export function getGraphData(payload,graph) {
    return axios({
        url: API_ENDPOINTS.DASHBOARD.GRAPHS[graph.graphName].url,
        method: API_ENDPOINTS.DASHBOARD.GRAPHS[graph.graphName].method,
        data: payload
    })
    .then(response => response)
    .catch(err => err.response)
}