const namespace = 'USER_';

export const CONSTANTS = {
    SET_IS_LOADING: `${namespace}SET_IS_LOADING`,
    SET_UPDATING_ORG_DATA: `${namespace}SET_UPDATING_ORG_DATA`,
    SET_INVESTEE_ORG_ID: `${namespace}SET_INVESTEE_ORG_ID`,
    SET_INVESTEE_ONBOARDED_ID: `${namespace}SET_INVESTEE_ONBOARDED_ID`,

    FETCH_INFO: `${namespace}FETCH_INFO`,
    FETCH_INFO_SUCCESS: `${namespace}FETCH_INFO_SUCCESS`,

    SET_IS_LOADING_CHARTS_DATA: `${namespace}SET_IS_LOADING_CHARTS_DATA`,
    FETCH_UNLOCKED_CHARTS_COUNT: `${namespace}FETCH_UNLOCKED_CHARTS_COUNT`,
    FETCH_UNLOCKED_CHARTS_COUNT_SUCCESS: `${namespace}FETCH_UNLOCKED_CHARTS_COUNT_SUCCESS`,

    UPDATE_ORGANIZATION_DATA: `${namespace}UPDATE_ORGANIZATION_DATA`,
    UPDATE_ORGANIZATION_DATA_SUCCESS: `${namespace}UPDATE_ORGANIZATION_DATA_SUCCESS`,
};
