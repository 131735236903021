import { CONSTANTS } from './constants';
import { Actions, ApiPayloads } from '../actiontypes';

export function setIsLoading(payload: boolean): Actions.BooleanPayloadAction {
    return {
        type: CONSTANTS.SET_IS_LOADING,
        payload,
    };
}

export function setUpdatingOrgData(payload: boolean): Actions.BooleanPayloadAction {
    return {
        type: CONSTANTS.SET_UPDATING_ORG_DATA,
        payload,
    };
}

export function fetchUserInfo(
    payload: ApiPayloads.UserInfoPayload,
    onSuccess: Function,
): Actions.User.FetchInfoAction {
    return {
        type: CONSTANTS.FETCH_INFO,
        payload,
        onSuccess,
    };
}

export function setInvesteeOrgId(payload: string): Actions.StringPayloadAction {
    return {
        type: CONSTANTS.SET_INVESTEE_ORG_ID,
        payload,
    };
}

export function setInvesteeOnboardedId(payload: string): Actions.StringPayloadAction {
    return {
        type: CONSTANTS.SET_INVESTEE_ONBOARDED_ID,
        payload,
    };
}

export function updateOrganizationData(payload: ApiPayloads.UpdateOrgData) {
    return {
        type: CONSTANTS.UPDATE_ORGANIZATION_DATA,
        payload,
    };
}

export function fetchUnlockedChartsCount(payload: any, handlers) {
    return {
        type: CONSTANTS.FETCH_UNLOCKED_CHARTS_COUNT,
        payload,
        handlers,
    };
}
