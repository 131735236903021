import axios from 'axios';
import { coreBaseUrl } from '../../utils/axios-interceptor';
import { API_ENDPOINTS } from '../apiEndpoints';

export function getGraphData(payload) {
    return axios({
        url: API_ENDPOINTS.FORECASTS.GET_GRAPH_DATA.url(),
        method: API_ENDPOINTS.FORECASTS.GET_GRAPH_DATA.method,
        params: API_ENDPOINTS.FORECASTS.GET_GRAPH_DATA.params(payload),
        data: payload.data,
    })
        .then((response) => response)
        .catch((err) => err.response);
}

export function fetchForecastsData(payload) {
    return axios({
        url: API_ENDPOINTS.FORECASTS.GET_FORECASTS_DATA.url(),
        method: API_ENDPOINTS.FORECASTS.GET_FORECASTS_DATA.method,
        params: API_ENDPOINTS.FORECASTS.GET_FORECASTS_DATA.params(payload),
    })
        .then((response) => response)
        .catch((err) => err.response);
}

export function updateForecastStatus(payload) {
    return axios({
        url: API_ENDPOINTS.FORECASTS.UNLOCK_FORECASTS.url(),
        baseURL: coreBaseUrl,
        method: API_ENDPOINTS.FORECASTS.UNLOCK_FORECASTS.method,
        data: {
            capitalForecastStatus: 'Approved',
            orgId: payload.investeeOrgId,
        },
    })
        .then((response) => response)
        .catch((err) => err.response);
}
