import * as Effects from 'redux-saga/effects';
import * as apiService from './service';
import { CONSTANTS } from './constants';

const call: any = Effects.call;
const put: any = Effects.put;
const takeLatest: any = Effects.takeLatest;
const takeEvery: any = Effects.takeEvery;

function* fetchMetricData(action) {
    yield put({
        type: CONSTANTS.SET_IS_LOADING_METRIC,
        payload: true,
        metricType: action.payload.metricType,
    });
    const response = yield call(apiService.getMetricDataService, action.payload);
    switch (response && response.status) {
        case 200:
            yield put({
                type: CONSTANTS.FETCH_METRIC_DATA_SUCCESS,
                payload: response.data.data,
                metricType: action.payload.metricType,
            });
            break;
        // case 400:
        //     yield put({
        //         type: CONSTANTS.SET_IS_LOADING_METRIC,
        //         payload: false,
        //         metricType: action.payload.metricType,
        //     });
        //     break;
        default:
            yield put({
                type: CONSTANTS.FETCH_METRIC_DATA_SUCCESS,
                payload: {},
                metricType: action.payload.metricType,
            });
            return;
    }
}

function* fetchCriteriaOptions(action) {
    const response = yield call(apiService.getCriteriaOptionsService, action.payload);
    switch (response && response.status) {
        case 200:
            yield put({
                type: CONSTANTS.FETCH_CRITERIA_OPTIONS_SUCCESS,
                payload: response.data.data,
            });
            break;
        case 400:
        default:
            return;
    }
}

export default function* actionWatcher() {
    yield takeEvery(CONSTANTS.FETCH_METRIC_DATA, fetchMetricData);
    yield takeLatest(CONSTANTS.FETCH_CRITERIA_OPTIONS, fetchCriteriaOptions);
}
