import { put, call, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as apiService from './service';
import { CONSTANTS } from './constants';
import { Actions } from '../actiontypes';

function* fetchPnlData(action: Actions.FetchTableDataPayloadAction) {
    yield put({
        type: CONSTANTS.SET_IS_LOADING,
        payload: true,
    });
    const response = yield call(apiService.getPnlData, action.payload);
    switch (response && response.status) {
        case 200:
            yield put({
                type: CONSTANTS.FETCH_PNL_DATA_SUCCESS,
                payload: response.data.data,
            });
            break;
        case 400:
        default:
            yield put({
                type: CONSTANTS.SET_IS_LOADING,
                payload: false,
            });
            return toast.error('Something went wrong');
    }
}

function* fetchBalanceSheet(action: Actions.FetchTableDataPayloadAction) {
    yield put({
        type: CONSTANTS.SET_IS_LOADING,
        payload: true,
    });
    const response = yield call(apiService.getBalanceSheet, action.payload);
    switch (response && response.status) {
        case 200:
            yield put({
                type: CONSTANTS.FETCH_BALANCE_SHEET_SUCCESS,
                payload: response.data.data,
            });
            break;
        case 400:
        default:
            yield put({
                type: CONSTANTS.SET_IS_LOADING,
                payload: false,
            });
            return toast.error('Something went wrong');
    }
}

function* fetchCashflowStatement(action: Actions.FetchTableDataPayloadAction) {
    yield put({
        type: CONSTANTS.SET_IS_LOADING,
        payload: true,
    });
    const response = yield call(apiService.getCashFlowStatement, action.payload);
    switch (response && response.status) {
        case 200:
            yield put({
                type: CONSTANTS.FETCH_CASHFLOW_STATEMENT_SUCCESS,
                payload: response.data.data,
            });
            break;
        case 400:
        default:
            yield put({
                type: CONSTANTS.SET_IS_LOADING,
                payload: false,
            });
            return toast.error('Something went wrong');
    }
}

function* fetchBankStatement(action: Actions.FetchTableDataPayloadAction) {
    yield put({
        type: CONSTANTS.SET_IS_LOADING,
        payload: true,
    });
    const response = yield call(apiService.getBankStatement, action.payload);
    switch (response && response.status) {
        case 200:
            yield put({
                type: CONSTANTS.FETCH_BANK_STATEMENT_SUCCESS,
                payload: response.data.data,
            });
            break;
        case 400:
        default:
            yield put({
                type: CONSTANTS.SET_IS_LOADING,
                payload: false,
            });
            return toast.error('Something went wrong');
    }
}

function* fetchInvoicesData(action: Actions.FetchTableDataPayloadAction) {
    yield put({
        type: CONSTANTS.SET_IS_LOADING,
        payload: true,
    });
    const response = yield call(apiService.getInvoicesData, action.payload);
    switch (response && response.status) {
        case 200:
            yield put({
                type: CONSTANTS.FETCH_INVOICES_DATA_SUCCESS,
                payload: response.data.data,
            });
            break;
        case 400:
        default:
            yield put({
                type: CONSTANTS.SET_IS_LOADING,
                payload: false,
            });
            return toast.error('Something went wrong');
    }
}

function* exportFinancialsAsCsv(action: Actions.FetchTableDataPayloadAction) {
    const response = yield call(apiService.exportFinancialsAsCsv, action.payload);
    switch (response && response.status) {
        case 200:
            return toast.success('Successfully exported CSV');
        case 400:
            return toast.error('Invalid Request');
        default:
            return toast.error('Something went wrong');
    }
}

function* fetchPageSourceData(action: Actions.FetchPageSourceAction) {
    yield put({
        type: CONSTANTS.SET_IS_LOADING,
        payload: true,
    });
    const response = yield call(apiService.fetchPageSourceData, action.payload);
    switch (response && response.status) {
        case 200:
            yield put({
                type: CONSTANTS.FETCH_PAGE_SOURCE_DATA_SUCCESS,
                payload: response.data.data,
            });
            break;
        case 400:
        default:
            yield put({
                type: CONSTANTS.SET_IS_LOADING,
                payload: false,
            });
            return toast.error('Something went wrong');
    }
}

export default function* actionWatcher() {
    yield takeLatest(CONSTANTS.FETCH_PNL_DATA, fetchPnlData);
    yield takeLatest(CONSTANTS.FETCH_BALANCE_SHEET, fetchBalanceSheet);
    yield takeLatest(CONSTANTS.FETCH_CASHFLOW_STATEMENT, fetchCashflowStatement);
    yield takeLatest(CONSTANTS.FETCH_BANK_STATEMENT, fetchBankStatement);
    yield takeLatest(CONSTANTS.FETCH_INVOICES_DATA, fetchInvoicesData);
    yield takeLatest(CONSTANTS.EXPORT_CSV, exportFinancialsAsCsv);
    yield takeLatest(CONSTANTS.FETCH_PAGE_SOURCE_DATA, fetchPageSourceData);
}
